export default {
    data: {
        "deparment": [
            { "deparment": "ANTIOQUIA", "departamento_c": "5" },
            { "deparment": "ATLANTICO", "departamento_c": "8" },
            { "deparment": "BOGOTA", "departamento_c": "11" },
            { "deparment": "BOLIVAR", "departamento_c": "13" },
            { "deparment": "BOYACA", "departamento_c": "15" },
            { "deparment": "CALDAS", "departamento_c": "17" },
            { "deparment": "CAQUETA", "departamento_c": "18" },
            { "deparment": "CAUCA", "departamento_c": "19" },
            { "deparment": "CESAR", "departamento_c": "20" },
            { "deparment": "CORDOBA", "departamento_c": "23" },
            { "deparment": "CUNDINAMARCA", "departamento_c": "25" },
            { "deparment": "CHOCO", "departamento_c": "27" },
            { "deparment": "HUILA", "departamento_c": "41" },
            { "deparment": "LA GUAJIRA", "departamento_c": "44" },
            { "deparment": "MAGDALENA", "departamento_c": "47" },
            { "deparment": "META", "departamento_c": "50" },
            { "deparment": "NARIÑO", "departamento_c": "52" },
            { "deparment": "N. DE SANTANDER", "departamento_c": "54" },
            { "deparment": "QUINDIO", "departamento_c": "63" },
            { "deparment": "RISARALDA", "departamento_c": "66" },
            { "deparment": "SANTANDER", "departamento_c": "68" },
            { "deparment": "SUCRE", "departamento_c": "70" },
            { "deparment": "TOLIMA", "departamento_c": "73" },
            { "deparment": "VALLE DEL CAUCA", "departamento_c": "76" },
            { "deparment": "ARAUCA", "departamento_c": "81" },
            { "deparment": "CASANARE", "departamento_c": "85" },
            { "deparment": "PUTUMAYO", "departamento_c": "86" },
            { "deparment": "SAN ANDRES", "departamento_c": "88" },
            { "deparment": "AMAZONAS", "departamento_c": "91" },
            { "deparment": "GUAINIA", "departamento_c": "94" },
            { "deparment": "GUAVIARE", "departamento_c": "95" },
            { "deparment": "VAUPES", "departamento_c": "97" },
            { "deparment": "VICHADA", "departamento_c": "99" },
        ],
        "genero_customer": {
            "F": "Femenino",
            "M": "Masculino"
        },
        "estado_civil": {
            "soltero": "Soltero(a)",
            "casado": "Casado(a)",
            "unionlibre": "Union Libre",
            "viudo": "Viudo(a)"
        },
        "edad": {
            "18_24": "18-24",
            "25_34": "25-34",
            "35_44": "35-44",
            "45_54": "45-54",
            "55_64": "55-64",
            "Mayores_65": "Mayores de 65"

        },
        "profesion": [
            { "profesion": "ABOGADO" },
            { "profesion": "ADMINISTRACIÓN" },
            { "profesion": "ADMINISTRACIÓN AERONÁUTICA" },
            { "profesion": "ADMINISTRACIÓN AGROINDUSTRIAL" },
            { "profesion": "ADMINISTRACIÓN AGROPECUARIA" },
            { "profesion": "ADMINISTRACIÓN DE COMERCIO EXTERIOR" },
            { "profesion": "ADMINISTRACIÓN DE DESARROLLO AGROINDUSTRIAL" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS AGRARIAS" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS AGROINDUSTRIALES" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS AGROPECUARIAS" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS COOPERATIVAS" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS TURÍSTICAS" },
            { "profesion": "ADMINISTRACIÓN DE EMPRESAS TURÍSTICAS Y HOTELERAS" },
            { "profesion": "ADMINISTRACIÓN DE INSTITUCIONES DE SERVICIO" },
            { "profesion": "ADMINISTRACIÓN DE NEGOCIOS" },
            { "profesion": "ADMINISTRACIÓN DE NEGOCIOS CON ÉNFASIS EN FINANZAS" },
            { "profesion": "ADMINISTRACIÓN DE OBRAS CIVILES" },
            { "profesion": "ADMINISTRACIÓN DE RECURSOS COSTEROS Y MARINOS" },
            { "profesion": "ADMINISTRACIÓN DE SISTEMAS DE INFORMACIÓN" },
            { "profesion": "ADMINISTRACIÓN DEL DESARROLLO SOCIAL" },
            { "profesion": "ADMINISTRACIÓN DEL MEDIO AMBIENTE" },
            { "profesion": "ADMINISTRACIÓN EMPRESARIAL Y PUBLICA" },
            { "profesion": "ADMINISTRACIÓN EN RECURSOS HUMANOS" },
            { "profesion": "ADMINISTRACIÓN FINANCIERA" },
            { "profesion": "ADMINISTRACIÓN FINANCIERA Y DE SISTEMAS" },
            { "profesion": "ADMINISTRACIÓN HOTELERA" },
            { "profesion": "ADMINISTRACIÓN HOTELERA Y DE TURISMO" },
            { "profesion": "ADMINISTRACIÓN INDUSTRIAL" },
            { "profesion": "ADMINISTRACIÓN INFORMÁTICA" },
            { "profesion": "ADMINISTRACIÓN MARÍTIMA" },
            { "profesion": "ADMINISTRACIÓN PARA EL DESARROLLO REGIONAL" },
            { "profesion": "ADMINISTRACIÓN POLICIAL" },
            { "profesion": "ADMINISTRACIÓN PUBLICA" },
            { "profesion": "ADMINISTRACIÓN REGIONAL Y URBANA" },
            { "profesion": "ADMINISTRACIÓN TURÍSTICA Y HOTELERA" },
            { "profesion": "ADMINISTRACIÓN Y FINANZAS" },
            { "profesion": "ADMON. EMPRESAS NEFAS. DESARROLLO DE LA CAPACIDAD" },
            { "profesion": "AGROLOGÍA" },
            { "profesion": "AGRONOMÍA" },
            { "profesion": "AMA_CASA" },
            { "profesion": "ANATOMOPATOLOGO" },
            { "profesion": "ANTROPOLOGÍA" },
            { "profesion": "ARQUITECTURA" },
            { "profesion": "ARQUITECTURA DE INTERIORES" },
            { "profesion": "ARQUITECTURA Y URBANISMO" },
            { "profesion": "ARTES ESCÉNICAS" },
            { "profesion": "ARTES MUSICALES" },
            { "profesion": "ARTES PLÁSTICAS" },
            { "profesion": "ARTES REPRESENTATIVAS" },
            { "profesion": "BACHILLER ACADÉMICO" },
            { "profesion": "BACTERIOLOGÍA" },
            { "profesion": "BACTERIOLOGÍA Y LABORATORIO CLÍNICO" },
            { "profesion": "BELLAS ARTES" },
            { "profesion": "BIBLIOTECOLOGÍA" },
            { "profesion": "BIBLIOTECOLOGÍA Y ARCHIVÍSTICA" },
            { "profesion": "BIOLOGÍA CON ÉNFASIS EN BIOTECNOLOGÍA" },
            { "profesion": "BIOLOGÍA CON ÉNFASIS EN RECURSOS NATURALES" },
            { "profesion": "BIOLOGÍA MARINA" },
            { "profesion": "BIOLOGÍA Y QUÍMICA" },
            { "profesion": "BIÓLOGO" },
            { "profesion": "CASTELLANO - LITERATURA" },
            { "profesion": "CIENCIA POLÍTICA" },
            { "profesion": "CIENCIAS DE ENFERMERÍA Y BIOLOGÍA" },
            { "profesion": "CIENCIAS DE LA COMERCIALIZACIÓN" },
            { "profesion": "CIENCIAS DE LA INFORMACIÓN Y LA DOCUMENTACIÓN" },
            { "profesion": "CIENCIAS DEL DEPORTE" },
            { "profesion": "CIENCIAS POLÍTICAS" },
            { "profesion": "CIENCIAS POLÍTICAS Y ADMINISTRATIVAS" },
            { "profesion": "CINE Y TELEVISIÓN" },
            { "profesion": "COMERCIO EXTERIOR" },
            { "profesion": "COMERCIO INTERNACIONAL" },
            { "profesion": "COMUNICACIÓN SOCIAL" },
            { "profesion": "COMUNICACIÓN SOCIAL FORMACIÓN BÁSICA EN PERIODISMO" },
            { "profesion": "COMUNICACIÓN SOCIAL INSTITUCIONAL" },
            { "profesion": "COMUNICACIÓN SOCIAL ÉNFASIS EN PRODUCCIÓN Y ADMÓN." },
            { "profesion": "COMUNICACIÓN SOCIAL- PERIODISMO" },
            { "profesion": "CONSERVACIÓN Y RESTAURACIÓN DE BIENES MUEBLES" },
            { "profesion": "CONSTRUCCIÓN" },
            { "profesion": "CONTADURÍA PUBLICA" },
            { "profesion": "CONTADURÍA PÚBLICA- ÉNFASIS EN SISTEMAS Y ECONOMÍA" },
            { "profesion": "CRIMINALÍSTICA" },
            { "profesion": "CULTURA FÍSICA Y DEPORTE " },
            { "profesion": "DEPORTE Y RECREACIÓN" },
            { "profesion": "DERECHO" },
            { "profesion": "DERECHO CIENCIAS POLÍTICAS Y SOCIALES" },
            { "profesion": "DERECHO Y CIENCIAS ADMINISTRATIVAS" },
            { "profesion": "DERECHO Y CIENCIAS POLÍTICAS" },
            { "profesion": "DERECHO Y CIENCIAS SOCIALES" },
            { "profesion": "DESARROLLO FAMILIAR" },
            { "profesion": "DIBUJO ARQUITECTÓNICO" },
            { "profesion": "DIRECCIÓN MUSICAL" },
            { "profesion": "DIRECCIÓN Y PRODUCCIÓN DE RADIO Y TELEVISIÓN" },
            { "profesion": "DISEÑO DE MODAS Y TEXTILES" },
            { "profesion": "DISEÑO GRAFICO" },
            { "profesion": "DISEÑO INDUSTRIAL" },
            { "profesion": "DISEÑO VISUAL" },
            { "profesion": "ECOLOGÍA" },
            { "profesion": "ECONOMÍA" },
            { "profesion": "ECONOMÍA AGRARIA" },
            { "profesion": "ECONOMÍA AGRÍCOLA" },
            { "profesion": "ECONOMÍA DE EMPRESAS" },
            { "profesion": "ECONOMÍA GENERAL" },
            { "profesion": "ECONOMÍA INDUSTRIAL" },
            { "profesion": "ECONOMÍA Y DESARROLLO" },
            { "profesion": "EDUCACIÓN" },
            { "profesion": "EDUCACIÓN MUSICAL" },
            { "profesion": "EJECUCIÓN INSTRUMENTAL" },
            { "profesion": "ENFERMERÍA" },
            { "profesion": "ENFERMERÍA COMUNITARIA" },
            { "profesion": "ENSOCIALESECONÓMICAS" },
            { "profesion": "ESTADÍSTICA" },
            { "profesion": "ESTADÍSTICA ADMINISTRATIVA" },
            { "profesion": "ESTADÍSTICA E INFORMÁTICA" },
            { "profesion": "ESTUDIOS DIPLOMÁTICOS EN INTERNACIONALES" },
            { "profesion": "ESTUDIOS EN CIENCIA POLÍTICA" },
            { "profesion": "ESTUDIOS MUSICALES" },
            { "profesion": "ESTUDIOS SUPERIORES EN INSTRUMENTO O CANTO" },
            { "profesion": "FARMACIA" },
            { "profesion": "FILOLOGÍA E IDIOMAS - ALEMÁN" },
            { "profesion": "FILOLOGÍA E IDIOMAS - ESPAÑOL" },
            { "profesion": "FILOLOGÍA E IDIOMAS - FRANCÉS" },
            { "profesion": "FILOLOGÍA E IDIOMAS - HUMANIDADES" },
            { "profesion": "FILOLOGÍA E IDIOMAS - INGLES" },
            { "profesion": "FILOLOGÍA ESPA?OLA" },
            { "profesion": "FILOSOFÍA" },
            { "profesion": "FILOSOFÍA Y HUMANIDADES" },
            { "profesion": "FILOSOFÍA Y LETRAS" },
            { "profesion": "FINANZAS Y COMERCIO INTERNACIONAL" },
            { "profesion": "FINANZAS Y NEGOCIOS INTERNACIONALES" },
            { "profesion": "FINANZAS Y RELACIONES INTERNACIONALES" },
            { "profesion": "FISIOTERAPIA" },
            { "profesion": "FONOAUDIOLOGÍA" },
            { "profesion": "FÍSICA" },
            { "profesion": "GEOGRAFÍA" },
            { "profesion": "GEOLOGÍA" },
            { "profesion": "GEOTECNOLOGIA" },
            { "profesion": "GERONTOLOGÍA" },
            { "profesion": "GESTIÓN EMPRESARIAL" },
            { "profesion": "GUITARRISTA" },
            { "profesion": "HISTORIA" },
            { "profesion": "IDIOMAS" },
            { "profesion": "INDUSTRIAS PECUARIAS" },
            { "profesion": "INGENIERÍA ADMINISTRATIVA" },
            { "profesion": "INGENIERÍA AGROINDUSTRIAL" },
            { "profesion": "INGENIERÍA AGRONÓMICA" },
            { "profesion": "INGENIERÍA AGRÍCOLA" },
            { "profesion": "INGENIERÍA AMBIENTAL" },
            { "profesion": "INGENIERÍA AMBIENTAL Y SANITARIA" },
            { "profesion": "INGENIERÍA BIOMÉDICA" },
            { "profesion": "INGENIERÍA CATASTRAL Y GEODESIA" },
            { "profesion": "INGENIERÍA CIVIL" },
            { "profesion": "INGENIERÍA DE ALIMENTOS" },
            { "profesion": "INGENIERÍA DE DISEÑO Y AUTOMATIZACIÓN ELECTRÓNICA" },
            { "profesion": "INGENIERÍA DE MATERIALES" },
            { "profesion": "INGENIERÍA DE MERCADOS" },
            { "profesion": "INGENIERÍA DE MINAS" },
            { "profesion": "INGENIERÍA DE MINAS Y METALURGIA" },
            { "profesion": "INGENIERÍA DE PETRÓLEOS" },
            { "profesion": "INGENIERÍA DE PROCESOS" },
            { "profesion": "INGENIERÍA DE PRODUCCIÓN" },
            { "profesion": "INGENIERÍA DE PRODUCCIÓN AGROINDUSTRIAL" },
            { "profesion": "INGENIERÍA DE RECURSOS NATURALES Y MEDIO AMBIENTE" },
            { "profesion": "INGENIERÍA DE SISTEMAS" },
            { "profesion": "INGENIERÍA DE SISTEMAS CON ÉNFASIS EN ADMÓN. E INF" },
            { "profesion": "INGENIERÍA DE SISTEMAS CON ÉNFASIS EN SOFTWARE" },
            { "profesion": "INGENIERÍA DE SISTEMAS CON ÉNFASIS EN TELE COMUNICA" },
            { "profesion": "INGENIERÍA DE SISTEMAS DE INFORMACIÓN" },
            { "profesion": "INGENIERÍA DE SISTEMAS Y COMPUTACIÓN" },
            { "profesion": "INGENIERÍA DE SISTEMAS ÉNFASIS EN ADMINISTRACIÓN" },
            { "profesion": "INGENIERÍA DE TRANSPORTE Y VÍAS" },
            { "profesion": "INGENIERÍA DEL DESARROLLO AMBIENTAL" },
            { "profesion": "INGENIERÍA DEL MEDIO AMBIENTE" },
            { "profesion": "INGENIERÍA ELECTRICISTA" },
            { "profesion": "INGENIERÍA ELECTROMECÁNICA" },
            { "profesion": "INGENIERÍA ELECTRÓNICA" },
            { "profesion": "INGENIERÍA ELECTRÓNICA Y DE COMPUTADORES" },
            { "profesion": "INGENIERÍA ELECTRÓNICA Y TELECOMUNICACIONES" },
            { "profesion": "INGENIERÍA ELÉCTRICA" },
            { "profesion": "INGENIERÍA EN AUTOMATIZACIÓN INDUSTRIAL" },
            { "profesion": "INGENIERÍA EN INSTRUMENTACIÓN Y CONTROL" },
            { "profesion": "INGENIERÍA ENERGÉTICA" },
            { "profesion": "INGENIERÍA FINANCIERA" },
            { "profesion": "INGENIERÍA FORESTAL" },
            { "profesion": "INGENIERÍA GEOGRÁFICA" },
            { "profesion": "INGENIERÍA GEOLÓGICA" },
            { "profesion": "INGENIERÍA INDUSTRIAL" },
            { "profesion": "INGENIERÍA INDUSTRIAL DE ALIMENTOS " },
            { "profesion": "INGENIERÍA MECÁNICA" },
            { "profesion": "INGENIERÍA METALÚRGICA" },
            { "profesion": "INGENIERÍA NAVAL - ESPECIALIDAD CONSTRUCCIONES" },
            { "profesion": "INGENIERÍA NAVAL - ESPECIALIDAD ELECTRÓNICA" },
            { "profesion": "INGENIERÍA NAVAL - ESPECIALIDAD MECÁNICA" },
            { "profesion": "INGENIERÍA PESQUERA" },
            { "profesion": "INGENIERÍA QUÍMICA" },
            { "profesion": "INGENIERÍA SANITARIA" },
            { "profesion": "INGENIERÍA TELEMÁTICA" },
            { "profesion": "INGENIERÍA TEXTIL" },
            { "profesion": "INSTRUCTIVO PARA EL CARGUE MASIVO DE SELECCIÓN DE PERSONAL" },
            { "profesion": "INSTRUMENTACIÓN QUIRÚRGICA" },
            { "profesion": "LENGUAS CLÁSICAS" },
            { "profesion": "LIC. EDUC. ESPECIAL PROBLEMAS DESARROLLO Y APREND" },
            { "profesion": "LIC. EDUC. ESPECIAL- PROBLEMAS DEL APRENDIZ. DE L" },
            { "profesion": "LIC. EN CIENCIAS RELIGIOSAS ÉNFASIS CATEQUESIS Y E" },
            { "profesion": "LIC. EN CIENCIAS SOCIALES GESTIÓN DEL DESARROLLO" },
            { "profesion": "LIC. EN EDUCACIÓN BÁSICA PRIMARIA ENF. EN CIENCIAS" },
            { "profesion": "LICENCIATURA EDUCACIÓN PREESCOLAR Y PROMOCIÓN DE L" },
            { "profesion": "LICENCIATURA EDUCACIÓN PRIMARIA Y PROMOCIÓN DE LA" },
            { "profesion": "LICENCIATURA EN ADMINISTRACIÓN EDUCATIVA" },
            { "profesion": "LICENCIATURA EN ADMINISTRACIÓN Y SUPERVISIÓN EDUCA " },
            { "profesion": "LICENCIATURA EN AGROPECUARIAS" },
            { "profesion": "LICENCIATURA EN ARTE DRAMÁTICO" },
            { "profesion": "LICENCIATURA EN ARTE ESCÉNICO" },
            { "profesion": "LICENCIATURA EN ARTES GRAFICAS" },
            { "profesion": "LICENCIATURA EN ARTES PLÁSTICAS" },
            { "profesion": "LICENCIATURA EN ARTES VISUALES" },
            { "profesion": "LICENCIATURA EN BACTERIOLOGÍA Y CIENCIAS NATURALES" },
            { "profesion": "LICENCIATURA EN BELLAS ARTES" },
            { "profesion": "LICENCIATURA EN BIOLOGÍA" },
            { "profesion": "LICENCIATURA EN BIOLOGÍA Y EDUCACIÓN AMBIENTAL" },
            { "profesion": "LICENCIATURA EN BIOLOGÍA Y QUÍMICA" },
            { "profesion": "LICENCIATURA EN BÁSICA PRIMARIA CON ÉNFASIS EN EST" },
            { "profesion": "LICENCIATURA EN CATEQUESIS" },
            { "profesion": "LICENCIATURA EN CIENCIAS AGROPECUARIAS" },
            { "profesion": "LICENCIATURA EN CIENCIAS DE LA EDUCACIÓN FILOLOGÍA" },
            { "profesion": "LICENCIATURA EN CIENCIAS DE LA EDUCACIÓN FILOSOFÍA" },
            { "profesion": "LICENCIATURA EN CIENCIAS DE LA EDUCACIÓN FÍSICA" },
            { "profesion": "LICENCIATURA EN CIENCIAS DE LA EDUCACIÓN MATEMATIC" },
            { "profesion": "LICENCIATURA EN CIENCIAS FÍSICAS" },
            { "profesion": "LICENCIATURA EN CIENCIAS NATURALES" },
            { "profesion": "LICENCIATURA EN CIENCIAS PEDAGÓGICAS" },
            { "profesion": "LICENCIATURA EN CIENCIAS POLÍTICAS Y ADMINISTRATIV" },
            { "profesion": "LICENCIATURA EN CIENCIAS RELIGIOSA Y ÉTICAS" },
            { "profesion": "LICENCIATURA EN CIENCIAS RELIGIOSAS" },
            { "profesion": "LICENCIATURA EN CIENCIAS SOCIALES" },
            { "profesion": "LICENCIATURA EN CIENCIAS SOCIALES E HISTORIA" },
            { "profesion": "LICENCIATURA EN CIENCIAS SOCIALES Y CONSTRUCCIÓN Y" },
            { "profesion": "LICENCIATURA EN CIENCIAS SOCIALES Y GEOGRAFÍA" },
            { "profesion": "LICENCIATURA EN COMERCIO" },
            { "profesion": "LICENCIATURA EN COMERCIO Y CONTADURÍA" },
            { "profesion": "LICENCIATURA EN DANZAS Y TEATRO" },
            { "profesion": "LICENCIATURA EN DIBUJO DE LA CONSTRUCCIÓN" },
            { "profesion": "LICENCIATURA EN DIBUJO MECÁNICO" },
            { "profesion": "LICENCIATURA EN DIDÁCTICA Y DIFICULTADES DEL APREN" },
            { "profesion": "LICENCIATURA EN DOCENCIA COMERCIAL" },
            { "profesion": "LICENCIATURA EN DOCENCIA DE COMPUTADORES" },
            { "profesion": "LICENCIATURA EN DOCENCIA DE PROCESOS INDUSTRIALES" },
            { "profesion": "LICENCIATURA EN DOCENCIA DE SISTEMAS Y COMPUTACIÓN" },
            { "profesion": "LICENCIATURA EN DOCENCIA DEL DISEÑO" },
            { "profesion": "LICENCIATURA EN DOCENCIA DEL ÁREA AGROPECUARIA" },
            { "profesion": "LICENCIATURA EN ECONOMÍA DEL HOGAR" },
            { "profesion": "LICENCIATURA EN EDUCA. CON ESPECIALIDAD EN ESTUDIOS" },
            { "profesion": "LICENCIATURA EN EDUCA. ESP. AUDIOV. Y PSICOPE" },
            { "profesion": "LICENCIATURA EN EDUCA. ESP. FILOSOFÍA Y LETRAS" },
            { "profesion": "LICENCIATURA EN EDUCA. ESPE. TECNOLOGÍA INDUSTRIAL" },
            { "profesion": "LICENCIATURA EN EDUCA. MAYORES INGLES Y FRANCÉS" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN AMBIENTAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN BÁSICA PRIMARIA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN CIENCIAS SOCIALES" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN DE ADULTOS" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ELECTROTÉCNICA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ELECTRÓNICA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ESPAÑOL Y COMUNICACIÓN A" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ESPAÑOL Y LENGUAS" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ESPECIAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ESPECIAL RETARDO EN EL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ESPECIAL- TIFLOLOGÍA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN ESPECIALIDAD EN MATEMATI" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN FRANCÉS" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN FÍSICA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN FÍSICA Y DEPORTES" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN FÍSICA Y RECREACIÓN" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN FÍSICA YSALUD" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN IDIOMAS" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN INDUSTRIAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN INFANTIL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN INGLES" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN MECÁNICA INDUSTRIAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN MUSICAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN PEDAGOGÍA MUSICAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN PREESCOLAR" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN PREESCOLAR Y BÁSICA PRIMA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN PRIMARIA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN PSICOLOGÍA Y PEDAGOGÍA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN RELIGIOSA Y MORAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN RURAL" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN TEOLOGÍA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN Y CIENCIAS RELIGIOSAS" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN- BIOLOGÍA" },
            { "profesion": "LICENCIATURA EN EDUCACIÓN- HISTORIA Y FILOSOFÍA" },
            { "profesion": "LICENCIATURA EN ELECTRICIDAD Y ELECTRÓNICA" },
            { "profesion": "LICENCIATURA EN ELECTROMECÁNICA" },
            { "profesion": "LICENCIATURA EN ELECTROTECNIA" },
            { "profesion": "LICENCIATURA EN ENSEÑANZA DE LENGUAS EXTRANJERAS" },
            { "profesion": "LICENCIATURA EN ESPAÑOL Y LITERATURA" },
            { "profesion": "LICENCIATURA EN ESTUDIOS BÍBLICOS " },
            { "profesion": "LICENCIATURA EN ETNOEDUCACION Y DESARROLLO COMUNIT" },
            { "profesion": "LICENCIATURA EN FILOLOGÍA" },
            { "profesion": "LICENCIATURA EN FILOLOGÍA E IDIOMAS INGLES-FRANCÉS" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA CON ÉNFASIS EN TEORÍA PO" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA E HISTORIA" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA E IDIOMAS" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA Y CIENCIAS RELIGIOSAS" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA Y HUMANIDADES" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA Y LETRAS" },
            { "profesion": "LICENCIATURA EN FILOSOFÍA Y TEOLOGÍA" },
            { "profesion": "LICENCIATURA EN FORMACIÓN ESTÉTICA" },
            { "profesion": "LICENCIATURA EN FÍSICA" },
            { "profesion": "LICENCIATURA EN FÍSICA Y MATEMÁTICAS" },
            { "profesion": "LICENCIATURA EN GEOGRAFÍA E HISTORIA" },
            { "profesion": "LICENCIATURA EN HISTORIA" },
            { "profesion": "LICENCIATURA EN HISTORIA DE COLOMBIA" },
            { "profesion": "LICENCIATURA EN HISTORIA Y CIVILIZACION" },
            { "profesion": "LICENCIATURA EN HISTORIA Y FILOSOFÍA" },
            { "profesion": "LICENCIATURA EN IDIOMAS" },
            { "profesion": "LICENCIATURA EN IDIOMAS ESPAÑOL-INGLES" },
            { "profesion": "LICENCIATURA EN IDIOMAS MODERNOS ESPAÑOL-FRANCÉS" },
            { "profesion": "LICENCIATURA EN IDIOMAS MODERNOS ESPAÑOL-INGLES" },
            { "profesion": "LICENCIATURA EN INFORMÁTICA" },
            { "profesion": "LICENCIATURA EN INFORMÁTICA EDUCATIVA" },
            { "profesion": "LICENCIATURA EN INGLES" },
            { "profesion": "LICENCIATURA EN INGLES Y HUMANIDADES" },
            { "profesion": "LICENCIATURA EN LENGUAS MODERNAR ESPAÑOL Y FRANCÉS" },
            { "profesion": "LICENCIATURA EN LENGUAS MODERNAS" },
            { "profesion": "LICENCIATURA EN LENGUAS MODERNAS ESPAÑOL E INGLES" },
            { "profesion": "LICENCIATURA EN LENGUAS MODERNAS ESPAÑOL FRANCÉS" },
            { "profesion": "LICENCIATURA EN LENGUAS MODERNAS ESPAÑOL INGLES" },
            { "profesion": "LICENCIATURA EN LENGUAS MODERNAS INGLES-FRANCÉS" },
            { "profesion": "LICENCIATURA EN LINGÜÍSTICA Y EDUCACIÓN INDÍGENA" },
            { "profesion": "LICENCIATURA EN LINGÜÍSTICA Y LITERATURA" },
            { "profesion": "LICENCIATURA EN LITERATURA" },
            { "profesion": "LICENCIATURA EN LITERATURA Y LENGUA ESPA? OLA" },
            { "profesion": "LICENCIATURA EN MATEMÁTICAS" },
            { "profesion": "LICENCIATURA EN MATEMÁTICAS Y COMPUTACIÓN" },
            { "profesion": "LICENCIATURA EN MATEMÁTICAS Y ESTADÍSTICA" },
            { "profesion": "LICENCIATURA EN MATEMÁTICAS Y FÍSICA" },
            { "profesion": "LICENCIATURA EN MECÁNICA INDUSTRIAL" },
            { "profesion": "LICENCIATURA EN MÚSICA" },
            { "profesion": "LICENCIATURA EN NUTRICIÓN Y DIETÉTICA" },
            { "profesion": "LICENCIATURA EN ORIENTACIÓN Y CONSEJERÍA" },
            { "profesion": "LICENCIATURA EN PEDAGOGÍA" },
            { "profesion": "LICENCIATURA EN PEDAGOGÍA INFANTIL Y FAMILIAR" },
            { "profesion": "LICENCIATURA EN PEDAGOGÍA PARA LA SALUD" },
            { "profesion": "LICENCIATURA EN PEDAGOGÍA REEDUCATIVA" },
            { "profesion": "LICENCIATURA EN PEDAGOGÍA SOCIAL Y COMUNITARIA" },
            { "profesion": "LICENCIATURA EN PEDAGOGÍA Y PSICOLOGÍA" },
            { "profesion": "LICENCIATURA EN PREESCOLAR" },
            { "profesion": "LICENCIATURA EN PRODUCCIÓN AGROPECUARIA" },
            { "profesion": "LICENCIATURA EN PROMOCIÓN DE LA COMUNIDAD" },
            { "profesion": "LICENCIATURA EN PSICOLOGÍA" },
            { "profesion": "LICENCIATURA EN PSICOLOGÍA EDUCATIVA" },
            { "profesion": "LICENCIATURA EN PSICOLOGÍA Y ADMINISTRACIÓN EDUCAT" },
            { "profesion": "LICENCIATURA EN PSICOPEDAGOGÍA" },
            { "profesion": "LICENCIATURA EN PSICOPEDAGOGÍA Y FILOSOFÍA" },
            { "profesion": "LICENCIATURA EN QUÍMICA" },
            { "profesion": "LICENCIATURA EN QUÍMICA Y BIOLOGÍA" },
            { "profesion": "LICENCIATURA EN SOCIALES" },
            { "profesion": "LICENCIATURA EN SUPERVISIÓN EDUCATIVA" },
            { "profesion": "LICENCIATURA EN TECNOLOGÍA EDUCATIVA" },
            { "profesion": "LICENCIATURA EN TECNOLOGÍA INDUSTRIAL" },
            { "profesion": "LICENCIATURA EN TEOLOGÍA" },
            { "profesion": "LINGÜÍSTICA Y LITERATURA" },
            { "profesion": "LITERATURA" },
            { "profesion": "MAESTRA EN ARTES VISUALES" },
            { "profesion": "MAESTRO EN PIANO" },
            { "profesion": "MAESTRÍA EN COMPOSICIÓN MUSICAL" },
            { "profesion": "MAGISTER" },
            { "profesion": "MANEJO AGROFORESTAL" },
            { "profesion": "MANEJO AGROLOGICO Y DE POST-COSECHA" },
            { "profesion": "MATEMÁTICAS" },
            { "profesion": "MATEMÁTICAS Y FÍSICA" },
            { "profesion": "MEDICINA GENERAL" },
            { "profesion": "MEDICINA VETERINARIA" },
            { "profesion": "MEDICINA Y CIRUGÍA" },
            { "profesion": "MEDICINA Y NEUROCIRUGÍA" },
            { "profesion": "MEDICINA Y RADIOLOGÍA" },
            { "profesion": "MERCADEO" },
            { "profesion": "MERCADEO CON ÉNFASIS EN NEGOCIOS INTERNACIONALES" },
            { "profesion": "MERCADEO NACIONAL E INTERNACIONAL" },
            { "profesion": "MERCADEO PUBLICIDAD Y VENTAS" },
            { "profesion": "MERCADEO Y PUBLICIDAD" },
            { "profesion": "MERCADOTECNIA" },
            { "profesion": "MERCADOTECNIA AGROINDUSTRIAL" },
            { "profesion": "METALISTERÍA" },
            { "profesion": "MICROBIOLOGÍA" },
            { "profesion": "MICROBIOLOGÍA AGRÍCOLA Y VETERINARIA" },
            { "profesion": "MICROBIOLOGÍA CON ÉNFASIS EN ALIMENTOS" },
            { "profesion": "MICROBIOLOGÍA INDUSTRIAL" },
            { "profesion": "MINISTERIO DE EDUCACIÓN NACIONAL" },
            { "profesion": "MÚSICA" },
            { "profesion": "MÚSICA CANTO" },
            { "profesion": "MÚSICA INSTRUMENTAL" },
            { "profesion": "MÚSICA INSTRUMENTO" },
            { "profesion": "NEUROLOGÍA INFANTIL" },
            { "profesion": "NO DEFINIDO" },
            { "profesion": "NORMALISTA SUPERIOR" },
            { "profesion": "NUTRICIONISTA DIETISTA" },
            { "profesion": "OCEANOGRAFÍA" },
            { "profesion": "ODONTOLOGÍA" },
            { "profesion": "OPTOMETRÍA" },
            { "profesion": "PATOLOGÍA CLÍNICA" },
            { "profesion": "PH" },
            { "profesion": "PINTURA" },
            { "profesion": "PLANEACIÓN PARA EL DESARROLLO SOCIAL" },
            { "profesion": "PROFESIONAL EN CIENCIA DE LA INFORMACIÓN Y LA DOC." },
            { "profesion": "PROFESIONAL EN LENGUAS MODERNAS" },
            { "profesion": "PROYECTO DE MODERNIZACIÓN DE SECRETARÍAS DE EDUCACIÓN" },
            { "profesion": "PSICOLOGÍA" },
            { "profesion": "PSICOLOGÍA ORGANIZACIONAL" },
            { "profesion": "PSICOLOGÍA Y FILOSOFÍA" },
            { "profesion": "PSICOPEDAGOGÍA Y ADMINISTRACIÓN EDUCATIVA" },
            { "profesion": "PSICÓLOGO" },
            { "profesion": "PSIQUIATRÍA" },
            { "profesion": "PUBLICIDAD" },
            { "profesion": "PUBLICIDAD Y MERCADEO" },
            { "profesion": "QUÍMICA" },
            { "profesion": "QUÍMICA DE ALIMENTOS" },
            { "profesion": "QUÍMICA FARMACÉUTICA" },
            { "profesion": "QUÍMICA Y FARMACIA" },
            { "profesion": "QUÍMICO" },
            { "profesion": "RECREACIÓN" },
            { "profesion": "REPÚBLICA DE COLOMBIA" },
            { "profesion": "SACERDOTE" },
            { "profesion": "SOCIOLOGÍA" },
            { "profesion": "SOPORTE LÓGICO LTDA." },
            { "profesion": "T.P ADMINISTRATIVO EN CONTABILIDAD Y FINANZAS" },
            { "profesion": "T.P EN DISEÑO GRAFICO" },
            { "profesion": "T.P EN LABORATORIO MEDICO" },
            { "profesion": "T.P. ADMINISTRATIVO EN RELACIONES INDUSTRIALES" },
            { "profesion": "T.P. CONSTRUCCIÓN Y ADMINISTRACIÓN DE OBRAS CIVILES" },
            { "profesion": "T.P. DISEÑO TEXTIL" },
            { "profesion": "T.P. DISEÑO Y DECORACIÓN DE ESPACIOS ARQUITECTÓNICA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN AGROPECUARIA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN BANCARIA Y FINANCIERA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE COMERCIO EXTERIOR" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE CONSTRUCCIONES" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE COSTOS Y AUDITORIA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE EMPRESAS" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE EMPRESAS AGROPECUARIAS" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE LA CONSTRUCCIÓN" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE OBRAS CIVILES" },
            { "profesion": "T.P. EN ADMINISTRACIÓN DE SERVICIO DE SALUD" },
            { "profesion": "T.P. EN ADMINISTRACIÓN EMPRESARIAL E INFORMÁTICA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN HOSPITALARIA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN HOTELERA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN INDUSTRIAL" },
            { "profesion": "T.P. EN ADMINISTRACIÓN INFORMÁTICA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN INMOBILIARIA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN MUNICIPAL" },
            { "profesion": "T.P. EN ADMINISTRACIÓN TURÍSTICA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN TURÍSTICA Y HOTELERA" },
            { "profesion": "T.P. EN ADMINISTRACIÓN Y AUDITORIA DE SISTEMAS" },
            { "profesion": "T.P. EN ADMINISTRACIÓN Y AVALÚOS DE FINCA RAÍZ" },
            { "profesion": "T.P. EN ADMINISTRACIÓN Y FINANZAS" },
            { "profesion": "T.P. EN ADMINISTRACIÓN Y MERCADOTECNIA" },
            { "profesion": "T.P. EN ADMÓN. BANCARIA DE INSTITUCIONES FINANCIER" },
            { "profesion": "T.P. EN ADMÓN. DE EMPRESAS CON ÉNFASIS EN AGROINDU" },
            { "profesion": "T.P. EN ADMÓN. DE EMPRESAS DE ECONOMÍA SOLIDARIA" },
            { "profesion": "T.P. EN ADMÓN. DE PLANTELES DE EDUCA. BÁSICA PRIMAR" },
            { "profesion": "T.P. EN AGROPECUARIA" },
            { "profesion": "T.P. EN ANÁLISIS QUÍMICO INDUSTRIAL" },
            { "profesion": "T.P. EN ANÁLISIS Y DISEÑO DE SISTEMAS DE COMPUTACIÓN" },
            { "profesion": "T.P. EN ANÁLISIS Y PROGRAMACIÓN DE COMPUTADORES" },
            { "profesion": "T.P. EN ARQUITECTURA DE INTERIORES" },
            { "profesion": "T.P. EN ARTE GRAFICO Y PUBLICITARIO" },
            { "profesion": "T.P. EN ARTE PUBLICITARIO" },
            { "profesion": "T.P. EN ARTE Y DECORACIÓN" },
            { "profesion": "T.P. EN AUXILIAR DE ARQUITECTURA E INGENIERÍA" },
            { "profesion": "T.P. EN BALÍSTICA" },
            { "profesion": "T.P. EN CATEQUESIS" },
            { "profesion": "T.P. EN CIENCIAS CONTABLES" },
            { "profesion": "T.P. EN CIENCIAS DE LA COMPUTACIÓN" },
            { "profesion": "T.P. EN CITOHISTOLOGIA" },
            { "profesion": "T.P. EN COCINA INTERNACIONAL" },
            { "profesion": "T.P. EN COMERCIO EXTERIOR" },
            { "profesion": "T.P. EN COMERCIO INTERNACIONAL" },
            { "profesion": "T.P. EN COMERCIO INTERNACIONAL Y LEGISLACIÓN ADUAN" },
            { "profesion": "T.P. EN COMERCIO Y NEGOCIOS INTERNACIONALES" },
            { "profesion": "T.P. EN COMPUTACIÓN" },
            { "profesion": "T.P. EN COMPUTACIÓN Y SISTEMAS" },
            { "profesion": "T.P. EN CONFECCIÓN" },
            { "profesion": "T.P. EN CONSTRUCCIONES" },
            { "profesion": "T.P. EN CONTABILIDAD" },
            { "profesion": "T.P. EN CONTABILIDAD COMERCIAL" },
            { "profesion": "T.P. EN CONTABILIDAD SISTEMATIZADA" },
            { "profesion": "T.P. EN CONTABILIDAD Y COSTOS" },
            { "profesion": "T.P. EN CONTABILIDAD Y FINANZAS" },
            { "profesion": "T.P. EN CONTADURÍA" },
            { "profesion": "T.P. EN CONTADURÍA TÉCNICA" },
            { "profesion": "T.P. EN COSTOS Y CONTABILIDAD" },
            { "profesion": "T.P. EN DACTILOSCOPIA" },
            { "profesion": "T.P. EN DECORACIÓN DE ESPACIOS ARQUITECTÓNICOS" },
            { "profesion": "T.P. EN DECORACIÓN Y DISEÑO DE INTERIORES" },
            { "profesion": "T.P. EN DELINEANTE Y AUXILIAR DE INGENIERÍA MECANI" },
            { "profesion": "T.P. EN DELINEANTES DE ARQUITECTURA E INGENIERÍA" },
            { "profesion": "T.P. EN DELINEANTES DE ARQUITECTURA Y DECORACIÓN" },
            { "profesion": "T.P. EN DEPORTE Y RECREACIÓN" },
            { "profesion": "T.P. EN DIBUJO ARQUITECTÓNICO" },
            { "profesion": "T.P. EN DIBUJO ARQUITECTÓNICO Y DE INGENIERÍA" },
            { "profesion": "T.P. EN DIBUJO ARQUITECTÓNICO Y DECORACIÓN" },
            { "profesion": "T.P. EN DIBUJO DE INGENIERÍA MECÁNICA Y ARQUITECTU" },
            { "profesion": "T.P. EN DIBUJO PUBLICITARIO" },
            { "profesion": "T.P. EN DISEÑO ASISTIDO POR COMPUTADOR" },
            { "profesion": "T.P. EN DISEÑO DE ALTA COSTURA" },
            { "profesion": "T.P. EN DISEÑO DE AMBIENTES" },
            { "profesion": "T.P. EN DISEÑO DE ARTES GRAFICAS" },
            { "profesion": "T.P. EN DISEÑO DE INTERIORES" },
            { "profesion": "T.P. EN DISEÑO DE MAQUINAS" },
            { "profesion": "T.P. EN DISEÑO DE MODAS" },
            { "profesion": "T.P. EN DISEÑO E INDUSTRIA DE LA MODA" },
            { "profesion": "T.P. EN DISEÑO INDUSTRIAL" },
            { "profesion": "T.P. EN DISEÑO MECÁNICO" },
            { "profesion": "T.P. EN DISEÑO PUBLICITARIO" },
            { "profesion": "T.P. EN DISEÑO TEXTIL Y DE MODAS" },
            { "profesion": "T.P. EN DISEÑO Y CONFECCIÓN DE LA MODA" },
            { "profesion": "T.P. EN DISEÑO Y DECORACIÓN DE AMBIENTES" },
            { "profesion": "T.P. EN DISEÑO Y PRODUCCIÓN DE TELEVISIÓN" },
            { "profesion": "T.P. EN DOCUMENTO LOGIA" },
            { "profesion": "T.P. EN EDUCACIÓN ARTÍSTICA" },
            { "profesion": "T.P. EN EDUCACIÓN BÁSICA PRIMARIA BILINGÜE" },
            { "profesion": "T.P. EN EDUCACIÓN ESPECIAL" },
            { "profesion": "T.P. EN EDUCACIÓN FÍSICA" },
            { "profesion": "T.P. EN EDUCACIÓN FÍSICA Y RECREACIÓN" },
            { "profesion": "T.P. EN EDUCACIÓN PREESCOLAR" },
            { "profesion": "T.P. EN ELECTROMECÁNICA" },
            { "profesion": "T.P. EN ELECTROMEDICINA" },
            { "profesion": "T.P. EN ELECTRÓNICA" },
            { "profesion": "T.P. EN ELECTRÓNICA INDUSTRIAL" },
            { "profesion": "T.P. EN ELECTRÓNICA Y TELECOMUNICACIONES" },
            { "profesion": "T.P. EN EXPLOSIVOS" },
            { "profesion": "T.P. EN FINANZAS Y SISTEMAS CONTABLES" },
            { "profesion": "T.P. EN FOTOGRAFÍA" },
            { "profesion": "T.P. EN FOTOGRAFÍA JUDICIAL" },
            { "profesion": "T.P. EN FOTOGRAFÍA Y CAMAROGRAFITA" },
            { "profesion": "T.P. EN GESTIÓN DE PEQUEÑAS Y MEDIANAS EMPRESAS" },
            { "profesion": "T.P. EN GESTIÓN DE SERVICIOS GERONTOLÓGICOS" },
            { "profesion": "T.P. EN GESTIÓN EMPRESARIAL" },
            { "profesion": "T.P. EN HOTELERÍA" },
            { "profesion": "T.P. EN IDENTIFICACIÓN DE AUTOMOTORES" },
            { "profesion": "T.P. EN INFORMÁTICA CONTABLE Y FINANCIERA" },
            { "profesion": "T.P. EN INFORMÁTICA GERENCIAL" },
            { "profesion": "T.P. EN INFORMÁTICA SECRETARIAL" },
            { "profesion": "T.P. EN INGENIERÍA AGROPECUARIA" },
            { "profesion": "T.P. EN INGENIERÍA AUTOMOTRIZ" },
            { "profesion": "T.P. EN INGENIERÍA COMERCIAL" },
            { "profesion": "T.P. EN INGENIERÍA DE ALIMENTOS" },
            { "profesion": "T.P. EN INGENIERÍA DE SISTEMAS" },
            { "profesion": "T.P. EN INGENIERÍA INDUSTRIAL" },
            { "profesion": "T.P. EN INGENIERÍA TEXTIL" },
            { "profesion": "T.P. EN INGENIERÍA TÉRMICA" },
            { "profesion": "T.P. EN INSTRUMENTACIÓN QUIRÚRGICA" },
            { "profesion": "T.P. EN INTELIGENCIA" },
            { "profesion": "T.P. EN LABORATORIO DENTAL" },
            { "profesion": "T.P. EN LOGÍSTICA DE PRODUCCIÓN Y DISTRIBUCIÓN" },
            { "profesion": "T.P. EN MANTENIMIENTO DE COMPUTADORES" },
            { "profesion": "T.P. EN MANTENIMIENTO ELECTRÓNICO" },
            { "profesion": "T.P. EN MARKETING INTERNACIONAL" },
            { "profesion": "T.P. EN MECÁNICA AUTOMOTRIZ" },
            { "profesion": "T.P. EN MERCADEO" },
            { "profesion": "T.P. EN MERCADEO E INFORMÁTICA" },
            { "profesion": "T.P. EN MERCADEO Y COMERCIALIZACIÓN" },
            { "profesion": "T.P. EN MERCADEO Y VENTAS" },
            { "profesion": "T.P. EN MERCADO Y FINANZAS" },
            { "profesion": "T.P. EN MERCADOTECNIA" },
            { "profesion": "T.P. EN MICROEMPRESAS Y COOPERATIVAS" },
            { "profesion": "T.P. EN MINERÍA" },
            { "profesion": "T.P. EN NUEVAS TECNOLOGÍAS INFORMÁTICAS" },
            { "profesion": "T.P. EN OPTOMETRÍA" },
            { "profesion": "T.P. EN PERIODISMO" },
            { "profesion": "T.P. EN PERIODISMO DEPORTIVO" },
            { "profesion": "T.P. EN PLANIMETRÍA" },
            { "profesion": "T.P. EN POLICÍA JUDICIAL" },
            { "profesion": "T.P. EN PROCESOS INDUSTRIALES" },
            { "profesion": "T.P. EN PRODUCCIÓN AGROPECUARIA" },
            { "profesion": "T.P. EN PRODUCCIÓN DE SEMILLAS" },
            { "profesion": "T.P. EN PRODUCCIÓN DE TELEVISIÓN" },
            { "profesion": "T.P. EN PRODUCCIÓN Y DESARROLLO INDUSTRIAL" },
            { "profesion": "T.P. EN PROGRAMACIÓN DE COMPUTADORES" },
            { "profesion": "T.P. EN PROMOCIÓN SOCIAL" },
            { "profesion": "T.P. EN PUBLICIDAD" },
            { "profesion": "T.P. EN PUBLICIDAD Y DISEÑO" },
            { "profesion": "T.P. EN PUBLICIDAD Y DISEÑO PUBLICITARIO" },
            { "profesion": "T.P. EN RADIOLOGÍA MEDICA" },
            { "profesion": "T.P. EN REALIZACIÓN DE VIDEO" },
            { "profesion": "T.P. EN RECREACIÓN DIRIGIDA" },
            { "profesion": "T.P. EN RECURSOS ENERGÉTICOS" },
            { "profesion": "T.P. EN RELACIONES INDUSTRIALES" },
            { "profesion": "T.P. EN RELACIONES PÚBLICAS Y COMUNICACIÓN" },
            { "profesion": "T.P. EN RENDIMIENTO DEPORTIVO" },
            { "profesion": "T.P. EN SALUD OCUPACIONAL Y SEGURIDAD INDUSTRIAL" },
            { "profesion": "T.P. EN SALUD Y RENDIMIENTO HUMANO" },
            { "profesion": "T.P. EN SECRETARIADO" },
            { "profesion": "T.P. EN SECRETARIADO BILINGÜE" },
            { "profesion": "T.P. EN SECRETARIADO BILINGÜE Y DE SISTEMAS" },
            { "profesion": "T.P. EN SECRETARIADO COMERCIAL" },
            { "profesion": "T.P. EN SECRETARIADO CONTABLE DE SISTEMAS" },
            { "profesion": "T.P. EN SECRETARIADO EJECUTIVO" },
            { "profesion": "T.P. EN SECRETARIADO EJECUTIVO BILINGÜE" },
            { "profesion": "T.P. EN SECRETARIADO EJECUTIVO DE SISTEMAS" },
            { "profesion": "T.P. EN SECRETARIADO GERENCIAL" },
            { "profesion": "T.P. EN SEGURIDAD E HIGIENE INDUSTRIAL" },
            { "profesion": "T.P. EN SEGURIDAD E HIGIENE OCUPACIONAL" },
            { "profesion": "T.P. EN SERVICIOS A BORDO" },
            { "profesion": "T.P. EN SISTEMAS" },
            { "profesion": "T.P. EN SISTEMAS CONTABLES Y FINANCIEROS" },
            { "profesion": "T.P. EN SISTEMAS Y ANÁLISIS DE LA COMPUTACIÓN" },
            { "profesion": "T.P. EN SISTEMAS Y COMPUTACIÓN" },
            { "profesion": "T.P. EN SISTEMATIZACIÓN" },
            { "profesion": "T.P. EN TECNOLOGÍA DE PLÁSTICOS" },
            { "profesion": "T.P. EN TELECOMUNICACIONES" },
            { "profesion": "T.P. EN TOPOGRAFÍA" },
            { "profesion": "T.P. EN TURISMO" },
            { "profesion": "T.P. EN TÉCNICAS AGROPECUARIAS" },
            { "profesion": "T.P. EN TÉCNICAS FORESTALES" },
            { "profesion": "T.P. EN TÉCNICO DE LABORATORIO" },
            { "profesion": "T.P. EN TÉCNICO HOTELERO" },
            { "profesion": "T.P. PROCESOS MECÁNICOS CON ÉNFASIS PRODUCCIÓN AGR" },
            { "profesion": "T.P. SECRETARIADO EJECUTIVO Y DE SISTEMAS BILINGÜE" },
            { "profesion": "TEATRO" },
            { "profesion": "TECNOL. ADMON. REC. COSTEROS Y MARIN. CON ÉNFASIS" },
            { "profesion": "TECNOLOGÍA AGROINDUSTRIAL" },
            { "profesion": "TECNOLOGÍA AGROPECUARIA" },
            { "profesion": "TECNOLOGÍA AGRÍCOLA" },
            { "profesion": "TECNOLOGÍA BILINGÜE EN ADMINISTRACIÓN DE EMPRESAS" },
            { "profesion": "TECNOLOGÍA BILINGÜE EN COMERCIO EXTERIOR" },
            { "profesion": "TECNOLOGÍA DE ALIMENTOS" },
            { "profesion": "TECNOLOGÍA DEPORTIVA" },
            { "profesion": "TECNOLOGÍA ELECTRÓNICA" },
            { "profesion": "TECNOLOGÍA ELÉCTRICA" },
            { "profesion": "TECNOLOGÍA EMPRESARIAL" },
            { "profesion": "TECNOLOGÍA EMPRESARIAL Y DE NEGOCIOS" },
            { "profesion": "TECNOLOGÍA EN ACUICULTURA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN AERONÁUTICA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN AGROPECUARIA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN BANCARIA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN COMERCIAL" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN COMERCIAL Y FINANCIER" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE EMPRESAS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE EMPRESAS COOPERATI" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE EMPRESAS TURÍSTICA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE LA PRODUCCIÓN" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE LA PRODUCCIÓN INDU" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE NEGOCIOS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE OBRAS CIVILES" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE OFICINAS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE OFICINAS BILINGÜE" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE PERSONAL" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE REDES DE COMPUTADO" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE REDES DE DATOS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE RIESGOS Y SEGUROS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE SEGUROS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE SERVICIOS DE SALUD" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE SISTEMAS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE SISTEMAS DE INFORME" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DE VENTAS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DEL TRANSPORTE" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DEL TURISMO SOCIAL" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DEPORTIVAS" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN DOCUMENTAL Y MICROGRA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN FINANCIERA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN FINANCIERA Y DE SISTE" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN HOSPITALARIA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN HOTELERA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN INFORMÁTICA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN JUDICIAL" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN MUNICIPAL" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN NAVIERA Y PORTUARIA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN TRIBUTARIA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN TURÍSTICA" },
            { "profesion": "TECNOLOGÍA EN ADMINISTRACIÓN Y FINANZAS" },
            { "profesion": "TECNOLOGÍA EN ADMÓN. DE AEROLÍNEAS Y AGENCIAS DE V" },
            { "profesion": "TECNOLOGÍA EN ADMÓN. DE EMPRESAS DE ECONOMÍA SOLIDA" },
            { "profesion": "TECNOLOGÍA EN ADMÓN. DE NEGOCIOS INTERNACIONALES" },
            { "profesion": "TECNOLOGÍA EN ALIMENTOS" },
            { "profesion": "TECNOLOGÍA EN ANÁLISIS Y DISEÑO DE SISTEMAS" },
            { "profesion": "TECNOLOGÍA EN ANÁLISIS Y PROGRAMACIÓN DE COMPUTADO" },
            { "profesion": "TECNOLOGÍA EN ARTE Y DECORACIÓN ARQUITECTÓNICA" },
            { "profesion": "TECNOLOGÍA EN AUDITORIA Y CONTROL INTERNO" },
            { "profesion": "TECNOLOGÍA EN AUDITORIA Y COSTOS" },
            { "profesion": "TECNOLOGÍA EN AUTOMATIZACIÓN INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN BANCA E INSTITUCIONES FINANCIERAS" },
            { "profesion": "TECNOLOGÍA EN CAFÉ Y DIVERSIFICACIÓN" },
            { "profesion": "TECNOLOGÍA EN CERÁMICA" },
            { "profesion": "TECNOLOGÍA EN CINE Y FOTOGRAFÍA" },
            { "profesion": "TECNOLOGÍA EN CITOHISTOLOGIA" },
            { "profesion": "TECNOLOGÍA EN COMERCIO EXTERIOR" },
            { "profesion": "TECNOLOGÍA EN COMERCIO INTERNACIONAL" },
            { "profesion": "TECNOLOGÍA EN COMPUTACIÓN" },
            { "profesion": "TECNOLOGÍA EN COMPUTACIÓN GRAFICA" },
            { "profesion": "TECNOLOGÍA EN COMUNICACIONES AERONÁUTICAS" },
            { "profesion": "TECNOLOGÍA EN COMUNICACIÓN PUBLICITARIA" },
            { "profesion": "TECNOLOGÍA EN CONMUTACIÓN" },
            { "profesion": "TECNOLOGÍA EN CONSTRUCCIONES" },
            { "profesion": "TECNOLOGÍA EN CONSTRUCCIONES CIVILES" },
            { "profesion": "TECNOLOGÍA EN CONSTRUCCIÓN" },
            { "profesion": "TECNOLOGÍA EN CONSTRUCCIÓN DE OBRAS CIVILES" },
            { "profesion": "TECNOLOGÍA EN CONTABILIDAD" },
            { "profesion": "TECNOLOGÍA EN CONTABILIDAD SISTEMATIZADA" },
            { "profesion": "TECNOLOGÍA EN CONTADURÍA" },
            { "profesion": "TECNOLOGÍA EN CONTADURÍA PUBLICA" },
            { "profesion": "TECNOLOGÍA EN CONTROL DE CALIDAD" },
            { "profesion": "TECNOLOGÍA EN COSTOS Y CONTADURÍA" },
            { "profesion": "TECNOLOGÍA EN CRIMINALÍSTICA" },
            { "profesion": "TECNOLOGÍA EN DELINEANTES DE ARQUITECTURA E INGENI" },
            { "profesion": "TECNOLOGÍA EN DESARROLLO DEL SOFTWARE" },
            { "profesion": "TECNOLOGÍA EN DIBUJO ARQUITECTÓNICO DE INGENIERÍA" },
            { "profesion": "TECNOLOGÍA EN DIBUJO INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN DIRECCIÓN DE VENTAS" },
            { "profesion": "TECNOLOGÍA EN DIRECCIÓN Y PRODUCCIÓN DE TELEVISIÓN" },
            { "profesion": "TECNOLOGÍA EN DISEÑO DE INTERIORES" },
            { "profesion": "TECNOLOGÍA EN DISEÑO DE MODAS" },
            { "profesion": "TECNOLOGÍA EN DISEÑO DE MODAS Y ALTA COSTURA" },
            { "profesion": "TECNOLOGÍA EN DISEÑO DE MODAS Y APLICACIONES TEXTI" },
            { "profesion": "TECNOLOGÍA EN DISEÑO DE MODAS Y TEXTIL" },
            { "profesion": "TECNOLOGÍA EN DISEÑO GRAFICO" },
            { "profesion": "TECNOLOGÍA EN DISEÑO INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN DISEÑO TEXTIL" },
            { "profesion": "TECNOLOGÍA EN DISEÑO TEXTIL Y PATRONAJE INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN DISEÑO Y DECORACIÓN DE ESPACIOS ARQU" },
            { "profesion": "TECNOLOGÍA EN DOCENCIA RURAL" },
            { "profesion": "TECNOLOGÍA EN EDUCACIÓN BÁSICA PRIMARIA" },
            { "profesion": "TECNOLOGÍA EN EDUCACIÓN ESPECIAL" },
            { "profesion": "TECNOLOGÍA EN EDUCACIÓN FÍSICA" },
            { "profesion": "TECNOLOGÍA EN EDUCACIÓN PARA EL DESARROLLO DE LA C" },
            { "profesion": "TECNOLOGÍA EN EDUCACIÓN PREESCOLAR" },
            { "profesion": "TECNOLOGÍA EN ELECTRICIDAD" },
            { "profesion": "TECNOLOGÍA EN ELECTRICIDAD INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN ELECTRIFICACIÓN Y TELEFONÍA RURAL" },
            { "profesion": "TECNOLOGÍA EN ELECTROMECÁNICA" },
            { "profesion": "TECNOLOGÍA EN ELECTROQUÍMICA" },
            { "profesion": "TECNOLOGÍA EN ELECTRÓNICA AERONÁUTICA" },
            { "profesion": "TECNOLOGÍA EN ELECTRÓNICA Y AUTOMATIZACIÓN INDUSTR" },
            { "profesion": "TECNOLOGÍA EN ELECTRÓNICA Y COMUNICACIONES" },
            { "profesion": "TECNOLOGÍA EN ELECTRÓNICA Y TELECOMUNICACIONES" },
            { "profesion": "TECNOLOGÍA EN EMPRESAS TURÍSTICAS Y HOTELERAS" },
            { "profesion": "TECNOLOGÍA EN ESTADÍSTICA E INFORMÁTICA" },
            { "profesion": "TECNOLOGÍA EN ESTUDIOS POLICIALES" },
            { "profesion": "TECNOLOGÍA EN FINANZAS Y COMERCIO INTERNACIONAL" },
            { "profesion": "TECNOLOGÍA EN FINANZAS Y SISTEMAS CONTABLES" },
            { "profesion": "TECNOLOGÍA EN FISIOTERAPIA" },
            { "profesion": "TECNOLOGÍA EN GERONTOLOGÍA" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN AGROPECUARIA" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN BANCARIA Y FINANCIERA" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN COMERCIAL Y DE NEGOCIOS" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN DE EMPRESAS ASOC. Y ORGANIZ." },
            { "profesion": "TECNOLOGÍA EN GESTIÓN DE OBRAS CIVILES Y CONSTRUCI" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN DE SERVICIOS PÚBLICOS SANITA" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN DE TRANSPORTES" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN EMPRESARIAL" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN EMPRESARIAL Y DE NEGOCIOS" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN INFORMÁTICA" },
            { "profesion": "TECNOLOGÍA EN GESTIÓN Y ADMINISTRACIÓN DE EMPRESAS" },
            { "profesion": "TECNOLOGÍA EN HIGIENE Y SEGURIDAD INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN INFORMÁTICA" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA DE ALIMENTOS" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA DE INSTRUMENTACIÓN Y CONT." },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA DE SISTEMAS" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA ELECTROMECÁNICA" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA ELECTRÓNICA" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA ELECTRÓNICA DIGITAL" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA ELECTRÓNICA Y TELECOMUNIC" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA HIDRONEUMÁTICA" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN INGENIERÍA INDUSTRIAL CON ÉNFASIS EN" },
            { "profesion": "TECNOLOGÍA EN INSTRUMENTACIÓN INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN INSTRUMENTACIÓN QUIRÚRGICA" },
            { "profesion": "TECNOLOGÍA EN INSTRUMENTACIÓN Y CONTROLES INDUSTRIA" },
            { "profesion": "TECNOLOGÍA EN INVESTIGACIÓN JUDICIAL" },
            { "profesion": "TECNOLOGÍA EN LABORATORIO DE PRÓTESIS DENTAL" },
            { "profesion": "TECNOLOGÍA EN LABORATORIO DE REHABILITACIÓN DENTAL" },
            { "profesion": "TECNOLOGÍA EN LABORATORIO DENTAL" },
            { "profesion": "TECNOLOGÍA EN LOGÍSTICA DE PRODUCCIÓN DISTRIBUCIÓN" },
            { "profesion": "TECNOLOGÍA EN LÁCTEOS" },
            { "profesion": "TECNOLOGÍA EN MADERAS" },
            { "profesion": "TECNOLOGÍA EN MANTENIMIENTO AERONÁUTICO" },
            { "profesion": "TECNOLOGÍA EN MECÁNICA AUTOMOTRIZ" },
            { "profesion": "TECNOLOGÍA EN MEDICINA VETERINARIA Y ZOOTECNIA" },
            { "profesion": "TECNOLOGÍA EN MERCADEO AGROPECUARIO" },
            { "profesion": "TECNOLOGÍA EN MERCADEO Y COMERCIALIZACIÓN" },
            { "profesion": "TECNOLOGÍA EN MERCADEO Y FINANZAS" },
            { "profesion": "TECNOLOGÍA EN MERCADEO Y NEGOCIOS INTERNACIONALES" },
            { "profesion": "TECNOLOGÍA EN MERCADEO Y PUBLICIDAD" },
            { "profesion": "TECNOLOGÍA EN MERCADEO Y VENTAS" },
            { "profesion": "TECNOLOGÍA EN MERCADOTECNIA" },
            { "profesion": "TECNOLOGÍA EN MERCADOTECNIA Y PUBLICIDAD" },
            { "profesion": "TECNOLOGÍA EN MERCADOTECNIA Y VENTAS" },
            { "profesion": "TECNOLOGÍA EN MINAS" },
            { "profesion": "TECNOLOGÍA EN MINERÍA" },
            { "profesion": "TECNOLOGÍA EN NEGOCIOS INTERNACIONALES" },
            { "profesion": "TECNOLOGÍA EN OBRAS CIVILES" },
            { "profesion": "TECNOLOGÍA EN PERIODISMO" },
            { "profesion": "TECNOLOGÍA EN PLÁSTICOS" },
            { "profesion": "TECNOLOGÍA EN PRODUCCIÓN AGRARIA" },
            { "profesion": "TECNOLOGÍA EN PRODUCCIÓN AGROPECUARIA" },
            { "profesion": "TECNOLOGÍA EN PRODUCCIÓN AGRÍCOLA" },
            { "profesion": "TECNOLOGÍA EN PRODUCCIÓN ANIMAL" },
            { "profesion": "TECNOLOGÍA EN PRODUCCIÓN INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN PROMOCIÓN SOCIAL" },
            { "profesion": "TECNOLOGÍA EN PUBLICIDAD" },
            { "profesion": "TECNOLOGÍA EN PUBLICIDAD Y COMERCIALIZACIÓN" },
            { "profesion": "TECNOLOGÍA EN PUBLICIDAD Y COMUNICACIÓN" },
            { "profesion": "TECNOLOGÍA EN PUBLICIDAD Y MERCADEO" },
            { "profesion": "TECNOLOGÍA EN QUÍMICA DE PRODUCTOS VEGETALES" },
            { "profesion": "TECNOLOGÍA EN QUÍMICA INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA EN RADIOLOGÍA E IMÁGENES" },
            { "profesion": "TECNOLOGÍA EN RECREACIÓN DIRIGIDA" },
            { "profesion": "TECNOLOGÍA EN RECURSOS HÍDRICOS" },
            { "profesion": "TECNOLOGÍA EN RECURSOS NATURALES RENOVABLES" },
            { "profesion": "TECNOLOGÍA EN REDES Y COMUNICACIONES DE DATOS" },
            { "profesion": "TECNOLOGÍA EN REGENCIA DE FARMACIA" },
            { "profesion": "TECNOLOGÍA EN RELACIONES INDUSTRIALES" },
            { "profesion": "TECNOLOGÍA EN SALUD COMUNITARIA" },
            { "profesion": "TECNOLOGÍA EN SALUD OCUPACIONAL" },
            { "profesion": "TECNOLOGÍA EN SANEAMIENTO AMBIENTAL" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO BILINGÜE" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO BILINGÜE Y COMPUTACIÓN" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO COMERCIAL BILINGÜE" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO EJECUTIVO" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO EJECUTIVO BILINGÜE" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO EJECUTIVO BILINGÜE ESPA" },
            { "profesion": "TECNOLOGÍA EN SECRETARIADO EJECUTIVO CON ÉNFASIS E" },
            { "profesion": "TECNOLOGÍA EN SEGURIDAD AERONÁUTICA" },
            { "profesion": "TECNOLOGÍA EN SEGURIDAD E HIGIENE OCUPACIONAL" },
            { "profesion": "TECNOLOGÍA EN SISTEMAS" },
            { "profesion": "TECNOLOGÍA EN SISTEMAS CONTABLES" },
            { "profesion": "TECNOLOGÍA EN SISTEMAS DE INFORMACIÓN" },
            { "profesion": "TECNOLOGÍA EN SISTEMAS DE INFORMACIÓN EN SALUD" },
            { "profesion": "TECNOLOGÍA EN SISTEMAS INDUSTRIALES" },
            { "profesion": "TECNOLOGÍA EN SISTEMAS Y COMPUTACIÓN" },
            { "profesion": "TECNOLOGÍA EN SISTEMATIZACIÓN DE DATOS" },
            { "profesion": "TECNOLOGÍA EN SUPERVISIÓN DE INDUSTRIA Y COMERCIO" },
            { "profesion": "TECNOLOGÍA EN TERAPIA DEL LENGUAJE" },
            { "profesion": "TECNOLOGÍA EN TERAPIA RECREATIVA" },
            { "profesion": "TECNOLOGÍA EN TERAPIA RESPIRATORIA" },
            { "profesion": "TECNOLOGÍA EN TEXTILES Y MODA" },
            { "profesion": "TECNOLOGÍA EN TOPOGRAFÍA" },
            { "profesion": "TECNOLOGÍA EN TRADUCCIÓN TURÍSTICA" },
            { "profesion": "TECNOLOGÍA EN TRIBUTACIÓN" },
            { "profesion": "TECNOLOGÍA EN TURISMO RECREATIVO" },
            { "profesion": "TECNOLOGÍA EN TURISMO Y RELACIONES INDUSTRIALES" },
            { "profesion": "TECNOLOGÍA EN ZOOTECNIA" },
            { "profesion": "TECNOLOGÍA FORESTAL" },
            { "profesion": "TECNOLOGÍA INDUSTRIAL" },
            { "profesion": "TECNOLOGÍA MECÁNICA" },
            { "profesion": "TECNOLOGÍA NAVAL EN ELECTROMECÁNICA" },
            { "profesion": "TECNOLOGÍA NAVAL EN ELECTRÓNICA" },
            { "profesion": "TECNOLOGÍA NAVIERA" },
            { "profesion": "TECNOLOGÍA PECUARIA" },
            { "profesion": "TECNOLOGÍA QUÍMICA" },
            { "profesion": "TECNOLOGÍA TEXTIL" },
            { "profesion": "TEOLOGÍA" },
            { "profesion": "TERAPIA FÍSICA" },
            { "profesion": "TERAPIA OCUPACIONAL" },
            { "profesion": "TERAPIA RESPIRATORIA" },
            { "profesion": "TERAPIAS PSICOSOCIALES" },
            { "profesion": "TEXTILES" },
            { "profesion": "TRABAJO SOCIAL" },
            { "profesion": "TRADUCCIÓN E INTERPRETACIÓN RUSO - ESPAÑOL" },
            { "profesion": "TRADUCCIÓN SIMULTANEA" },
            { "profesion": "TÉCNICO" },
            { "profesion": "UNIVERSITARIO" },
            { "profesion": "UNIVERSITARIO ESP." },
            { "profesion": "ZOOTECNIA" },
            { "profesion": "OTRO" },
        ],
        "deparment_customer": {
            "5": "ANTIOQUIA",
            "8": "ATLANTICO",
            "11": "BOGOTA",
            "13": "BOLIVAR",
            "15": "BOYACA",
            "17": "CALDAS",
            "18": "CAQUETA",
            "19": "CAUCA",
            "20": "CESAR",
            "23": "CORDOBA",
            "25": "CUNDINAMARCA",
            "27": "CHOCO",
            "41": "HUILA",
            "44": "LA GUAJIRA",
            "47": "MAGDALENA",
            "50": "META",
            "52": "NARIÑO",
            "54": "N. DE SANTANDER",
            "63": "QUINDIO",
            "66": "RISARALDA",
            "68": "SANTANDER",
            "70": "SUCRE",
            "73": "TOLIMA",
            "76": "VALLE DEL CAUCA",
            "81": "ARAUCA",
            "85": "CASANARE",
            "86": "PUTUMAYO",
            "88": "SAN ANDRES",
            "91": "AMAZONAS",
            "94": "GUAINIA",
            "95": "GUAVIARE",
            "97": "VAUPES",
            "99": "VICHADA"
        },
        "cities_customer": {
            '5_001': 'MEDELLIN',
            '5_002': 'ABEJORRAL',
            '5_004': 'ABRIAQUI',
            '5_021': 'ALEJANDRIA',
            '5_030': 'AMAGA',
            '5_031': 'AMALFI',
            '5_034': 'ANDES',
            '5_036': 'ANGELOPOLIS',
            '5_038': 'ANGOSTURA',
            '5_040': 'ANORI',
            '5_042': 'SANTAFE DE ANTIOQUIA',
            '5_044': 'ANZA',
            '5_045': 'APARTADO',
            '5_051': 'ARBOLETES',
            '5_055': 'ARGELIA',
            '5_059': 'ARMENIA',
            '5_079': 'BARBOSA',
            '5_086': 'BELMIRA',
            '5_088': 'BELLO',
            '5_091': 'BETANIA',
            '5_093': 'BETULIA',
            '5_101': 'CIUDAD BOLIVAR',
            '5_107': 'BRICEÑO',
            '5_113': 'BURITICA',
            '5_120': 'CACERES',
            '5_125': 'CAICEDO',
            '5_129': 'CALDAS',
            '5_134': 'CAMPAMENTO',
            '5_138': 'CAÑASGORDAS',
            '5_142': 'CARACOLI',
            '5_145': 'CARAMANTA',
            '5_147': 'CAREPA',
            '5_148': 'EL CARMEN DE VIBORAL',
            '5_150': 'CAROLINA',
            '5_154': 'CAUCASIA',
            '5_172': 'CHIGORODO',
            '5_190': 'CISNEROS',
            '5_197': 'COCORNA',
            '5_206': 'CONCEPCION',
            '5_209': 'CONCORDIA',
            '5_212': 'COPACABANA',
            '5_234': 'DABEIBA',
            '5_237': 'DON MATIAS',
            '5_240': 'EBEJICO',
            '5_250': 'EL BAGRE',
            '5_264': 'ENTRERRIOS',
            '5_266': 'ENVIGADO',
            '5_282': 'FREDONIA',
            '5_284': 'FRONTINO',
            '5_306': 'GIRALDO',
            '5_308': 'GIRARDOTA',
            '5_310': 'GOMEZ PLATA',
            '5_313': 'GRANADA',
            '5_315': 'GUADALUPE',
            '5_318': 'GUARNE',
            '5_321': 'GUATAPE',
            '5_347': 'HELICONIA',
            '5_353': 'HISPANIA',
            '5_360': 'ITAGUI',
            '5_361': 'ITUANGO',
            '5_364': 'JARDIN',
            '5_368': 'JERICO',
            '5_376': 'LA CEJA',
            '5_380': 'LA ESTRELLA',
            '5_390': 'LA PINTADA',
            '5_400': 'LA UNION',
            '5_411': 'LIBORINA',
            '5_425': 'MACEO',
            '5_440': 'MARINILLA',
            '5_467': 'MONTEBELLO',
            '5_475': 'MURINDO',
            '5_480': 'MUTATA',
            '5_483': 'NARIÑO',
            '5_490': 'NECOCLI',
            '5_495': 'NECHI',
            '5_501': 'OLAYA',
            '5_541': 'PEÐOL',
            '5_543': 'PEQUE',
            '5_576': 'PUEBLORRICO',
            '5_579': 'PUERTO BERRIO',
            '5_585': 'PUERTO NARE',
            '5_591': 'PUERTO TRIUNFO',
            '5_604': 'REMEDIOS',
            '5_607': 'RETIRO',
            '5_615': 'RIONEGRO',
            '5_628': 'SABANALARGA',
            '5_631': 'SABANETA',
            '5_642': 'SALGAR',
            '5_647': 'SAN ANDRES DE CUERQUIA',
            '5_649': 'SAN CARLOS',
            '5_652': 'SAN FRANCISCO',
            '5_656': 'SAN JERONIMO',
            '5_658': 'SAN JOSE DE LA MONTAÑA',
            '5_659': 'SAN JUAN DE URABA',
            '5_660': 'SAN LUIS',
            '5_664': 'SAN PEDRO',
            '5_665': 'SAN PEDRO DE URABA',
            '5_667': 'SAN RAFAEL',
            '5_670': 'SAN ROQUE',
            '5_674': 'SAN VICENTE',
            '5_679': 'SANTA BARBARA',
            '5_686': 'SANTA ROSA DE OSOS',
            '5_690': 'SANTO DOMINGO',
            '5_697': 'EL SANTUARIO',
            '5_736': 'SEGOVIA',
            '5_756': 'SONSON',
            '5_761': 'SOPETRAN',
            '5_789': 'TAMESIS',
            '5_790': 'TARAZA',
            '5_792': 'TARSO',
            '5_809': 'TITIRIBI',
            '5_819': 'TOLEDO',
            '5_837': 'TURBO',
            '5_842': 'URAMITA',
            '5_847': 'URRAO',
            '5_854': 'VALDIVIA',
            '5_856': 'VALPARAISO',
            '5_858': 'VEGACHI',
            '5_861': 'VENECIA',
            '5_873': 'VIGIA DEL FUERTE',
            '5_885': 'YALI',
            '5_887': 'YARUMAL',
            '5_890': 'YOLOMBO',
            '5_893': 'YONDO',
            '5_895': 'ZARAGOZA',
            '8_001': 'BARRANQUILLA',
            '8_078': 'BARANOA',
            '8_137': 'CAMPO DE LA CRUZ',
            '8_141': 'CANDELARIA',
            '8_296': 'GALAPA',
            '8_372': 'JUAN DE ACOSTA',
            '8_421': 'LURUACO',
            '8_433': 'MALAMBO',
            '8_436': 'MANATI',
            '8_520': 'PALMAR DE VARELA',
            '8_549': 'PIOJO',
            '8_558': 'POLONUEVO',
            '8_560': 'PONEDERA',
            '8_573': 'PUERTO COLOMBIA',
            '8_606': 'REPELON',
            '8_634': 'SABANAGRANDE',
            '8_638': 'SABANALARGA',
            '8_675': 'SANTA LUCIA',
            '8_685': 'SANTO TOMAS',
            '8_758': 'SOLEDAD',
            '8_770': 'SUAN',
            '8_832': 'TUBARA',
            '8_849': 'USIACURI',
            '11_001': 'BOGOTA, D.C.',
            '13_001': 'CARTAGENA',
            '13_006': 'ACHI',
            '13_030': 'ALTOS DEL ROSARIO',
            '13_042': 'ARENAL',
            '13_052': 'ARJONA',
            '13_062': 'ARROYOHONDO',
            '13_074': 'BARRANCO DE LOBA',
            '13_140': 'CALAMAR',
            '13_160': 'CANTAGALLO',
            '13_188': 'CICUCO',
            '13_212': 'CORDOBA',
            '13_222': 'CLEMENCIA',
            '13_244': 'EL CARMEN DE BOLIVAR',
            '13_248': 'EL GUAMO',
            '13_268': 'EL PEÑON',
            '13_300': 'HATILLO DE LOBA',
            '13_430': 'MAGANGUE',
            '13_433': 'MAHATES',
            '13_440': 'MARGARITA',
            '13_442': 'MARIA LA BAJA',
            '13_458': 'MONTECRISTO',
            '13_468': 'MOMPOS',
            '13_490': 'NOROSI',
            '13_473': 'MORALES',
            '13_549': 'PINILLOS',
            '13_580': 'REGIDOR',
            '13_600': 'RIO VIEJO',
            '13_620': 'SAN CRISTOBAL',
            '13_647': 'SAN ESTANISLAO',
            '13_650': 'SAN FERNANDO',
            '13_654': 'SAN JACINTO',
            '13_655': 'SAN JACINTO DEL CAUCA',
            '13_657': 'SAN JUAN NEPOMUCENO',
            '13_667': 'SAN MARTIN DE LOBA',
            '13_670': 'SAN PABLO',
            '13_673': 'SANTA CATALINA',
            '13_683': 'SANTA ROSA',
            '13_688': 'SANTA ROSA DEL SUR',
            '13_744': 'SIMITI',
            '13_760': 'SOPLAVIENTO',
            '13_780': 'TALAIGUA NUEVO',
            '13_810': 'TIQUISIO',
            '13_836': 'TURBACO',
            '13_838': 'TURBANA',
            '13_873': 'VILLANUEVA',
            '13_894': 'ZAMBRANO',
            '15_001': 'TUNJA',
            '15_022': 'ALMEIDA',
            '15_047': 'AQUITANIA',
            '15_051': 'ARCABUCO',
            '15_087': 'BELEN',
            '15_090': 'BERBEO',
            '15_092': 'BETEITIVA',
            '15_097': 'BOAVITA',
            '15_104': 'BOYACA',
            '15_106': 'BRICEÑO',
            '15_109': 'BUENAVISTA',
            '15_114': 'BUSBANZA',
            '15_131': 'CALDAS',
            '15_135': 'CAMPOHERMOSO',
            '15_162': 'CERINZA',
            '15_172': 'CHINAVITA',
            '15_176': 'CHIQUINQUIRA',
            '15_180': 'CHISCAS',
            '15_183': 'CHITA',
            '15_185': 'CHITARAQUE',
            '15_187': 'CHIVATA',
            '15_189': 'CIENEGA',
            '15_204': 'COMBITA',
            '15_212': 'COPER',
            '15_215': 'CORRALES',
            '15_218': 'COVARACHIA',
            '15_223': 'CUBARA',
            '15_224': 'CUCAITA',
            '15_226': 'CUITIVA',
            '15_232': 'CHIQUIZA',
            '15_236': 'CHIVOR',
            '15_238': 'DUITAMA',
            '15_244': 'EL COCUY',
            '15_248': 'EL ESPINO',
            '15_272': 'FIRAVITOBA',
            '15_276': 'FLORESTA',
            '15_293': 'GACHANTIVA',
            '15_296': 'GAMEZA',
            '15_299': 'GARAGOA',
            '15_317': 'GUACAMAYAS',
            '15_322': 'GUATEQUE',
            '15_325': 'GUAYATA',
            '15_332': 'GsICAN',
            '15_362': 'IZA',
            '15_367': 'JENESANO',
            '15_368': 'JERICO',
            '15_377': 'LABRANZAGRANDE',
            '15_380': 'LA CAPILLA',
            '15_401': 'LA VICTORIA',
            '15_403': 'LA UVITA',
            '15_407': 'VILLA DE LEYVA',
            '15_425': 'MACANAL',
            '15_442': 'MARIPI',
            '15_455': 'MIRAFLORES',
            '15_464': 'MONGUA',
            '15_466': 'MONGUI',
            '15_469': 'MONIQUIRA',
            '15_476': 'MOTAVITA',
            '15_480': 'MUZO',
            '15_491': 'NOBSA',
            '15_494': 'NUEVO COLON',
            '15_500': 'OICATA',
            '15_507': 'OTANCHE',
            '15_511': 'PACHAVITA',
            '15_514': 'PAEZ',
            '15_516': 'PAIPA',
            '15_518': 'PAJARITO',
            '15_522': 'PANQUEBA',
            '15_531': 'PAUNA',
            '15_533': 'PAYA',
            '15_537': 'PAZ DE RIO',
            '15_542': 'PESCA',
            '15_550': 'PISBA',
            '15_572': 'PUERTO BOYACA',
            '15_580': 'QUIPAMA',
            '15_599': 'RAMIRIQUI',
            '15_600': 'RAQUIRA',
            '15_621': 'RONDON',
            '15_632': 'SABOYA',
            '15_638': 'SACHICA',
            '15_646': 'SAMACA',
            '15_660': 'SAN EDUARDO',
            '15_664': 'SAN JOSE DE PARE',
            '15_667': 'SAN LUIS DE GACENO',
            '15_673': 'SAN MATEO',
            '15_676': 'SAN MIGUEL DE SEMA',
            '15_681': 'SAN PABLO DE BORBUR',
            '15_686': 'SANTANA',
            '15_690': 'SANTA MARIA',
            '15_693': 'SANTA ROSA DE VITERBO',
            '15_696': 'SANTA SOFIA',
            '15_720': 'SATIVANORTE',
            '15_723': 'SATIVASUR',
            '15_740': 'SIACHOQUE',
            '15_753': 'SOATA',
            '15_755': 'SOCOTA',
            '15_757': 'SOCHA',
            '15_759': 'SOGAMOSO',
            '15_761': 'SOMONDOCO',
            '15_762': 'SORA',
            '15_763': 'SOTAQUIRA',
            '15_764': 'SORACA',
            '15_774': 'SUSACON',
            '15_776': 'SUTAMARCHAN',
            '15_778': 'SUTATENZA',
            '15_790': 'TASCO',
            '15_798': 'TENZA',
            '15_804': 'TIBANA',
            '15_806': 'TIBASOSA',
            '15_808': 'TINJACA',
            '15_810': 'TIPACOQUE',
            '15_814': 'TOCA',
            '15_816': 'TOGsI',
            '15_820': 'TOPAGA',
            '15_822': 'TOTA',
            '15_832': 'TUNUNGUA',
            '15_835': 'TURMEQUE',
            '15_837': 'TUTA',
            '15_839': 'TUTAZA',
            '15_842': 'UMBITA',
            '15_861': 'VENTAQUEMADA',
            '15_879': 'VIRACACHA',
            '15_897': 'ZETAQUIRA',
            '17_001': 'MANIZALES',
            '17_013': 'AGUADAS',
            '17_042': 'ANSERMA',
            '17_050': 'ARANZAZU',
            '17_088': 'BELALCAZAR',
            '17_174': 'CHINCHINA',
            '17_272': 'FILADELFIA',
            '17_380': 'LA DORADA',
            '17_388': 'LA MERCED',
            '17_433': 'MANZANARES',
            '17_442': 'MARMATO',
            '17_444': 'MARQUETALIA',
            '17_446': 'MARULANDA',
            '17_486': 'NEIRA',
            '17_495': 'NORCASIA',
            '17_513': 'PACORA',
            '17_524': 'PALESTINA',
            '17_541': 'PENSILVANIA',
            '17_614': 'RIOSUCIO',
            '17_616': 'RISARALDA',
            '17_653': 'SALAMINA',
            '17_662': 'SAMANA',
            '17_665': 'SAN JOSE',
            '17_777': 'SUPIA',
            '17_867': 'VICTORIA',
            '17_873': 'VILLAMARIA',
            '17_877': 'VITERBO',
            '18_001': 'FLORENCIA',
            '18_029': 'ALBANIA',
            '18_094': 'BELEN DE LOS ANDAQUIES',
            '18_150': 'CARTAGENA DEL CHAIRA',
            '18_205': 'CURILLO',
            '18_247': 'EL DONCELLO',
            '18_256': 'EL PAUJIL',
            '18_410': 'LA MONTAÑITA',
            '18_460': 'MILAN',
            '18_479': 'MORELIA',
            '18_592': 'PUERTO RICO',
            '18_610': 'SAN JOSE DEL FRAGUA',
            '18_753': 'SAN VICENTE DEL CAGUAN',
            '18_756': 'SOLANO',
            '18_785': 'SOLITA',
            '18_860': 'VALPARAISO',
            '19_001': 'POPAYAN',
            '19_022': 'ALMAGUER',
            '19_050': 'ARGELIA',
            '19_075': 'BALBOA',
            '19_100': 'BOLIVAR',
            '19_110': 'BUENOS AIRES',
            '19_130': 'CAJIBIO',
            '19_137': 'CALDONO',
            '19_142': 'CALOTO',
            '19_212': 'CORINTO',
            '19_256': 'EL TAMBO',
            '19_290': 'FLORENCIA',
            '19_300': 'GUACHENE',
            '19_318': 'GUAPI',
            '19_355': 'INZA',
            '19_364': 'JAMBALO',
            '19_392': 'LA SIERRA',
            '19_397': 'LA VEGA',
            '19_418': 'LOPEZ',
            '19_450': 'MERCADERES',
            '19_455': 'MIRANDA',
            '19_473': 'MORALES',
            '19_513': 'PADILLA',
            '19_517': 'PAEZ',
            '19_532': 'PATIA',
            '19_533': 'PIAMONTE',
            '19_548': 'PIENDAMO',
            '19_573': 'PUERTO TEJADA',
            '19_585': 'PURACE',
            '19_622': 'ROSAS',
            '19_693': 'SAN SEBASTIAN',
            '19_698': 'SANTANDER DE QUILICHAO',
            '19_701': 'SANTA ROSA',
            '19_743': 'SILVIA',
            '19_760': 'SOTARA',
            '19_780': 'SUAREZ',
            '19_785': 'SUCRE',
            '19_807': 'TIMBIO',
            '19_809': 'TIMBIQUI',
            '19_821': 'TORIBIO',
            '19_824': 'TOTORO',
            '19_845': 'VILLA RICA',
            '20_001': 'VALLEDUPAR',
            '20_011': 'AGUACHICA',
            '20_013': 'AGUSTIN CODAZZI',
            '20_032': 'ASTREA',
            '20_045': 'BECERRIL',
            '20_060': 'BOSCONIA',
            '20_175': 'CHIMICHAGUA',
            '20_178': 'CHIRIGUANA',
            '20_228': 'CURUMANI',
            '20_238': 'EL COPEY',
            '20_250': 'EL PASO',
            '20_295': 'GAMARRA',
            '20_310': 'GONZALEZ',
            '20_383': 'LA GLORIA',
            '20_400': 'LA JAGUA DE IBIRICO',
            '20_443': 'MANAURE',
            '20_517': 'PAILITAS',
            '20_550': 'PELAYA',
            '20_570': 'PUEBLO BELLO',
            '20_614': 'RIO DE ORO',
            '20_621': 'LA PAZ',
            '20_710': 'SAN ALBERTO',
            '20_750': 'SAN DIEGO',
            '20_770': 'SAN MARTIN',
            '20_787': 'TAMALAMEQUE',
            '23_001': 'MONTERIA',
            '23_068': 'AYAPEL',
            '23_079': 'BUENAVISTA',
            '23_090': 'CANALETE',
            '23_162': 'CERETE',
            '23_168': 'CHIMA',
            '23_182': 'CHINU',
            '23_189': 'CIENAGA DE ORO',
            '23_300': 'COTORRA',
            '23_350': 'LA APARTADA',
            '23_417': 'LORICA',
            '23_419': 'LOS CORDOBAS',
            '23_464': 'MOMIL',
            '23_466': 'MONTELIBANO',
            '23_500': 'MOÑITOS',
            '23_555': 'PLANETA RICA',
            '23_570': 'PUEBLO NUEVO',
            '23_574': 'PUERTO ESCONDIDO',
            '23_580': 'PUERTO LIBERTADOR',
            '23_586': 'PURISIMA',
            '23_660': 'SAHAGUN',
            '23_670': 'SAN ANDRES SOTAVENTO',
            '23_672': 'SAN ANTERO',
            '23_675': 'SAN BERNARDO DEL VIENTO',
            '23_678': 'SAN CARLOS',
            '23_686': 'SAN PELAYO',
            '23_807': 'TIERRALTA',
            '23_855': 'VALENCIA',
            '25_001': 'AGUA DE DIOS',
            '25_019': 'ALBAN',
            '25_035': 'ANAPOIMA',
            '25_040': 'ANOLAIMA',
            '25_053': 'ARBELAEZ',
            '25_086': 'BELTRAN',
            '25_095': 'BITUIMA',
            '25_099': 'BOJACA',
            '25_120': 'CABRERA',
            '25_123': 'CACHIPAY',
            '25_126': 'CAJICA',
            '25_148': 'CAPARRAPI',
            '25_151': 'CAQUEZA',
            '25_154': 'CARMEN DE CARUPA',
            '25_168': 'CHAGUANI',
            '25_175': 'CHIA',
            '25_178': 'CHIPAQUE',
            '25_181': 'CHOACHI',
            '25_183': 'CHOCONTA',
            '25_200': 'COGUA',
            '25_214': 'COTA',
            '25_224': 'CUCUNUBA',
            '25_245': 'EL COLEGIO',
            '25_258': 'EL PEÑON',
            '25_260': 'EL ROSAL',
            '25_269': 'FACATATIVA',
            '25_279': 'FOMEQUE',
            '25_281': 'FOSCA',
            '25_286': 'FUNZA',
            '25_288': 'FUQUENE',
            '25_290': 'FUSAGASUGA',
            '25_293': 'GACHALA',
            '25_295': 'GACHANCIPA',
            '25_297': 'GACHETA',
            '25_299': 'GAMA',
            '25_307': 'GIRARDOT',
            '25_312': 'GRANADA',
            '25_317': 'GUACHETA',
            '25_320': 'GUADUAS',
            '25_322': 'GUASCA',
            '25_324': 'GUATAQUI',
            '25_326': 'GUATAVITA',
            '25_328': 'GUAYABAL DE SIQUIMA',
            '25_335': 'GUAYABETAL',
            '25_339': 'GUTIERREZ',
            '25_368': 'JERUSALEN',
            '25_372': 'JUNIN',
            '25_377': 'LA CALERA',
            '25_386': 'LA MESA',
            '25_394': 'LA PALMA',
            '25_398': 'LA PEÑA',
            '25_402': 'LA VEGA',
            '25_407': 'LENGUAZAQUE',
            '25_426': 'MACHETA',
            '25_430': 'MADRID',
            '25_436': 'MANTA',
            '25_438': 'MEDINA',
            '25_473': 'MOSQUERA',
            '25_483': 'NARIÑO',
            '25_486': 'NEMOCON',
            '25_488': 'NILO',
            '25_489': 'NIMAIMA',
            '25_491': 'NOCAIMA',
            '25_506': 'VENECIA',
            '25_513': 'PACHO',
            '25_518': 'PAIME',
            '25_524': 'PANDI',
            '25_530': 'PARATEBUENO',
            '25_535': 'PASCA',
            '25_572': 'PUERTO SALGAR',
            '25_580': 'PULI',
            '25_592': 'QUEBRADANEGRA',
            '25_594': 'QUETAME',
            '25_596': 'QUIPILE',
            '25_599': 'APULO',
            '25_612': 'RICAURTE',
            '25_645': 'SAN ANTONIO DEL TEQUENDAMA',
            '25_649': 'SAN BERNARDO',
            '25_653': 'SAN CAYETANO',
            '25_658': 'SAN FRANCISCO',
            '25_662': 'SAN JUAN DE RIO SECO',
            '25_718': 'SASAIMA',
            '25_736': 'SESQUILE',
            '25_740': 'SIBATE',
            '25_743': 'SILVANIA',
            '25_745': 'SIMIJACA',
            '25_754': 'SOACHA',
            '25_758': 'SOPO',
            '25_769': 'SUBACHOQUE',
            '25_772': 'SUESCA',
            '25_777': 'SUPATA',
            '25_779': 'SUSA',
            '25_781': 'SUTATAUSA',
            '25_785': 'TABIO',
            '25_793': 'TAUSA',
            '25_797': 'TENA',
            '25_799': 'TENJO',
            '25_805': 'TIBACUY',
            '25_807': 'TIBIRITA',
            '25_815': 'TOCAIMA',
            '25_817': 'TOCANCIPA',
            '25_823': 'TOPAIPI',
            '25_839': 'UBALA',
            '25_841': 'UBAQUE',
            '25_843': 'VILLA DE SAN DIEGO DE UBATE',
            '25_845': 'UNE',
            '25_851': 'UTICA',
            '25_862': 'VERGARA',
            '25_867': 'VIANI',
            '25_871': 'VILLAGOMEZ',
            '25_873': 'VILLAPINZON',
            '25_875': 'VILLETA',
            '25_878': 'VIOTA',
            '25_885': 'YACOPI',
            '25_898': 'ZIPACON',
            '25_899': 'ZIPAQUIRA',
            '27_001': 'QUIBDO',
            '27_006': 'ACANDI',
            '27_025': 'ALTO BAUDO',
            '27_050': 'ATRATO',
            '27_073': 'BAGADO',
            '27_075': 'BAHIA SOLANO',
            '27_077': 'BAJO BAUDO',
            '27_099': 'BOJAYA',
            '27_135': 'EL CANTON DEL SAN PABLO',
            '27_150': 'CARMEN DEL DARIEN',
            '27_160': 'CERTEGUI',
            '27_205': 'CONDOTO',
            '27_245': 'EL CARMEN DE ATRATO',
            '27_250': 'EL LITORAL DEL SAN JUAN',
            '27_361': 'ISTMINA',
            '27_372': 'JURADO',
            '27_413': 'LLORO',
            '27_425': 'MEDIO ATRATO',
            '27_430': 'MEDIO BAUDO',
            '27_450': 'MEDIO SAN JUAN',
            '27_491': 'NOVITA',
            '27_495': 'NUQUI',
            '27_580': 'RIO IRO',
            '27_600': 'RIO QUITO',
            '27_615': 'RIOSUCIO',
            '27_660': 'SAN JOSE DEL PALMAR',
            '27_745': 'SIPI',
            '27_787': 'TADO',
            '27_800': 'UNGUIA',
            '27_810': 'UNION PANAMERICANA',
            '41_001': 'NEIVA',
            '41_006': 'ACEVEDO',
            '41_013': 'AGRADO',
            '41_016': 'AIPE',
            '41_020': 'ALGECIRAS',
            '41_026': 'ALTAMIRA',
            '41_078': 'BARAYA',
            '41_132': 'CAMPOALEGRE',
            '41_206': 'COLOMBIA',
            '41_244': 'ELIAS',
            '41_298': 'GARZON',
            '41_306': 'GIGANTE',
            '41_319': 'GUADALUPE',
            '41_349': 'HOBO',
            '41_357': 'IQUIRA',
            '41_359': 'ISNOS',
            '41_378': 'LA ARGENTINA',
            '41_396': 'LA PLATA',
            '41_483': 'NATAGA',
            '41_503': 'OPORAPA',
            '41_518': 'PAICOL',
            '41_524': 'PALERMO',
            '41_530': 'PALESTINA',
            '41_548': 'PITAL',
            '41_551': 'PITALITO',
            '41_615': 'RIVERA',
            '41_660': 'SALADOBLANCO',
            '41_668': 'SAN AGUSTIN',
            '41_676': 'SANTA MARIA',
            '41_770': 'SUAZA',
            '41_791': 'TARQUI',
            '41_797': 'TESALIA',
            '41_799': 'TELLO',
            '41_801': 'TERUEL',
            '41_807': 'TIMANA',
            '41_872': 'VILLAVIEJA',
            '41_885': 'YAGUARA',
            '44_001': 'RIOHACHA',
            '44_035': 'ALBANIA',
            '44_078': 'BARRANCAS',
            '44_090': 'DIBULLA',
            '44_098': 'DISTRACCION',
            '44_110': 'EL MOLINO',
            '44_279': 'FONSECA',
            '44_378': 'HATONUEVO',
            '44_420': 'LA JAGUA DEL PILAR',
            '44_430': 'MAICAO',
            '44_560': 'MANAURE',
            '44_650': 'SAN JUAN DEL CESAR',
            '44_847': 'URIBIA',
            '44_855': 'URUMITA',
            '44_874': 'VILLANUEVA',
            '47_001': 'SANTA MARTA',
            '47_030': 'ALGARROBO',
            '47_053': 'ARACATACA',
            '47_058': 'ARIGUANI',
            '47_161': 'CERRO SAN ANTONIO',
            '47_170': 'CHIBOLO',
            '47_189': 'CIENAGA',
            '47_205': 'CONCORDIA',
            '47_245': 'EL BANCO',
            '47_258': 'EL PIÑON',
            '47_268': 'EL RETEN',
            '47_288': 'FUNDACION',
            '47_318': 'GUAMAL',
            '47_460': 'NUEVA GRANADA',
            '47_541': 'PEDRAZA',
            '47_545': 'PIJIÑO DEL CARMEN',
            '47_551': 'PIVIJAY',
            '47_555': 'PLATO',
            '47_570': 'PUEBLOVIEJO',
            '47_605': 'REMOLINO',
            '47_660': 'SABANAS DE SAN ANGEL',
            '47_675': 'SALAMINA',
            '47_692': 'SAN SEBASTIAN DE BUENAVISTA',
            '47_703': 'SAN ZENON',
            '47_707': 'SANTA ANA',
            '47_720': 'SANTA BARBARA DE PINTO',
            '47_745': 'SITIONUEVO',
            '47_798': 'TENERIFE',
            '47_960': 'ZAPAYAN',
            '47_980': 'ZONA BANANERA',
            '50_001': 'VILLAVICENCIO',
            '50_006': 'ACACIAS',
            '50_110': 'BARRANCA DE UPIA',
            '50_124': 'CABUYARO',
            '50_150': 'CASTILLA LA NUEVA',
            '50_223': 'CUBARRAL',
            '50_226': 'CUMARAL',
            '50_245': 'EL CALVARIO',
            '50_251': 'EL CASTILLO',
            '50_270': 'EL DORADO',
            '50_287': 'FUENTE DE ORO',
            '50_313': 'GRANADA',
            '50_318': 'GUAMAL',
            '50_325': 'MAPIRIPAN',
            '50_330': 'MESETAS',
            '50_350': 'LA MACARENA',
            '50_370': 'URIBE',
            '50_400': 'LEJANIAS',
            '50_450': 'PUERTO CONCORDIA',
            '50_568': 'PUERTO GAITAN',
            '50_573': 'PUERTO LOPEZ',
            '50_577': 'PUERTO LLERAS',
            '50_590': 'PUERTO RICO',
            '50_606': 'RESTREPO',
            '50_680': 'SAN CARLOS DE GUAROA',
            '50_683': 'SAN JUAN DE ARAMA',
            '50_686': 'SAN JUANITO',
            '50_689': 'SAN MARTIN',
            '50_711': 'VISTAHERMOSA',
            '52_001': 'PASTO',
            '52_019': 'ALBAN',
            '52_022': 'ALDANA',
            '52_036': 'ANCUYA',
            '52_051': 'ARBOLEDA',
            '52_079': 'BARBACOAS',
            '52_083': 'BELEN',
            '52_110': 'BUESACO',
            '52_203': 'COLON',
            '52_207': 'CONSACA',
            '52_210': 'CONTADERO',
            '52_215': 'CORDOBA',
            '52_224': 'CUASPUD',
            '52_227': 'CUMBAL',
            '52_233': 'CUMBITARA',
            '52_240': 'CHACHAGsI',
            '52_250': 'EL CHARCO',
            '52_254': 'EL PEÑOL',
            '52_256': 'EL ROSARIO',
            '52_258': 'EL TABLON DE GOMEZ',
            '52_260': 'EL TAMBO',
            '52_287': 'FUNES',
            '52_317': 'GUACHUCAL',
            '52_320': 'GUAITARILLA',
            '52_323': 'GUALMATAN',
            '52_352': 'ILES',
            '52_354': 'IMUES',
            '52_356': 'IPIALES',
            '52_378': 'LA CRUZ',
            '52_381': 'LA FLORIDA',
            '52_385': 'LA LLANADA',
            '52_390': 'LA TOLA',
            '52_399': 'LA UNION',
            '52_405': 'LEIVA',
            '52_411': 'LINARES',
            '52_418': 'LOS ANDES',
            '52_427': 'MAGsI',
            '52_435': 'MALLAMA',
            '52_473': 'MOSQUERA',
            '52_480': 'NARIÑO',
            '52_490': 'OLAYA HERRERA',
            '52_506': 'OSPINA',
            '52_520': 'FRANCISCO PIZARRO',
            '52_540': 'POLICARPA',
            '52_560': 'POTOSI',
            '52_565': 'PROVIDENCIA',
            '52_573': 'PUERRES',
            '52_585': 'PUPIALES',
            '52_612': 'RICAURTE',
            '52_621': 'ROBERTO PAYAN',
            '52_678': 'SAMANIEGO',
            '52_683': 'SANDONA',
            '52_685': 'SAN BERNARDO',
            '52_687': 'SAN LORENZO',
            '52_693': 'SAN PABLO',
            '52_694': 'SAN PEDRO DE CARTAGO',
            '52_696': 'SANTA BARBARA',
            '52_699': 'SANTACRUZ',
            '52_720': 'SAPUYES',
            '52_786': 'TAMINANGO',
            '52_788': 'TANGUA',
            '52_835': 'SAN ANDRES DE TUMACO',
            '52_838': 'TUQUERRES',
            '52_885': 'YACUANQUER',
            '54_001': 'CUCUTA',
            '54_003': 'ABREGO',
            '54_051': 'ARBOLEDAS',
            '54_099': 'BOCHALEMA',
            '54_109': 'BUCARASICA',
            '54_125': 'CACOTA',
            '54_128': 'CACHIRA',
            '54_172': 'CHINACOTA',
            '54_174': 'CHITAGA',
            '54_206': 'CONVENCION',
            '54_223': 'CUCUTILLA',
            '54_239': 'DURANIA',
            '54_245': 'EL CARMEN',
            '54_250': 'EL TARRA',
            '54_261': 'EL ZULIA',
            '54_313': 'GRAMALOTE',
            '54_344': 'HACARI',
            '54_347': 'HERRAN',
            '54_377': 'LABATECA',
            '54_385': 'LA ESPERANZA',
            '54_398': 'LA PLAYA',
            '54_405': 'LOS PATIOS',
            '54_418': 'LOURDES',
            '54_480': 'MUTISCUA',
            '54_498': 'OCAÑA',
            '54_518': 'PAMPLONA',
            '54_520': 'PAMPLONITA',
            '54_553': 'PUERTO SANTANDER',
            '54_599': 'RAGONVALIA',
            '54_660': 'SALAZAR',
            '54_670': 'SAN CALIXTO',
            '54_673': 'SAN CAYETANO',
            '54_680': 'SANTIAGO',
            '54_720': 'SARDINATA',
            '54_743': 'SILOS',
            '54_800': 'TEORAMA',
            '54_810': 'TIBU',
            '54_820': 'TOLEDO',
            '54_871': 'VILLA CARO',
            '54_874': 'VILLA DEL ROSARIO',
            '63_001': 'ARMENIA',
            '63_111': 'BUENAVISTA',
            '63_130': 'CALARCA',
            '63_190': 'CIRCASIA',
            '63_212': 'CORDOBA',
            '63_272': 'FILANDIA',
            '63_302': 'GENOVA',
            '63_401': 'LA TEBAIDA',
            '63_470': 'MONTENEGRO',
            '63_548': 'PIJAO',
            '63_594': 'QUIMBAYA',
            '63_690': 'SALENTO',
            '66_001': 'PEREIRA',
            '66_045': 'APIA',
            '66_075': 'BALBOA',
            '66_088': 'BELEN DE UMBRIA',
            '66_170': 'DOSQUEBRADAS',
            '66_318': 'GUATICA',
            '66_383': 'LA CELIA',
            '66_400': 'LA VIRGINIA',
            '66_440': 'MARSELLA',
            '66_456': 'MISTRATO',
            '66_572': 'PUEBLO RICO',
            '66_594': 'QUINCHIA',
            '66_682': 'SANTA ROSA DE CABAL',
            '66_687': 'SANTUARIO',
            '68_001': 'BUCARAMANGA',
            '68_013': 'AGUADA',
            '68_020': 'ALBANIA',
            '68_051': 'ARATOCA',
            '68_077': 'BARBOSA',
            '68_079': 'BARICHARA',
            '68_081': 'BARRANCABERMEJA',
            '68_092': 'BETULIA',
            '68_101': 'BOLIVAR',
            '68_121': 'CABRERA',
            '68_132': 'CALIFORNIA',
            '68_147': 'CAPITANEJO',
            '68_152': 'CARCASI',
            '68_160': 'CEPITA',
            '68_162': 'CERRITO',
            '68_167': 'CHARALA',
            '68_169': 'CHARTA',
            '68_176': 'CHIMA',
            '68_179': 'CHIPATA',
            '68_190': 'CIMITARRA',
            '68_207': 'CONCEPCION',
            '68_209': 'CONFINES',
            '68_211': 'CONTRATACION',
            '68_217': 'COROMORO',
            '68_229': 'CURITI',
            '68_235': 'EL CARMEN DE CHUCURI',
            '68_245': 'EL GUACAMAYO',
            '68_250': 'EL PEÑON',
            '68_255': 'EL PLAYON',
            '68_264': 'ENCINO',
            '68_266': 'ENCISO',
            '68_271': 'FLORIAN',
            '68_276': 'FLORIDABLANCA',
            '68_296': 'GALAN',
            '68_298': 'GAMBITA',
            '68_307': 'GIRON',
            '68_318': 'GUACA',
            '68_320': 'GUADALUPE',
            '68_322': 'GUAPOTA',
            '68_324': 'GUAVATA',
            '68_327': 'GsEPSA',
            '68_344': 'HATO',
            '68_368': 'JESUS MARIA',
            '68_370': 'JORDAN',
            '68_377': 'LA BELLEZA',
            '68_385': 'LANDAZURI',
            '68_397': 'LA PAZ',
            '68_406': 'LEBRIJA',
            '68_418': 'LOS SANTOS',
            '68_425': 'MACARAVITA',
            '68_432': 'MALAGA',
            '68_444': 'MATANZA',
            '68_464': 'MOGOTES',
            '68_468': 'MOLAGAVITA',
            '68_498': 'OCAMONTE',
            '68_500': 'OIBA',
            '68_502': 'ONZAGA',
            '68_522': 'PALMAR',
            '68_524': 'PALMAS DEL SOCORRO',
            '68_533': 'PARAMO',
            '68_547': 'PIEDECUESTA',
            '68_549': 'PINCHOTE',
            '68_572': 'PUENTE NACIONAL',
            '68_573': 'PUERTO PARRA',
            '68_575': 'PUERTO WILCHES',
            '68_615': 'RIONEGRO',
            '68_655': 'SABANA DE TORRES',
            '68_669': 'SAN ANDRES',
            '68_673': 'SAN BENITO',
            '68_679': 'SAN GIL',
            '68_682': 'SAN JOAQUIN',
            '68_684': 'SAN JOSE DE MIRANDA',
            '68_686': 'SAN MIGUEL',
            '68_689': 'SAN VICENTE DE CHUCURI',
            '68_705': 'SANTA BARBARA',
            '68_720': 'SANTA HELENA DEL OPON',
            '68_745': 'SIMACOTA',
            '68_755': 'SOCORRO',
            '68_770': 'SUAITA',
            '68_773': 'SUCRE',
            '68_780': 'SURATA',
            '68_820': 'TONA',
            '68_855': 'VALLE DE SAN JOSE',
            '68_861': 'VELEZ',
            '68_867': 'VETAS',
            '68_872': 'VILLANUEVA',
            '68_895': 'ZAPATOCA',
            '70_001': 'SINCELEJO',
            '70_110': 'BUENAVISTA',
            '70_124': 'CAIMITO',
            '70_204': 'COLOSO',
            '70_215': 'COROZAL',
            '70_221': 'COVEÑAS',
            '70_230': 'CHALAN',
            '70_233': 'EL ROBLE',
            '70_235': 'GALERAS',
            '70_265': 'GUARANDA',
            '70_400': 'LA UNION',
            '70_418': 'LOS PALMITOS',
            '70_429': 'MAJAGUAL',
            '70_473': 'MORROA',
            '70_508': 'OVEJAS',
            '70_523': 'PALMITO',
            '70_670': 'SAMPUES',
            '70_678': 'SAN BENITO ABAD',
            '70_702': 'SAN JUAN DE BETULIA',
            '70_708': 'SAN MARCOS',
            '70_713': 'SAN ONOFRE',
            '70_717': 'SAN PEDRO',
            '70_742': 'SAN LUIS DE SINCE',
            '70_771': 'SUCRE',
            '70_820': 'SANTIAGO DE TOLU',
            '70_823': 'TOLU VIEJO',
            '73_001': 'IBAGUE',
            '73_024': 'ALPUJARRA',
            '73_026': 'ALVARADO',
            '73_030': 'AMBALEMA',
            '73_043': 'ANZOATEGUI',
            '73_055': 'ARMERO',
            '73_067': 'ATACO',
            '73_124': 'CAJAMARCA',
            '73_148': 'CARMEN DE APICALA',
            '73_152': 'CASABIANCA',
            '73_168': 'CHAPARRAL',
            '73_200': 'COELLO',
            '73_217': 'COYAIMA',
            '73_226': 'CUNDAY',
            '73_236': 'DOLORES',
            '73_268': 'ESPINAL',
            '73_270': 'FALAN',
            '73_275': 'FLANDES',
            '73_283': 'FRESNO',
            '73_319': 'GUAMO',
            '73_347': 'HERVEO',
            '73_349': 'HONDA',
            '73_352': 'ICONONZO',
            '73_408': 'LERIDA',
            '73_411': 'LIBANO',
            '73_443': 'MARIQUITA',
            '73_449': 'MELGAR',
            '73_461': 'MURILLO',
            '73_483': 'NATAGAIMA',
            '73_504': 'ORTEGA',
            '73_520': 'PALOCABILDO',
            '73_547': 'PIEDRAS',
            '73_555': 'PLANADAS',
            '73_563': 'PRADO',
            '73_585': 'PURIFICACION',
            '73_616': 'RIOBLANCO',
            '73_622': 'RONCESVALLES',
            '73_624': 'ROVIRA',
            '73_671': 'SALDAÑA',
            '73_675': 'SAN ANTONIO',
            '73_678': 'SAN LUIS',
            '73_686': 'SANTA ISABEL',
            '73_770': 'SUAREZ',
            '73_854': 'VALLE DE SAN JUAN',
            '73_861': 'VENADILLO',
            '73_870': 'VILLAHERMOSA',
            '73_873': 'VILLARRICA',
            '76_001': 'CALI',
            '76_020': 'ALCALA',
            '76_036': 'ANDALUCIA',
            '76_041': 'ANSERMANUEVO',
            '76_054': 'ARGELIA',
            '76_100': 'BOLIVAR',
            '76_109': 'BUENAVENTURA',
            '76_111': 'GUADALAJARA DE BUGA',
            '76_113': 'BUGALAGRANDE',
            '76_122': 'CAICEDONIA',
            '76_126': 'CALIMA',
            '76_130': 'CANDELARIA',
            '76_147': 'CARTAGO',
            '76_233': 'DAGUA',
            '76_243': 'EL AGUILA',
            '76_246': 'EL CAIRO',
            '76_248': 'EL CERRITO',
            '76_250': 'EL DOVIO',
            '76_275': 'FLORIDA',
            '76_306': 'GINEBRA',
            '76_318': 'GUACARI',
            '76_364': 'JAMUNDI',
            '76_377': 'LA CUMBRE',
            '76_400': 'LA UNION',
            '76_403': 'LA VICTORIA',
            '76_497': 'OBANDO',
            '76_520': 'PALMIRA',
            '76_563': 'PRADERA',
            '76_606': 'RESTREPO',
            '76_616': 'RIOFRIO',
            '76_622': 'ROLDANILLO',
            '76_670': 'SAN PEDRO',
            '76_736': 'SEVILLA',
            '76_823': 'TORO',
            '76_828': 'TRUJILLO',
            '76_834': 'TULUA',
            '76_845': 'ULLOA',
            '76_863': 'VERSALLES',
            '76_869': 'VIJES',
            '76_890': 'YOTOCO',
            '76_892': 'YUMBO',
            '76_895': 'ZARZAL',
            '81_001': 'ARAUCA',
            '81_065': 'ARAUQUITA',
            '81_220': 'CRAVO NORTE',
            '81_300': 'FORTUL',
            '81_591': 'PUERTO RONDON',
            '81_736': 'SARAVENA',
            '81_794': 'TAME',
            '85_001': 'YOPAL',
            '85_010': 'AGUAZUL',
            '85_015': 'CHAMEZA',
            '85_125': 'HATO COROZAL',
            '85_136': 'LA SALINA',
            '85_139': 'MANI',
            '85_162': 'MONTERREY',
            '85_225': 'NUNCHIA',
            '85_230': 'OROCUE',
            '85_250': 'PAZ DE ARIPORO',
            '85_263': 'PORE',
            '85_279': 'RECETOR',
            '85_300': 'SABANALARGA',
            '85_315': 'SACAMA',
            '85_325': 'SAN LUIS DE PALENQUE',
            '85_400': 'TAMARA',
            '85_410': 'TAURAMENA',
            '85_430': 'TRINIDAD',
            '85_440': 'VILLANUEVA',
            '86_001': 'MOCOA',
            '86_219': 'COLON',
            '86_320': 'ORITO',
            '86_568': 'PUERTO ASIS',
            '86_569': 'PUERTO CAICEDO',
            '86_571': 'PUERTO GUZMAN',
            '86_573': 'LEGUIZAMO',
            '86_749': 'SIBUNDOY',
            '86_755': 'SAN FRANCISCO',
            '86_757': 'SAN MIGUEL',
            '86_760': 'SANTIAGO',
            '86_865': 'VALLE DEL GUAMUEZ',
            '86_885': 'VILLAGARZON',
            '88_001': 'SAN ANDRES',
            '88_564': 'PROVIDENCIA',
            '91_001': 'LETICIA',
            '91_263': 'EL ENCANTO',
            '91_405': 'LA CHORRERA',
            '91_407': 'LA PEDRERA',
            '91_430': 'LA VICTORIA',
            '91_460': 'MIRITI - PARANA',
            '91_530': 'PUERTO ALEGRIA',
            '91_536': 'PUERTO ARICA',
            '91_540': 'PUERTO NARIÑO',
            '91_669': 'PUERTO SANTANDER',
            '91_798': 'TARAPACA',
            '94_001': 'INIRIDA',
            '94_343': 'BARRANCO MINAS',
            '94_663': 'MAPIRIPANA',
            '94_883': 'SAN FELIPE',
            '94_884': 'PUERTO COLOMBIA',
            '94_885': 'LA GUADALUPE',
            '94_886': 'CACAHUAL',
            '94_887': 'PANA PANA',
            '94_888': 'MORICHAL',
            '95_001': 'SAN JOSE DEL GUAVIARE',
            '95_015': 'CALAMAR',
            '95_025': 'EL RETORNO',
            '95_200': 'MIRAFLORES',
            '97_001': 'MITU',
            '97_161': 'CARURU',
            '97_511': 'PACOA',
            '97_666': 'TARAIRA',
            '97_777': 'PAPUNAUA',
            '97_889': 'YAVARATE',
            '99_001': 'PUERTO CARREÑO',
            '99_524': 'LA PRIMAVERA',
            '99_624': 'SANTA ROSALIA',

        },
        "barrios": [

            { "barrios": "ALDEA PABLO VI" },
            { "barrios": "ALEJANDRO ECHAVARRÍA" },
            { "barrios": "ALEJANDRÍA" },
            { "barrios": "ALFONSO LÓPEZ" },
            { "barrios": "ALTAMIRA" },
            { "barrios": "ALTAVISTA" },
            { "barrios": "ALTOS DEL POBLADO" },
            { "barrios": "ANDALUCÍA" },
            { "barrios": "ANTONIO NARIÑO" },
            { "barrios": "ARANJUEZ" },
            { "barrios": "ASOMADERA Nº 1" },
            { "barrios": "ASOMADERA Nº 2" },
            { "barrios": "ASOMADERA Nº 3" },
            { "barrios": "ASTORGA" },
            { "barrios": "AURES Nº 1" },
            { "barrios": "AURES Nº 2" },
            { "barrios": "BARRIO COLOMBIA" },
            { "barrios": "BARRIO COLÓN" },
            { "barrios": "BARRIO CRISTÓBAL" },
            { "barrios": "BARRIO FACULTAD DE MINAS" },
            { "barrios": "BARRIOS DE JESÚS" },
            { "barrios": "BATALLÓN GIRARDOT" },
            { "barrios": "BELALCAZAR" },
            { "barrios": "BELENCITO" },
            { "barrios": "BELLO HORIZONTE" },
            { "barrios": "BELÉN" },
            { "barrios": "BERLÍN" },
            { "barrios": "BERMEJAL - LOS ÁLAMOS" },
            { "barrios": "BETANIA" },
            { "barrios": "BLANQUIZAL" },
            { "barrios": "BOLIVARIANA" },
            { "barrios": "BOMBONA Nº 1" },
            { "barrios": "BOMBONA Nº 2" },
            { "barrios": "BOSQUES DE SAN PABLO" },
            { "barrios": "BOSTON" },
            { "barrios": "BOYACÁ" },
            { "barrios": "BRASILIA" },
            { "barrios": "BUENOS AIRES" },
            { "barrios": "CALASANZ" },
            { "barrios": "CALASANZ PARTE ALTA" },
            { "barrios": "CALLE NUEVA" },
            { "barrios": "CAMPO ALEGRE" },
            { "barrios": "CAMPO AMOR, NOEL" },
            { "barrios": "CAMPO VALDES Nº 1" },
            { "barrios": "CAMPO VALDES Nº 2" },
            { "barrios": "CARIBE" },
            { "barrios": "CARLOS E. RESTREPO" },
            { "barrios": "CARPINELO" },
            { "barrios": "CASTILLA" },
            { "barrios": "CASTROPOL" },
            { "barrios": "CATALUÑA" },
            { "barrios": "CERRO EL VOLADOR" },
            { "barrios": "CERRO NUTIBARA" },
            { "barrios": "CORAZÓN DE JESÚS" },
            { "barrios": "CRISTO REY" },
            { "barrios": "CUARTA BRIGADA" },
            { "barrios": "CUCARACHO" },
            { "barrios": "CÓRDOBA" },
            { "barrios": "DIEGO ECHEVARRIA" },
            { "barrios": "DOCE DE OCTUBRE Nº 1" },
            { "barrios": "DOCE DE OCTUBRE Nº 2" },
            { "barrios": "EDUARDO SANTOS" },
            { "barrios": "EL CASTILLO" },
            { "barrios": "EL CHAGUALO" },
            { "barrios": "EL COMPROMISO" },
            { "barrios": "EL CORAZÓN" },
            { "barrios": "EL DANUBIO" },
            { "barrios": "EL DIAMANTE" },
            { "barrios": "EL DIAMANTE Nº 2" },
            { "barrios": "EL NOGAL - LOS ALMENDROS" },
            { "barrios": "EL PESEBRE" },
            { "barrios": "EL PINAL" },
            { "barrios": "EL PLAYÓN DE LOS COMUNEROS" },
            { "barrios": "EL POBLADO" },
            { "barrios": "EL POMAR" },
            { "barrios": "EL PROGRESO" },
            { "barrios": "EL RAIZAL" },
            { "barrios": "EL RINCÓN" },
            { "barrios": "EL SALADO" },
            { "barrios": "EL SALVADOR" },
            { "barrios": "EL SOCORRO" },
            { "barrios": "EL TESORO" },
            { "barrios": "EL TRIUNFO" },
            { "barrios": "EL VELÓDROMO" },
            { "barrios": "ENCISO" },
            { "barrios": "ESTACIÓN VILLA" },
            { "barrios": "ESTADIO" },
            { "barrios": "FERRINI" },
            { "barrios": "FLORENCIA" },
            { "barrios": "FLORIDA NUEVA" },
            { "barrios": "FRANCISCO ANTONIO ZEA" },
            { "barrios": "FUENTE CLARA" },
            { "barrios": "FÁTIMA" },
            { "barrios": "GERONA" },
            { "barrios": "GIRARDOT" },
            { "barrios": "GRANADA" },
            { "barrios": "GRANIZAL" },
            { "barrios": "GUAYABAL" },
            { "barrios": "GUAYAQUIL" },
            { "barrios": "HÉCTOR ABAD GÓMEZ" },
            { "barrios": "JESÚS NAZARENO" },
            { "barrios": "JUAN PABLO II" },
            { "barrios": "JUAN XIII - LA QUIEBRA" },
            { "barrios": "KENNEDY" },
            { "barrios": "LA AGUACATALA" },
            { "barrios": "LA AMÉRICA" },
            { "barrios": "LA AVANZADA" },
            { "barrios": "LA CANDELARIA" },
            { "barrios": "LA CASTELLANA" },
            { "barrios": "LA COLINA" },
            { "barrios": "LA CRUZ" },
            { "barrios": "LA DIVISA" },
            { "barrios": "LA ESPERANZA" },
            { "barrios": "LA ESPERANZA Nº 2" },
            { "barrios": "LA FLORESTA" },
            { "barrios": "LA FLORIDA" },
            { "barrios": "LA FRANCIA" },
            { "barrios": "LA FRONTERA" },
            { "barrios": "LA GLORIA" },
            { "barrios": "LA HONDA" },
            { "barrios": "LA ISLA" },
            { "barrios": "LA LADERA" },
            { "barrios": "LA LIBERTAD" },
            { "barrios": "LA LOMA DE LOS BERNAL" },
            { "barrios": "LA MANSIÓN" },
            { "barrios": "LA MILAGROSA" },
            { "barrios": "LA MOTA" },
            { "barrios": "LA PALMA" },
            { "barrios": "LA PARALELA" },
            { "barrios": "LA PILARICA" },
            { "barrios": "LA PIÑUELA" },
            { "barrios": "LA PRADERA" },
            { "barrios": "LA ROSA" },
            { "barrios": "LA SALLE" },
            { "barrios": "LA SIERRA (SANTA LUCÍA - LAS ESTANCIAS)" },
            { "barrios": "LALINDE" },
            { "barrios": "LAS ACACIAS" },
            { "barrios": "LAS BRISAS" },
            { "barrios": "LAS ESMERALDAS" },
            { "barrios": "LAS ESTANCIAS" },
            { "barrios": "LAS GRANJAS" },
            { "barrios": "LAS INDEPENDENCIAS" },
            { "barrios": "LAS LOMAS Nº 1" },
            { "barrios": "LAS LOMAS Nº 2" },
            { "barrios": "LAS MERCEDES" },
            { "barrios": "LAS PALMAS" },
            { "barrios": "LAS PLAYAS" },
            { "barrios": "LAS VIOLETAS" },
            { "barrios": "LAURELES" },
            { "barrios": "LLANADITAS" },
            { "barrios": "LORENA" },
            { "barrios": "LORETO" },
            { "barrios": "LOS ALCÁZARES" },
            { "barrios": "LOS ALPES" },
            { "barrios": "LOS BALSOS Nº 1" },
            { "barrios": "LOS BALSOS Nº 2" },
            { "barrios": "LOS CERROS EL VERGEL" },
            { "barrios": "LOS COLORES" },
            { "barrios": "LOS CONQUISTADORES" },
            { "barrios": "LOS MANGOS" },
            { "barrios": "LOS NARANJOS" },
            { "barrios": "LOS PINOS" },
            { "barrios": "LOS ÁNGELES" },
            { "barrios": "LÓPEZ DE MESA" },
            { "barrios": "MANILA" },
            { "barrios": "MANRIQUE CENTRAL Nº 1" },
            { "barrios": "MANRIQUE CENTRAL Nº 2" },
            { "barrios": "MANRIQUE ORIENTAL" },
            { "barrios": "MARIA CANO – CARAMBOLAS" },
            { "barrios": "METROPOLITANO" },
            { "barrios": "MIRADOR DEL DOCE" },
            { "barrios": "MIRAFLORES" },
            { "barrios": "MIRANDA" },
            { "barrios": "MIRAVALLE" },
            { "barrios": "MONTECLARO" },
            { "barrios": "MORAVIA" },
            { "barrios": "MOSCÚ Nº 1" },
            { "barrios": "MOSCÚ Nº 2" },
            { "barrios": "NARANJAL" },
            { "barrios": "NUEVA VILLA DE ABURRÁ" },
            { "barrios": "NUEVA VILLA DE LA IGUANÁ" },
            { "barrios": "NUEVOS CONQUISTADORES" },
            { "barrios": "OCHO DE MARZO" },
            { "barrios": "OLAYA HERRERA" },
            { "barrios": "ORIENTE" },
            { "barrios": "PABLO VI" },
            { "barrios": "PAJARITO" },
            { "barrios": "PALENQUE" },
            { "barrios": "PALERMO" },
            { "barrios": "PARQUE JUAN PABLO II" },
            { "barrios": "PATIO BONITO" },
            { "barrios": "PEDREGAL" },
            { "barrios": "PERPETUO SOCORRO" },
            { "barrios": "PEÑITAS" },
            { "barrios": "PICACHITO" },
            { "barrios": "PICACHO" },
            { "barrios": "POPULAR" },
            { "barrios": "PRADO" },
            { "barrios": "PROGRESO Nº 2" },
            { "barrios": "ROBLEDO" },
            { "barrios": "RODEO ALTO-LA HONDONADA" },
            { "barrios": "ROSALES" },
            { "barrios": "SAN ANTONIO" },
            { "barrios": "SAN BENITO" },
            { "barrios": "SAN BERNARDO" },
            { "barrios": "SAN DIEGO" },
            { "barrios": "SAN GERMÁN" },
            { "barrios": "SAN ISIDRO" },
            { "barrios": "SAN JAVIER Nº 1" },
            { "barrios": "SAN JAVIER Nº 2" },
            { "barrios": "SAN JOAQUÍN" },
            { "barrios": "SAN JOSÉ LA CIMA Nº 1" },
            { "barrios": "SAN JOSÉ LA CIMA Nº 2" },
            { "barrios": "SAN LUCAS" },
            { "barrios": "SAN MARTÍN DE PORRES" },
            { "barrios": "SAN MIGUEL" },
            { "barrios": "SAN PABLO" },
            { "barrios": "SAN PEDRO" },
            { "barrios": "SANTA CRUZ" },
            { "barrios": "SANTA FE" },
            { "barrios": "SANTA INÉS" },
            { "barrios": "SANTA LUCÍA" },
            { "barrios": "SANTA MARGARITA" },
            { "barrios": "SANTA MARÍA DE LOS ÁNGELES" },
            { "barrios": "SANTA MÓNICA" },
            { "barrios": "SANTA ROSA DE LIMA" },
            { "barrios": "SANTA TERESITA" },
            { "barrios": "SANTANDER" },
            { "barrios": "SANTO DOMINGO SAVIO Nº 1" },
            { "barrios": "SANTO DOMINGO SAVIO Nº 2" },
            { "barrios": "SEVILLA" },
            { "barrios": "SHELLMAR" },
            { "barrios": "SIMESA" },
            { "barrios": "SIMÓN BOLÍVAR" },
            { "barrios": "SUCRE" },
            { "barrios": "SURAMERICANA" },
            { "barrios": "TEJELO" },
            { "barrios": "TENCHE" },
            { "barrios": "TOSCANA" },
            { "barrios": "TRECE DE NOVIEMBRE" },
            { "barrios": "TRICENTENARIO" },
            { "barrios": "TRINIDAD" },
            { "barrios": "VEINTE DE JULIO" },
            { "barrios": "VERSALLES Nº 1" },
            { "barrios": "VERSALLES Nº 2" },
            { "barrios": "VILLA CARLOTA" },
            { "barrios": "VILLA DEL SOCORRO" },
            { "barrios": "VILLA FLORA" },
            { "barrios": "VILLA GUADALUPE" },
            { "barrios": "VILLA HERMOSA" },
            { "barrios": "VILLA LILLIAM" },
            { "barrios": "VILLA NIZA" },
            { "barrios": "VILLA NUEVA" },
            { "barrios": "VILLA TURBAY" },
            { "barrios": "VILLATINA" },
            { "barrios": "ZAFRA" },
            { "barrios": "OTRO" },
            { "barrios": "OTROS BARRIOS DE MEDELLIN" },
        ],
        "cities": {
            "ANTIOQUIA": [
                { "name": 'MEDELLIN', "codigo": "5_001" },
                { "name": 'ABEJORRAL', "codigo": "5_002" },
                { "name": 'ABRIAQUI', "codigo": "5_004" },
                { "name": 'ALEJANDRIA', "codigo": "5_021" },
                { "name": 'AMAGA', "codigo": "5_030" },
                { "name": 'AMALFI', "codigo": "5_031" },
                { "name": 'ANDES', "codigo": "5_034" },
                { "name": 'ANGELOPOLIS', "codigo": "5_036" },
                { "name": 'ANGOSTURA', "codigo": "5_038" },
                { "name": 'ANORI', "codigo": "5_040" },
                { "name": 'SANTAFE DE ANTIOQUIA', "codigo": "5_042" },
                { "name": 'ANZA', "codigo": "5_044" },
                { "name": 'APARTADO', "codigo": "5_045" },
                { "name": 'ARBOLETES', "codigo": "5_051" },
                { "name": 'ARGELIA', "codigo": "5_055" },
                { "name": 'ARMENIA', "codigo": "5_059" },
                { "name": 'BARBOSA', "codigo": "5_079" },
                { "name": 'BELMIRA', "codigo": "5_086" },
                { "name": 'BELLO', "codigo": "5_088" },
                { "name": 'BETANIA', "codigo": "5_091" },
                { "name": 'BETULIA', "codigo": "5_093" },
                { "name": 'CIUDAD BOLIVAR', "codigo": "5_101" },
                { "name": 'BRICEÑO', "codigo": "5_107" },
                { "name": 'BURITICA', "codigo": "5_113" },
                { "name": 'CACERES', "codigo": "5_120" },
                { "name": 'CAICEDO', "codigo": "5_125" },
                { "name": 'CALDAS', "codigo": "5_129" },
                { "name": 'CAMPAMENTO', "codigo": "5_134" },
                { "name": 'CAÑASGORDAS', "codigo": "5_138" },
                { "name": 'CARACOLI', "codigo": "5_142" },
                { "name": 'CARAMANTA', "codigo": "5_145" },
                { "name": 'CAREPA', "codigo": "5_147" },
                { "name": 'EL CARMEN DE VIBORAL', "codigo": "5_148" },
                { "name": 'CAROLINA', "codigo": "5_150" },
                { "name": 'CAUCASIA', "codigo": "5_154" },
                { "name": 'CHIGORODO', "codigo": "5_172" },
                { "name": 'CISNEROS', "codigo": "5_190" },
                { "name": 'COCORNA', "codigo": "5_197" },
                { "name": 'CONCEPCION', "codigo": "5_206" },
                { "name": 'CONCORDIA', "codigo": "5_209" },
                { "name": 'COPACABANA', "codigo": "5_212" },
                { "name": 'DABEIBA', "codigo": "5_234" },
                { "name": 'DON MATIAS', "codigo": "5_237" },
                { "name": 'EBEJICO', "codigo": "5_240" },
                { "name": 'EL BAGRE', "codigo": "5_250" },
                { "name": 'ENTRERRIOS', "codigo": "5_264" },
                { "name": 'ENVIGADO', "codigo": "5_266" },
                { "name": 'FREDONIA', "codigo": "5_282" },
                { "name": 'FRONTINO', "codigo": "5_284" },
                { "name": 'GIRALDO', "codigo": "5_306" },
                { "name": 'GIRARDOTA', "codigo": "5_308" },
                { "name": 'GOMEZ PLATA', "codigo": "5_310" },
                { "name": 'GRANADA', "codigo": "5_313" },
                { "name": 'GUADALUPE', "codigo": "5_315" },
                { "name": 'GUARNE', "codigo": "5_318" },
                { "name": 'GUATAPE', "codigo": "5_321" },
                { "name": 'HELICONIA', "codigo": "5_347" },
                { "name": 'HISPANIA', "codigo": "5_353" },
                { "name": 'ITAGUI', "codigo": "5_360" },
                { "name": 'ITUANGO', "codigo": "5_361" },
                { "name": 'JARDIN', "codigo": "5_364" },
                { "name": 'JERICO', "codigo": "5_368" },
                { "name": 'LA CEJA', "codigo": "5_376" },
                { "name": 'LA ESTRELLA', "codigo": "5_380" },
                { "name": 'LA PINTADA', "codigo": "5_390" },
                { "name": 'LA UNION', "codigo": "5_400" },
                { "name": 'LIBORINA', "codigo": "5_411" },
                { "name": 'MACEO', "codigo": "5_425" },
                { "name": 'MARINILLA', "codigo": "5_440" },
                { "name": 'MONTEBELLO', "codigo": "5_467" },
                { "name": 'MURINDO', "codigo": "5_475" },
                { "name": 'MUTATA', "codigo": "5_480" },
                { "name": 'NARIÑO', "codigo": "5_483" },
                { "name": 'NECOCLI', "codigo": "5_490" },
                { "name": 'NECHI', "codigo": "5_495" },
                { "name": 'OLAYA', "codigo": "5_501" },
                { "name": 'PEÐOL', "codigo": "5_541" },
                { "name": 'PEQUE', "codigo": "5_543" },
                { "name": 'PUEBLORRICO', "codigo": "5_576" },
                { "name": 'PUERTO BERRIO', "codigo": "5_579" },
                { "name": 'PUERTO NARE', "codigo": "5_585" },
                { "name": 'PUERTO TRIUNFO', "codigo": "5_591" },
                { "name": 'REMEDIOS', "codigo": "5_604" },
                { "name": 'RETIRO', "codigo": "5_607" },
                { "name": 'RIONEGRO', "codigo": "5_615" },
                { "name": 'SABANALARGA', "codigo": "5_628" },
                { "name": 'SABANETA', "codigo": "5_631" },
                { "name": 'SALGAR', "codigo": "5_642" },
                { "name": 'SAN ANDRES DE CUERQUIA', "codigo": "5_647" },
                { "name": 'SAN CARLOS', "codigo": "5_649" },
                { "name": 'SAN FRANCISCO', "codigo": "5_652" },
                { "name": 'SAN JERONIMO', "codigo": "5_656" },
                { "name": 'SAN JOSE DE LA MONTAÑA', "codigo": "5_658" },
                { "name": 'SAN JUAN DE URABA', "codigo": "5_659" },
                { "name": 'SAN LUIS', "codigo": "5_660" },
                { "name": 'SAN PEDRO', "codigo": "5_664" },
                { "name": 'SAN PEDRO DE URABA', "codigo": "5_665", },
                { "name": 'SAN RAFAEL', "codigo": "5_667" },
                { "name": 'SAN ROQUE', "codigo": "5_670" },
                { "name": 'SAN VICENTE', "codigo": "5_674" },
                { "name": 'SANTA BARBARA', "codigo": "5_679" },
                { "name": 'SANTA ROSA DE OSOS', "codigo": "5_686" },
                { "name": 'SANTO DOMINGO', "codigo": "5_690" },
                { "name": 'EL SANTUARIO', "codigo": "5_697" },
                { "name": 'SEGOVIA', "codigo": "5_736" },
                { "name": 'SONSON', "codigo": "5_756" },
                { "name": 'SOPETRAN', "codigo": "5_761" },
                { "name": 'TAMESIS', "codigo": "5_789" },
                { "name": 'TARAZA', "codigo": "5_790" },
                { "name": 'TARSO', "codigo": "5_792" },
                { "name": 'TITIRIBI', "codigo": "5_809" },
                { "name": 'TOLEDO', "codigo": "5_819" },
                { "name": 'TURBO', "codigo": "5_837" },
                { "name": 'URAMITA', "codigo": "5_842" },
                { "name": 'URRAO', "codigo": "5_847" },
                { "name": 'VALDIVIA', "codigo": "5_854" },
                { "name": 'VALPARAISO', "codigo": "5_856" },
                { "name": 'VEGACHI', "codigo": "5_858" },
                { "name": 'VENECIA', "codigo": "5_861" },
                { "name": 'VIGIA DEL FUERTE', "codigo": "5_873" },
                { "name": 'YALI', "codigo": "5_885" },
                { "name": 'YARUMAL', "codigo": "5_887" },
                { "name": 'YOLOMBO', "codigo": "5_890" },
                { "name": 'YONDO', "codigo": "5_893" },
                { "name": 'ZARAGOZA', "codigo": "5_895" },
            ],
            "ATLANTICO": [
                { "name": 'BARRANQUILLA', "codigo": '8_001' },
                { "name": 'BARANOA', "codigo": '8_078' },
                { "name": 'CAMPO DE LA CRUZ', "codigo": '8_137' },
                { "name": 'CANDELARIA', "codigo": '8_141' },
                { "name": 'GALAPA', "codigo": '8_296' },
                { "name": 'JUAN DE ACOSTA', "codigo": '8_372' },
                { "name": 'LURUACO', "codigo": '8_421' },
                { "name": 'MALAMBO', "codigo": '8_433' },
                { "name": 'MANATI', "codigo": '8_436' },
                { "name": 'PALMAR DE VARELA', "codigo": '8_520' },
                { "name": 'PIOJO', "codigo": '8_549' },
                { "name": 'POLONUEVO', "codigo": '8_558' },
                { "name": 'PONEDERA', "codigo": '8_560' },
                { "name": 'PUERTO COLOMBIA', "codigo": '8_573' },
                { "name": 'REPELON', "codigo": '8_606' },
                { "name": 'SABANAGRANDE', "codigo": '8_634' },
                { "name": 'SABANALARGA', "codigo": '8_638' },
                { "name": 'SANTA LUCIA', "codigo": '8_675' },
                { "name": 'SANTO TOMAS', "codigo": '8_685' },
                { "name": 'SOLEDAD', "codigo": '8_758' },
                { "name": 'SUAN', "codigo": '8_770' },
                { "name": 'TUBARA', "codigo": '8_832' },
                { "name": 'USIACURI', "codigo": '8_849' }
            ],
            "BOGOTA": [
                { "name": "BOGOTA, D.C.", "codigo": "11_001" }
            ],
            "BOLIVAR": [
                { 'name': 'CARTAGENA', "codigo": '13_001' },
                { 'name': 'ACHI', "codigo": '13_006' },
                { 'name': 'ALTOS DEL ROSARIO', "codigo": '13_030' },
                { 'name': 'ARENAL', "codigo": '13_042' },
                { 'name': 'ARJONA', "codigo": '13_052' },
                { 'name': 'ARROYOHONDO', "codigo": '13_062' },
                { 'name': 'BARRANCO DE LOBA', "codigo": '13_074' },
                { 'name': 'CALAMAR', "codigo": '13_140' },
                { 'name': 'CANTAGALLO', "codigo": '13_160' },
                { 'name': 'CICUCO', "codigo": '13_188' },
                { 'name': 'CORDOBA', "codigo": '13_212' },
                { 'name': 'CLEMENCIA', "codigo": '13_222' },
                { 'name': 'EL CARMEN DE BOLIVAR', "codigo": '13_244' },
                { 'name': 'EL GUAMO', "codigo": '13_248' },
                { 'name': 'EL PEÑON', "codigo": '13_268' },
                { 'name': 'HATILLO DE LOBA', "codigo": '13_300' },
                { 'name': 'MAGANGUE', "codigo": '13_430' },
                { 'name': 'MAHATES', "codigo": '13_433' },
                { 'name': 'MARGARITA', "codigo": '13_440' },
                { 'name': 'MARIA LA BAJA', "codigo": '13_442' },
                { 'name': 'MONTECRISTO', "codigo": '13_458' },
                { 'name': 'MOMPOS', "codigo": '13_468' },
                { 'name': 'NOROSI', "codigo": '13_490' },
                { 'name': 'MORALES', "codigo": '13_473' },
                { 'name': 'PINILLOS', "codigo": '13_549' },
                { 'name': 'REGIDOR', "codigo": '13_580' },
                { 'name': 'RIO VIEJO', "codigo": '13_600' },
                { 'name': 'SAN CRISTOBAL', "codigo": '13_620' },
                { 'name': 'SAN ESTANISLAO', "codigo": '13_647' },
                { 'name': 'SAN FERNANDO', "codigo": '13_650' },
                { 'name': 'SAN JACINTO', "codigo": '13_654' },
                { 'name': 'SAN JACINTO DEL CAUCA', "codigo": '13_655' },
                { 'name': 'SAN JUAN NEPOMUCENO', "codigo": '13_657' },
                { 'name': 'SAN MARTIN DE LOBA', "codigo": '13_667' },
                { 'name': 'SAN PABLO', "codigo": '13_670' },
                { 'name': 'SANTA CATALINA', "codigo": '13_673' },
                { 'name': 'SANTA ROSA', "codigo": '13_683' },
                { 'name': 'SANTA ROSA DEL SUR', "codigo": '13_688' },
                { 'name': 'SIMITI', "codigo": '13_744' },
                { 'name': 'SOPLAVIENTO', "codigo": '13_760' },
                { 'name': 'TALAIGUA NUEVO', "codigo": '13_780' },
                { 'name': 'TIQUISIO', "codigo": '13_810' },
                { 'name': 'TURBACO', "codigo": '13_836' },
                { 'name': 'TURBANA', "codigo": '13_838' },
                { 'name': 'VILLANUEVA', "codigo": '13_873' },
                { 'name': 'ZAMBRANO', "codigo": '13_894' },
            ],
            "BOYACA": [
                { "name": 'TUNJA', 'codigo': '15_001' },
                { "name": 'ALMEIDA', 'codigo': '15_022' },
                { "name": 'AQUITANIA', 'codigo': '15_047' },
                { "name": 'ARCABUCO', 'codigo': '15_051' },
                { "name": 'BELEN', 'codigo': '15_087' },
                { "name": 'BERBEO', 'codigo': '15_090' },
                { "name": 'BETEITIVA', 'codigo': '15_092' },
                { "name": 'BOAVITA', 'codigo': '15_097' },
                { "name": 'BOYACA', 'codigo': '15_104' },
                { "name": 'BRICEÑO', 'codigo': '15_106' },
                { "name": 'BUENAVISTA', 'codigo': '15_109' },
                { "name": 'BUSBANZA', 'codigo': '15_114' },
                { "name": 'CALDAS', 'codigo': '15_131' },
                { "name": 'CAMPOHERMOSO', 'codigo': '15_135' },
                { "name": 'CERINZA', 'codigo': '15_162' },
                { "name": 'CHINAVITA', 'codigo': '15_172' },
                { "name": 'CHIQUINQUIRA', 'codigo': '15_176' },
                { "name": 'CHISCAS', 'codigo': '15_180' },
                { "name": 'CHITA', 'codigo': '15_183' },
                { "name": 'CHITARAQUE', 'codigo': '15_185' },
                { "name": 'CHIVATA', 'codigo': '15_187' },
                { "name": 'CIENEGA', 'codigo': '15_189' },
                { "name": 'COMBITA', 'codigo': '15_204' },
                { "name": 'COPER', 'codigo': '15_212' },
                { "name": 'CORRALES', 'codigo': '15_215' },
                { "name": 'COVARACHIA', 'codigo': '15_218' },
                { "name": 'CUBARA', 'codigo': '15_223' },
                { "name": 'CUCAITA', 'codigo': '15_224' },
                { "name": 'CUITIVA', 'codigo': '15_226' },
                { "name": 'CHIQUIZA', 'codigo': '15_232' },
                { "name": 'CHIVOR', 'codigo': '15_236' },
                { "name": 'DUITAMA', 'codigo': '15_238' },
                { "name": 'EL COCUY', 'codigo': '15_244' },
                { "name": 'EL ESPINO', 'codigo': '15_248' },
                { "name": 'FIRAVITOBA', 'codigo': '15_272' },
                { "name": 'FLORESTA', 'codigo': '15_276' },
                { "name": 'GACHANTIVA', 'codigo': '15_293' },
                { "name": 'GAMEZA', 'codigo': '15_296' },
                { "name": 'GARAGOA', 'codigo': '15_299' },
                { "name": 'GUACAMAYAS', 'codigo': '15_317' },
                { "name": 'GUATEQUE', 'codigo': '15_322' },
                { "name": 'GUAYATA', 'codigo': '15_325' },
                { "name": 'GsICAN', 'codigo': '15_332' },
                { "name": 'IZA', 'codigo': '15_362' },
                { "name": 'JENESANO', 'codigo': '15_367' },
                { "name": 'JERICO', 'codigo': '15_368' },
                { "name": 'LABRANZAGRANDE', 'codigo': '15_377' },
                { "name": 'LA CAPILLA', 'codigo': '15_380' },
                { "name": 'LA VICTORIA', 'codigo': '15_401' },
                { "name": 'LA UVITA', 'codigo': '15_403' },
                { "name": 'VILLA DE LEYVA', 'codigo': '15_407' },
                { "name": 'MACANAL', 'codigo': '15_425' },
                { "name": 'MARIPI', 'codigo': '15_442' },
                { "name": 'MIRAFLORES', 'codigo': '15_455' },
                { "name": 'MONGUA', 'codigo': '15_464' },
                { "name": 'MONGUI', 'codigo': '15_466' },
                { "name": 'MONIQUIRA', 'codigo': '15_469' },
                { "name": 'MOTAVITA', 'codigo': '15_476' },
                { "name": 'MUZO', 'codigo': '15_480' },
                { "name": 'NOBSA', 'codigo': '15_491' },
                { "name": 'NUEVO COLON', 'codigo': '15_494' },
                { "name": 'OICATA', 'codigo': '15_500' },
                { "name": 'OTANCHE', 'codigo': '15_507' },
                { "name": 'PACHAVITA', 'codigo': '15_511' },
                { "name": 'PAEZ', 'codigo': '15_514' },
                { "name": 'PAIPA', 'codigo': '15_516' },
                { "name": 'PAJARITO', 'codigo': '15_518' },
                { "name": 'PANQUEBA', 'codigo': '15_522' },
                { "name": 'PAUNA', 'codigo': '15_531' },
                { "name": 'PAYA', 'codigo': '15_533' },
                { "name": 'PAZ DE RIO', 'codigo': '15_537' },
                { "name": 'PESCA', 'codigo': '15_542' },
                { "name": 'PISBA', 'codigo': '15_550' },
                { "name": 'PUERTO BOYACA', 'codigo': '15_572' },
                { "name": 'QUIPAMA', 'codigo': '15_580' },
                { "name": 'RAMIRIQUI', 'codigo': '15_599' },
                { "name": 'RAQUIRA', 'codigo': '15_600' },
                { "name": 'RONDON', 'codigo': '15_621' },
                { "name": 'SABOYA', 'codigo': '15_632' },
                { "name": 'SACHICA', 'codigo': '15_638' },
                { "name": 'SAMACA', 'codigo': '15_646' },
                { "name": 'SAN EDUARDO', 'codigo': '15_660' },
                { "name": 'SAN JOSE DE PARE', 'codigo': '15_664' },
                { "name": 'SAN LUIS DE GACENO', 'codigo': '15_667' },
                { "name": 'SAN MATEO', 'codigo': '15_673' },
                { "name": 'SAN MIGUEL DE SEMA', 'codigo': '15_676' },
                { "name": 'SAN PABLO DE BORBUR', 'codigo': '15_681' },
                { "name": 'SANTANA', 'codigo': '15_686' },
                { "name": 'SANTA MARIA', 'codigo': '15_690' },
                { "name": 'SANTA ROSA DE VITERBO', 'codigo': '15_693' },
                { "name": 'SANTA SOFIA', 'codigo': '15_696' },
                { "name": 'SATIVANORTE', 'codigo': '15_720' },
                { "name": 'SATIVASUR', 'codigo': '15_723' },
                { "name": 'SIACHOQUE', 'codigo': '15_740' },
                { "name": 'SOATA', 'codigo': '15_753' },
                { "name": 'SOCOTA', 'codigo': '15_755' },
                { "name": 'SOCHA', 'codigo': '15_757' },
                { "name": 'SOGAMOSO', 'codigo': '15_759' },
                { "name": 'SOMONDOCO', 'codigo': '15_761' },
                { "name": 'SORA', 'codigo': '15_762' },
                { "name": 'SOTAQUIRA', 'codigo': '15_763' },
                { "name": 'SORACA', 'codigo': '15_764' },
                { "name": 'SUSACON', 'codigo': '15_774' },
                { "name": 'SUTAMARCHAN', 'codigo': '15_776' },
                { "name": 'SUTATENZA', 'codigo': '15_778' },
                { "name": 'TASCO', 'codigo': '15_790' },
                { "name": 'TENZA', 'codigo': '15_798' },
                { "name": 'TIBANA', 'codigo': '15_804' },
                { "name": 'TIBASOSA', 'codigo': '15_806' },
                { "name": 'TINJACA', 'codigo': '15_808' },
                { "name": 'TIPACOQUE', 'codigo': '15_810' },
                { "name": 'TOCA', 'codigo': '15_814' },
                { "name": 'TOGsI', 'codigo': '15_816' },
                { "name": 'TOPAGA', 'codigo': '15_820' },
                { "name": 'TOTA', 'codigo': '15_822' },
                { "name": 'TUNUNGUA', 'codigo': '15_832' },
                { "name": 'TURMEQUE', 'codigo': '15_835' },
                { "name": 'TUTA', 'codigo': '15_837' },
                { "name": 'TUTAZA', 'codigo': '15_839' },
                { "name": 'UMBITA', 'codigo': '15_842' },
                { "name": 'VENTAQUEMADA', 'codigo': '15_861' },
                { "name": 'VIRACACHA', 'codigo': '15_879' },
                { "name": 'ZETAQUIRA', 'codigo': '15_897' },
            ],
            "CALDAS": [
                { 'name': 'MANIZALES', 'codigo': '17_001' },
                { 'name': 'AGUADAS', 'codigo': '17_013' },
                { 'name': 'ANSERMA', 'codigo': '17_042' },
                { 'name': 'ARANZAZU', 'codigo': '17_050' },
                { 'name': 'BELALCAZAR', 'codigo': '17_088' },
                { 'name': 'CHINCHINA', 'codigo': '17_174' },
                { 'name': 'FILADELFIA', 'codigo': '17_272' },
                { 'name': 'LA DORADA', 'codigo': '17_380' },
                { 'name': 'LA MERCED', 'codigo': '17_388' },
                { 'name': 'MANZANARES', 'codigo': '17_433' },
                { 'name': 'MARMATO', 'codigo': '17_442' },
                { 'name': 'MARQUETALIA', 'codigo': '17_444' },
                { 'name': 'MARULANDA', 'codigo': '17_446' },
                { 'name': 'NEIRA', 'codigo': '17_486' },
                { 'name': 'NORCASIA', 'codigo': '17_495' },
                { 'name': 'PACORA', 'codigo': '17_513' },
                { 'name': 'PALESTINA', 'codigo': '17_524' },
                { 'name': 'PENSILVANIA', 'codigo': '17_541' },
                { 'name': 'RIOSUCIO', 'codigo': '17_614' },
                { 'name': 'RISARALDA', 'codigo': '17_616' },
                { 'name': 'SALAMINA', 'codigo': '17_653' },
                { 'name': 'SAMANA', 'codigo': '17_662' },
                { 'name': 'SAN JOSE', 'codigo': '17_665' },
                { 'name': 'SUPIA', 'codigo': '17_777' },
                { 'name': 'VICTORIA', 'codigo': '17_867' },
                { 'name': 'VILLAMARIA', 'codigo': '17_873' },
                { 'name': 'VITERBO', 'codigo': '17_877' },
            ],
            "CAQUETA": [
                { 'name': 'FLORENCIA', 'codigo': '18_001' },
                { 'name': 'ALBANIA', 'codigo': '18_029' },
                { 'name': 'BELEN DE LOS ANDAQUIES', 'codigo': '18_094' },
                { 'name': 'CARTAGENA DEL CHAIRA', 'codigo': '18_150' },
                { 'name': 'CURILLO', 'codigo': '18_205' },
                { 'name': 'EL DONCELLO', 'codigo': '18_247' },
                { 'name': 'EL PAUJIL', 'codigo': '18_256' },
                { 'name': 'LA MONTAÑITA', 'codigo': '18_410' },
                { 'name': 'MILAN', 'codigo': '18_460' },
                { 'name': 'MORELIA', 'codigo': '18_479' },
                { 'name': 'PUERTO RICO', 'codigo': '18_592' },
                { 'name': 'SAN JOSE DEL FRAGUA', 'codigo': '18_610' },
                { 'name': 'SAN VICENTE DEL CAGUAN', 'codigo': '18_753' },
                { 'name': 'SOLANO', 'codigo': '18_756' },
                { 'name': 'SOLITA', 'codigo': '18_785' },
                { 'name': 'VALPARAISO', 'codigo': '18_860' },
            ],
            "CAUCA": [
                { 'name': 'POPAYAN', 'codigo': '19_001' },
                { 'name': 'ALMAGUER', 'codigo': '19_022' },
                { 'name': 'ARGELIA', 'codigo': '19_050' },
                { 'name': 'BALBOA', 'codigo': '19_075' },
                { 'name': 'BOLIVAR', 'codigo': '19_100' },
                { 'name': 'BUENOS AIRES', 'codigo': '19_110' },
                { 'name': 'CAJIBIO', 'codigo': '19_130' },
                { 'name': 'CALDONO', 'codigo': '19_137' },
                { 'name': 'CALOTO', 'codigo': '19_142' },
                { 'name': 'CORINTO', 'codigo': '19_212' },
                { 'name': 'EL TAMBO', 'codigo': '19_256' },
                { 'name': 'FLORENCIA', 'codigo': '19_290' },
                { 'name': 'GUACHENE', 'codigo': '19_300' },
                { 'name': 'GUAPI', 'codigo': '19_318' },
                { 'name': 'INZA', 'codigo': '19_355' },
                { 'name': 'JAMBALO', 'codigo': '19_364' },
                { 'name': 'LA SIERRA', 'codigo': '19_392' },
                { 'name': 'LA VEGA', 'codigo': '19_397' },
                { 'name': 'LOPEZ', 'codigo': '19_418' },
                { 'name': 'MERCADERES', 'codigo': '19_450' },
                { 'name': 'MIRANDA', 'codigo': '19_455' },
                { 'name': 'MORALES', 'codigo': '19_473' },
                { 'name': 'PADILLA', 'codigo': '19_513' },
                { 'name': 'PAEZ', 'codigo': '19_517' },
                { 'name': 'PATIA', 'codigo': '19_532' },
                { 'name': 'PIAMONTE', 'codigo': '19_533' },
                { 'name': 'PIENDAMO', 'codigo': '19_548' },
                { 'name': 'PUERTO TEJADA', 'codigo': '19_573' },
                { 'name': 'PURACE', 'codigo': '19_585' },
                { 'name': 'ROSAS', 'codigo': '19_622' },
                { 'name': 'SAN SEBASTIAN', 'codigo': '19_693' },
                { 'name': 'SANTANDER DE QUILICHAO', 'codigo': '19_698' },
                { 'name': 'SANTA ROSA', 'codigo': '19_701' },
                { 'name': 'SILVIA', 'codigo': '19_743' },
                { 'name': 'SOTARA', 'codigo': '19_760' },
                { 'name': 'SUAREZ', 'codigo': '19_780' },
                { 'name': 'SUCRE', 'codigo': '19_785' },
                { 'name': 'TIMBIO', 'codigo': '19_807' },
                { 'name': 'TIMBIQUI', 'codigo': '19_809' },
                { 'name': 'TORIBIO', 'codigo': '19_821' },
                { 'name': 'TOTORO', 'codigo': '19_824' },
                { 'name': 'VILLA RICA', 'codigo': '19_845' },
            ],
            "CESAR": [
                { 'name': 'VALLEDUPAR', 'codigo': '20_001' },
                { 'name': 'AGUACHICA', 'codigo': '20_011' },
                { 'name': 'AGUSTIN CODAZZI', 'codigo': '20_013' },
                { 'name': 'ASTREA', 'codigo': '20_032' },
                { 'name': 'BECERRIL', 'codigo': '20_045' },
                { 'name': 'BOSCONIA', 'codigo': '20_060' },
                { 'name': 'CHIMICHAGUA', 'codigo': '20_175' },
                { 'name': 'CHIRIGUANA', 'codigo': '20_178' },
                { 'name': 'CURUMANI', 'codigo': '20_228' },
                { 'name': 'EL COPEY', 'codigo': '20_238' },
                { 'name': 'EL PASO', 'codigo': '20_250' },
                { 'name': 'GAMARRA', 'codigo': '20_295' },
                { 'name': 'GONZALEZ', 'codigo': '20_310' },
                { 'name': 'LA GLORIA', 'codigo': '20_383' },
                { 'name': 'LA JAGUA DE IBIRICO', 'codigo': '20_400' },
                { 'name': 'MANAURE', 'codigo': '20_443' },
                { 'name': 'PAILITAS', 'codigo': '20_517' },
                { 'name': 'PELAYA', 'codigo': '20_550' },
                { 'name': 'PUEBLO BELLO', 'codigo': '20_570' },
                { 'name': 'RIO DE ORO', 'codigo': '20_614' },
                { 'name': 'LA PAZ', 'codigo': '20_621' },
                { 'name': 'SAN ALBERTO', 'codigo': '20_710' },
                { 'name': 'SAN DIEGO', 'codigo': '20_750' },
                { 'name': 'SAN MARTIN', 'codigo': '20_770' },
                { 'name': 'TAMALAMEQUE', 'codigo': '20_787' },
            ],
            "CORDOBA": [
                { 'name': 'MONTERIA', 'codigo': '23_001' },
                { 'name': 'AYAPEL', 'codigo': '23_068' },
                { 'name': 'BUENAVISTA', 'codigo': '23_079' },
                { 'name': 'CANALETE', 'codigo': '23_090' },
                { 'name': 'CERETE', 'codigo': '23_162' },
                { 'name': 'CHIMA', 'codigo': '23_168' },
                { 'name': 'CHINU', 'codigo': '23_182' },
                { 'name': 'CIENAGA DE ORO', 'codigo': '23_189' },
                { 'name': 'COTORRA', 'codigo': '23_300' },
                { 'name': 'LA APARTADA', 'codigo': '23_350' },
                { 'name': 'LORICA', 'codigo': '23_417' },
                { 'name': 'LOS CORDOBAS', 'codigo': '23_419' },
                { 'name': 'MOMIL', 'codigo': '23_464' },
                { 'name': 'MONTELIBANO', 'codigo': '23_466' },
                { 'name': 'MOÑITOS', 'codigo': '23_500' },
                { 'name': 'PLANETA RICA', 'codigo': '23_555' },
                { 'name': 'PUEBLO NUEVO', 'codigo': '23_570' },
                { 'name': 'PUERTO ESCONDIDO', 'codigo': '23_574' },
                { 'name': 'PUERTO LIBERTADOR', 'codigo': '23_580' },
                { 'name': 'PURISIMA', 'codigo': '23_586' },
                { 'name': 'SAHAGUN', 'codigo': '23_660' },
                { 'name': 'SAN ANDRES SOTAVENTO', 'codigo': '23_670' },
                { 'name': 'SAN ANTERO', 'codigo': '23_672' },
                { 'name': 'SAN BERNARDO DEL VIENTO', 'codigo': '23_675' },
                { 'name': 'SAN CARLOS', 'codigo': '23_678' },
                { 'name': 'SAN PELAYO', 'codigo': '23_686' },
                { 'name': 'TIERRALTA', 'codigo': '23_807' },
                { 'name': 'VALENCIA', 'codigo': '23_855' },
            ],
            "CUNDINAMARCA": [
                { 'name': 'AGUA DE DIOS', 'codigo': '25_001' },
                { 'name': 'ALBAN', 'codigo': '25_019' },
                { 'name': 'ANAPOIMA', 'codigo': '25_035' },
                { 'name': 'ANOLAIMA', 'codigo': '25_040' },
                { 'name': 'ARBELAEZ', 'codigo': '25_053' },
                { 'name': 'BELTRAN', 'codigo': '25_086' },
                { 'name': 'BITUIMA', 'codigo': '25_095' },
                { 'name': 'BOJACA', 'codigo': '25_099' },
                { 'name': 'CABRERA', 'codigo': '25_120' },
                { 'name': 'CACHIPAY', 'codigo': '25_123' },
                { 'name': 'CAJICA', 'codigo': '25_126' },
                { 'name': 'CAPARRAPI', 'codigo': '25_148' },
                { 'name': 'CAQUEZA', 'codigo': '25_151' },
                { 'name': 'CARMEN DE CARUPA', 'codigo': '25_154' },
                { 'name': 'CHAGUANI', 'codigo': '25_168' },
                { 'name': 'CHIA', 'codigo': '25_175' },
                { 'name': 'CHIPAQUE', 'codigo': '25_178' },
                { 'name': 'CHOACHI', 'codigo': '25_181' },
                { 'name': 'CHOCONTA', 'codigo': '25_183' },
                { 'name': 'COGUA', 'codigo': '25_200' },
                { 'name': 'COTA', 'codigo': '25_214' },
                { 'name': 'CUCUNUBA', 'codigo': '25_224' },
                { 'name': 'EL COLEGIO', 'codigo': '25_245' },
                { 'name': 'EL PEÑON', 'codigo': '25_258' },
                { 'name': 'EL ROSAL', 'codigo': '25_260' },
                { 'name': 'FACATATIVA', 'codigo': '25_269' },
                { 'name': 'FOMEQUE', 'codigo': '25_279' },
                { 'name': 'FOSCA', 'codigo': '25_281' },
                { 'name': 'FUNZA', 'codigo': '25_286' },
                { 'name': 'FUQUENE', 'codigo': '25_288' },
                { 'name': 'FUSAGASUGA', 'codigo': '25_290' },
                { 'name': 'GACHALA', 'codigo': '25_293' },
                { 'name': 'GACHANCIPA', 'codigo': '25_295' },
                { 'name': 'GACHETA', 'codigo': '25_297' },
                { 'name': 'GAMA', 'codigo': '25_299' },
                { 'name': 'GIRARDOT', 'codigo': '25_307' },
                { 'name': 'GRANADA', 'codigo': '25_312' },
                { 'name': 'GUACHETA', 'codigo': '25_317' },
                { 'name': 'GUADUAS', 'codigo': '25_320' },
                { 'name': 'GUASCA', 'codigo': '25_322' },
                { 'name': 'GUATAQUI', 'codigo': '25_324' },
                { 'name': 'GUATAVITA', 'codigo': '25_326' },
                { 'name': 'GUAYABAL DE SIQUIMA', 'codigo': '25_328' },
                { 'name': 'GUAYABETAL', 'codigo': '25_335' },
                { 'name': 'GUTIERREZ', 'codigo': '25_339' },
                { 'name': 'JERUSALEN', 'codigo': '25_368' },
                { 'name': 'JUNIN', 'codigo': '25_372' },
                { 'name': 'LA CALERA', 'codigo': '25_377' },
                { 'name': 'LA MESA', 'codigo': '25_386' },
                { 'name': 'LA PALMA', 'codigo': '25_394' },
                { 'name': 'LA PEÑA', 'codigo': '25_398' },
                { 'name': 'LA VEGA', 'codigo': '25_402' },
                { 'name': 'LENGUAZAQUE', 'codigo': '25_407' },
                { 'name': 'MACHETA', 'codigo': '25_426' },
                { 'name': 'MADRID', 'codigo': '25_430' },
                { 'name': 'MANTA', 'codigo': '25_436' },
                { 'name': 'MEDINA', 'codigo': '25_438' },
                { 'name': 'MOSQUERA', 'codigo': '25_473' },
                { 'name': 'NARIÑO', 'codigo': '25_483' },
                { 'name': 'NEMOCON', 'codigo': '25_486' },
                { 'name': 'NILO', 'codigo': '25_488' },
                { 'name': 'NIMAIMA', 'codigo': '25_489' },
                { 'name': 'NOCAIMA', 'codigo': '25_491' },
                { 'name': 'VENECIA', 'codigo': '25_506' },
                { 'name': 'PACHO', 'codigo': '25_513' },
                { 'name': 'PAIME', 'codigo': '25_518' },
                { 'name': 'PANDI', 'codigo': '25_524' },
                { 'name': 'PARATEBUENO', 'codigo': '25_530' },
                { 'name': 'PASCA', 'codigo': '25_535' },
                { 'name': 'PUERTO SALGAR', 'codigo': '25_572' },
                { 'name': 'PULI', 'codigo': '25_580' },
                { 'name': 'QUEBRADANEGRA', 'codigo': '25_592' },
                { 'name': 'QUETAME', 'codigo': '25_594' },
                { 'name': 'QUIPILE', 'codigo': '25_596' },
                { 'name': 'APULO', 'codigo': '25_599' },
                { 'name': 'RICAURTE', 'codigo': '25_612' },
                { 'name': 'SAN ANTONIO DEL TEQUENDAMA', 'codigo': '25_645' },
                { 'name': 'SAN BERNARDO', 'codigo': '25_649' },
                { 'name': 'SAN CAYETANO', 'codigo': '25_653' },
                { 'name': 'SAN FRANCISCO', 'codigo': '25_658' },
                { 'name': 'SAN JUAN DE RIO SECO', 'codigo': '25_662' },
                { 'name': 'SASAIMA', 'codigo': '25_718' },
                { 'name': 'SESQUILE', 'codigo': '25_736' },
                { 'name': 'SIBATE', 'codigo': '25_740' },
                { 'name': 'SILVANIA', 'codigo': '25_743' },
                { 'name': 'SIMIJACA', 'codigo': '25_745' },
                { 'name': 'SOACHA', 'codigo': '25_754' },
                { 'name': 'SOPO', 'codigo': '25_758' },
                { 'name': 'SUBACHOQUE', 'codigo': '25_769' },
                { 'name': 'SUESCA', 'codigo': '25_772' },
                { 'name': 'SUPATA', 'codigo': '25_777' },
                { 'name': 'SUSA', 'codigo': '25_779' },
                { 'name': 'SUTATAUSA', 'codigo': '25_781' },
                { 'name': 'TABIO', 'codigo': '25_785' },
                { 'name': 'TAUSA', 'codigo': '25_793' },
                { 'name': 'TENA', 'codigo': '25_797' },
                { 'name': 'TENJO', 'codigo': '25_799' },
                { 'name': 'TIBACUY', 'codigo': '25_805' },
                { 'name': 'TIBIRITA', 'codigo': '25_807' },
                { 'name': 'TOCAIMA', 'codigo': '25_815' },
                { 'name': 'TOCANCIPA', 'codigo': '25_817' },
                { 'name': 'TOPAIPI', 'codigo': '25_823' },
                { 'name': 'UBALA', 'codigo': '25_839' },
                { 'name': 'UBAQUE', 'codigo': '25_841' },
                { 'name': 'VILLA DE SAN DIEGO DE UBATE', 'codigo': '25_843' },
                { 'name': 'UNE', 'codigo': '25_845' },
                { 'name': 'UTICA', 'codigo': '25_851' },
                { 'name': 'VERGARA', 'codigo': '25_862' },
                { 'name': 'VIANI', 'codigo': '25_867' },
                { 'name': 'VILLAGOMEZ', 'codigo': '25_871' },
                { 'name': 'VILLAPINZON', 'codigo': '25_873' },
                { 'name': 'VILLETA', 'codigo': '25_875' },
                { 'name': 'VIOTA', 'codigo': '25_878' },
                { 'name': 'YACOPI', 'codigo': '25_885' },
                { 'name': 'ZIPACON', 'codigo': '25_898' },
                { 'name': 'ZIPAQUIRA', 'codigo': '25_899' },
            ],
            "HUILA": [
                { 'name': 'NEIVA', 'codigo': '41_001' },
                { 'name': 'ACEVEDO', 'codigo': '41_006' },
                { 'name': 'AGRADO', 'codigo': '41_013' },
                { 'name': 'AIPE', 'codigo': '41_016' },
                { 'name': 'ALGECIRAS', 'codigo': '41_020' },
                { 'name': 'ALTAMIRA', 'codigo': '41_026' },
                { 'name': 'BARAYA', 'codigo': '41_078' },
                { 'name': 'CAMPOALEGRE', 'codigo': '41_132' },
                { 'name': 'COLOMBIA', 'codigo': '41_206' },
                { 'name': 'ELIAS', 'codigo': '41_244' },
                { 'name': 'GARZON', 'codigo': '41_298' },
                { 'name': 'GIGANTE', 'codigo': '41_306' },
                { 'name': 'GUADALUPE', 'codigo': '41_319' },
                { 'name': 'HOBO', 'codigo': '41_349' },
                { 'name': 'IQUIRA', 'codigo': '41_357' },
                { 'name': 'ISNOS', 'codigo': '41_359' },
                { 'name': 'LA ARGENTINA', 'codigo': '41_378' },
                { 'name': 'LA PLATA', 'codigo': '41_396' },
                { 'name': 'NATAGA', 'codigo': '41_483' },
                { 'name': 'OPORAPA', 'codigo': '41_503' },
                { 'name': 'PAICOL', 'codigo': '41_518' },
                { 'name': 'PALERMO', 'codigo': '41_524' },
                { 'name': 'PALESTINA', 'codigo': '41_530' },
                { 'name': 'PITAL', 'codigo': '41_548' },
                { 'name': 'PITALITO', 'codigo': '41_551' },
                { 'name': 'RIVERA', 'codigo': '41_615' },
                { 'name': 'SALADOBLANCO', 'codigo': '41_660' },
                { 'name': 'SAN AGUSTIN', 'codigo': '41_668' },
                { 'name': 'SANTA MARIA', 'codigo': '41_676' },
                { 'name': 'SUAZA', 'codigo': '41_770' },
                { 'name': 'TARQUI', 'codigo': '41_791' },
                { 'name': 'TESALIA', 'codigo': '41_797' },
                { 'name': 'TELLO', 'codigo': '41_799' },
                { 'name': 'TERUEL', 'codigo': '41_801' },
                { 'name': 'TIMANA', 'codigo': '41_807' },
                { 'name': 'VILLAVIEJA', 'codigo': '41_872' },
                { 'name': 'YAGUARA', 'codigo': '41_885' },
            ],
            "LA GUAJIRA": [
                { 'name': 'RIOHACHA', 'codigo': '44_001' },
                { 'name': 'ALBANIA', 'codigo': '44_035' },
                { 'name': 'BARRANCAS', 'codigo': '44_078' },
                { 'name': 'DIBULLA', 'codigo': '44_090' },
                { 'name': 'DISTRACCION', 'codigo': '44_098' },
                { 'name': 'EL MOLINO', 'codigo': '44_110' },
                { 'name': 'FONSECA', 'codigo': '44_279' },
                { 'name': 'HATONUEVO', 'codigo': '44_378' },
                { 'name': 'LA JAGUA DEL PILAR', 'codigo': '44_420' },
                { 'name': 'MAICAO', 'codigo': '44_430' },
                { 'name': 'MANAURE', 'codigo': '44_560' },
                { 'name': 'SAN JUAN DEL CESAR', 'codigo': '44_650' },
                { 'name': 'URIBIA', 'codigo': '44_847' },
                { 'name': 'URUMITA', 'codigo': '44_855' },
                { 'name': 'VILLANUEVA', 'codigo': '44_874' },
            ],
            "MAGDALENA": [
                { 'name': 'SANTA MARTA', 'codigo': '47_001' },
                { 'name': 'ALGARROBO', 'codigo': '47_030' },
                { 'name': 'ARACATACA', 'codigo': '47_053' },
                { 'name': 'ARIGUANI', 'codigo': '47_058' },
                { 'name': 'CERRO SAN ANTONIO', 'codigo': '47_161' },
                { 'name': 'CHIBOLO', 'codigo': '47_170' },
                { 'name': 'CIENAGA', 'codigo': '47_189' },
                { 'name': 'CONCORDIA', 'codigo': '47_205' },
                { 'name': 'EL BANCO', 'codigo': '47_245' },
                { 'name': 'EL PIÑON', 'codigo': '47_258' },
                { 'name': 'EL RETEN', 'codigo': '47_268' },
                { 'name': 'FUNDACION', 'codigo': '47_288' },
                { 'name': 'GUAMAL', 'codigo': '47_318' },
                { 'name': 'NUEVA GRANADA', 'codigo': '47_460' },
                { 'name': 'PEDRAZA', 'codigo': '47_541' },
                { 'name': 'PIJIÑO DEL CARMEN', 'codigo': '47_545' },
                { 'name': 'PIVIJAY', 'codigo': '47_551' },
                { 'name': 'PLATO', 'codigo': '47_555' },
                { 'name': 'PUEBLOVIEJO', 'codigo': '47_570' },
                { 'name': 'REMOLINO', 'codigo': '47_605' },
                { 'name': 'SABANAS DE SAN ANGEL', 'codigo': '47_660' },
                { 'name': 'SALAMINA', 'codigo': '47_675' },
                { 'name': 'SAN SEBASTIAN DE BUENAVISTA', 'codigo': '47_692' },
                { 'name': 'SAN ZENON', 'codigo': '47_703' },
                { 'name': 'SANTA ANA', 'codigo': '47_707' },
                { 'name': 'SANTA BARBARA DE PINTO', 'codigo': '47_720' },
                { 'name': 'SITIONUEVO', 'codigo': '47_745' },
                { 'name': 'TENERIFE', 'codigo': '47_798' },
                { 'name': 'ZAPAYAN', 'codigo': '47_960' },
                { 'name': 'ZONA BANANERA', 'codigo': '47_980' },
            ],
            "META": [
                { 'name': 'VILLAVICENCIO', 'codigo': '50_001' },
                { 'name': 'ACACIAS', 'codigo': '50_006' },
                { 'name': 'BARRANCA DE UPIA', 'codigo': '50_110' },
                { 'name': 'CABUYARO', 'codigo': '50_124' },
                { 'name': 'CASTILLA LA NUEVA', 'codigo': '50_150' },
                { 'name': 'CUBARRAL', 'codigo': '50_223' },
                { 'name': 'CUMARAL', 'codigo': '50_226' },
                { 'name': 'EL CALVARIO', 'codigo': '50_245' },
                { 'name': 'EL CASTILLO', 'codigo': '50_251' },
                { 'name': 'EL DORADO', 'codigo': '50_270' },
                { 'name': 'FUENTE DE ORO', 'codigo': '50_287' },
                { 'name': 'GRANADA', 'codigo': '50_313' },
                { 'name': 'GUAMAL', 'codigo': '50_318' },
                { 'name': 'MAPIRIPAN', 'codigo': '50_325' },
                { 'name': 'MESETAS', 'codigo': '50_330' },
                { 'name': 'LA MACARENA', 'codigo': '50_350' },
                { 'name': 'URIBE', 'codigo': '50_370' },
                { 'name': 'LEJANIAS', 'codigo': '50_400' },
                { 'name': 'PUERTO CONCORDIA', 'codigo': '50_450' },
                { 'name': 'PUERTO GAITAN', 'codigo': '50_568' },
                { 'name': 'PUERTO LOPEZ', 'codigo': '50_573' },
                { 'name': 'PUERTO LLERAS', 'codigo': '50_577' },
                { 'name': 'PUERTO RICO', 'codigo': '50_590' },
                { 'name': 'RESTREPO', 'codigo': '50_606' },
                { 'name': 'SAN CARLOS DE GUAROA', 'codigo': '50_680' },
                { 'name': 'SAN JUAN DE ARAMA', 'codigo': '50_683' },
                { 'name': 'SAN JUANITO', 'codigo': '50_686' },
                { 'name': 'SAN MARTIN', 'codigo': '50_689' },
                { 'name': 'VISTAHERMOSA', 'codigo': '50_711' },
            ],
            "NARIÑO": [
                { 'name': 'PASTO', 'codigo': '52_001' },
                { 'name': 'ALBAN', 'codigo': '52_019' },
                { 'name': 'ALDANA', 'codigo': '52_022' },
                { 'name': 'ANCUYA', 'codigo': '52_036' },
                { 'name': 'ARBOLEDA', 'codigo': '52_051' },
                { 'name': 'BARBACOAS', 'codigo': '52_079' },
                { 'name': 'BELEN', 'codigo': '52_083' },
                { 'name': 'BUESACO', 'codigo': '52_110' },
                { 'name': 'COLON', 'codigo': '52_203' },
                { 'name': 'CONSACA', 'codigo': '52_207' },
                { 'name': 'CONTADERO', 'codigo': '52_210' },
                { 'name': 'CORDOBA', 'codigo': '52_215' },
                { 'name': 'CUASPUD', 'codigo': '52_224' },
                { 'name': 'CUMBAL', 'codigo': '52_227' },
                { 'name': 'CUMBITARA', 'codigo': '52_233' },
                { 'name': 'CHACHAGsI', 'codigo': '52_240' },
                { 'name': 'EL CHARCO', 'codigo': '52_250' },
                { 'name': 'EL PEÑOL', 'codigo': '52_254' },
                { 'name': 'EL ROSARIO', 'codigo': '52_256' },
                { 'name': 'EL TABLON DE GOMEZ', 'codigo': '52_258' },
                { 'name': 'EL TAMBO', 'codigo': '52_260' },
                { 'name': 'FUNES', 'codigo': '52_287' },
                { 'name': 'GUACHUCAL', 'codigo': '52_317' },
                { 'name': 'GUAITARILLA', 'codigo': '52_320' },
                { 'name': 'GUALMATAN', 'codigo': '52_323' },
                { 'name': 'ILES', 'codigo': '52_352' },
                { 'name': 'IMUES', 'codigo': '52_354' },
                { 'name': 'IPIALES', 'codigo': '52_356' },
                { 'name': 'LA CRUZ', 'codigo': '52_378' },
                { 'name': 'LA FLORIDA', 'codigo': '52_381' },
                { 'name': 'LA LLANADA', 'codigo': '52_385' },
                { 'name': 'LA TOLA', 'codigo': '52_390' },
                { 'name': 'LA UNION', 'codigo': '52_399' },
                { 'name': 'LEIVA', 'codigo': '52_405' },
                { 'name': 'LINARES', 'codigo': '52_411' },
                { 'name': 'LOS ANDES', 'codigo': '52_418' },
                { 'name': 'MAGsI', 'codigo': '52_427' },
                { 'name': 'MALLAMA', 'codigo': '52_435' },
                { 'name': 'MOSQUERA', 'codigo': '52_473' },
                { 'name': 'NARIÑO', 'codigo': '52_480' },
                { 'name': 'OLAYA HERRERA', 'codigo': '52_490' },
                { 'name': 'OSPINA', 'codigo': '52_506' },
                { 'name': 'FRANCISCO PIZARRO', 'codigo': '52_520' },
                { 'name': 'POLICARPA', 'codigo': '52_540' },
                { 'name': 'POTOSI', 'codigo': '52_560' },
                { 'name': 'PROVIDENCIA', 'codigo': '52_565' },
                { 'name': 'PUERRES', 'codigo': '52_573' },
                { 'name': 'PUPIALES', 'codigo': '52_585' },
                { 'name': 'RICAURTE', 'codigo': '52_612' },
                { 'name': 'ROBERTO PAYAN', 'codigo': '52_621' },
                { 'name': 'SAMANIEGO', 'codigo': '52_678' },
                { 'name': 'SANDONA', 'codigo': '52_683' },
                { 'name': 'SAN BERNARDO', 'codigo': '52_685' },
                { 'name': 'SAN LORENZO', 'codigo': '52_687' },
                { 'name': 'SAN PABLO', 'codigo': '52_693' },
                { 'name': 'SAN PEDRO DE CARTAGO', 'codigo': '52_694' },
                { 'name': 'SANTA BARBARA', 'codigo': '52_696' },
                { 'name': 'SANTACRUZ', 'codigo': '52_699' },
                { 'name': 'SAPUYES', 'codigo': '52_720' },
                { 'name': 'TAMINANGO', 'codigo': '52_786' },
                { 'name': 'TANGUA', 'codigo': '52_788' },
                { 'name': 'SAN ANDRES DE TUMACO', 'codigo': '52_835' },
                { 'name': 'TUQUERRES', 'codigo': '52_838' },
                { 'name': 'YACUANQUER', 'codigo': '52_885' },
            ],
            "N. DE SANTANDER": [
                { 'name': 'CUCUTA', 'codigo': '54_001' },
                { 'name': 'ABREGO', 'codigo': '54_003' },
                { 'name': 'ARBOLEDAS', 'codigo': '54_051' },
                { 'name': 'BOCHALEMA', 'codigo': '54_099' },
                { 'name': 'BUCARASICA', 'codigo': '54_109' },
                { 'name': 'CACOTA', 'codigo': '54_125' },
                { 'name': 'CACHIRA', 'codigo': '54_128' },
                { 'name': 'CHINACOTA', 'codigo': '54_172' },
                { 'name': 'CHITAGA', 'codigo': '54_174' },
                { 'name': 'CONVENCION', 'codigo': '54_206' },
                { 'name': 'CUCUTILLA', 'codigo': '54_223' },
                { 'name': 'DURANIA', 'codigo': '54_239' },
                { 'name': 'EL CARMEN', 'codigo': '54_245' },
                { 'name': 'EL TARRA', 'codigo': '54_250' },
                { 'name': 'EL ZULIA', 'codigo': '54_261' },
                { 'name': 'GRAMALOTE', 'codigo': '54_313' },
                { 'name': 'HACARI', 'codigo': '54_344' },
                { 'name': 'HERRAN', 'codigo': '54_347' },
                { 'name': 'LABATECA', 'codigo': '54_377' },
                { 'name': 'LA ESPERANZA', 'codigo': '54_385' },
                { 'name': 'LA PLAYA', 'codigo': '54_398' },
                { 'name': 'LOS PATIOS', 'codigo': '54_405' },
                { 'name': 'LOURDES', 'codigo': '54_418' },
                { 'name': 'MUTISCUA', 'codigo': '54_480' },
                { 'name': 'OCAÑA', 'codigo': '54_498' },
                { 'name': 'PAMPLONA', 'codigo': '54_518' },
                { 'name': 'PAMPLONITA', 'codigo': '54_520' },
                { 'name': 'PUERTO SANTANDER', 'codigo': '54_553' },
                { 'name': 'RAGONVALIA', 'codigo': '54_599' },
                { 'name': 'SALAZAR', 'codigo': '54_660' },
                { 'name': 'SAN CALIXTO', 'codigo': '54_670' },
                { 'name': 'SAN CAYETANO', 'codigo': '54_673' },
                { 'name': 'SANTIAGO', 'codigo': '54_680' },
                { 'name': 'SARDINATA', 'codigo': '54_720' },
                { 'name': 'SILOS', 'codigo': '54_743' },
                { 'name': 'TEORAMA', 'codigo': '54_800' },
                { 'name': 'TIBU', 'codigo': '54_810' },
                { 'name': 'TOLEDO', 'codigo': '54_820' },
                { 'name': 'VILLA CARO', 'codigo': '54_871' },
                { 'name': 'VILLA DEL ROSARIO', 'codigo': '54_874' },
            ],
            "QUINDIO": [
                { 'name': 'ARMENIA', 'codigo': '63_001' },
                { 'name': 'BUENAVISTA', 'codigo': '63_111' },
                { 'name': 'CALARCA', 'codigo': '63_130' },
                { 'name': 'CIRCASIA', 'codigo': '63_190' },
                { 'name': 'CORDOBA', 'codigo': '63_212' },
                { 'name': 'FILANDIA', 'codigo': '63_272' },
                { 'name': 'GENOVA', 'codigo': '63_302' },
                { 'name': 'LA TEBAIDA', 'codigo': '63_401' },
                { 'name': 'MONTENEGRO', 'codigo': '63_470' },
                { 'name': 'PIJAO', 'codigo': '63_548' },
                { 'name': 'QUIMBAYA', 'codigo': '63_594' },
                { 'name': 'SALENTO', 'codigo': '63_690' },

            ],
            "RISARALDA": [
                { 'name': 'PEREIRA', 'codigo': '66_001' },
                { 'name': 'APIA', 'codigo': '66_045' },
                { 'name': 'BALBOA', 'codigo': '66_075' },
                { 'name': 'BELEN DE UMBRIA', 'codigo': '66_088' },
                { 'name': 'DOSQUEBRADAS', 'codigo': '66_170' },
                { 'name': 'GUATICA', 'codigo': '66_318' },
                { 'name': 'LA CELIA', 'codigo': '66_383' },
                { 'name': 'LA VIRGINIA', 'codigo': '66_400' },
                { 'name': 'MARSELLA', 'codigo': '66_440' },
                { 'name': 'MISTRATO', 'codigo': '66_456' },
                { 'name': 'PUEBLO RICO', 'codigo': '66_572' },
                { 'name': 'QUINCHIA', 'codigo': '66_594' },
                { 'name': 'SANTA ROSA DE CABAL', 'codigo': '66_682' },
                { 'name': 'SANTUARIO', 'codigo': '66_687' },
            ],
            "SANTANDER": [
                { 'name': 'BUCARAMANGA', 'codigo': '68_001' },
                { 'name': 'AGUADA', 'codigo': '68_013' },
                { 'name': 'ALBANIA', 'codigo': '68_020' },
                { 'name': 'ARATOCA', 'codigo': '68_051' },
                { 'name': 'BARBOSA', 'codigo': '68_077' },
                { 'name': 'BARICHARA', 'codigo': '68_079' },
                { 'name': 'BARRANCABERMEJA', 'codigo': '68_081' },
                { 'name': 'BETULIA', 'codigo': '68_092' },
                { 'name': 'BOLIVAR', 'codigo': '68_101' },
                { 'name': 'CABRERA', 'codigo': '68_121' },
                { 'name': 'CALIFORNIA', 'codigo': '68_132' },
                { 'name': 'CAPITANEJO', 'codigo': '68_147' },
                { 'name': 'CARCASI', 'codigo': '68_152' },
                { 'name': 'CEPITA', 'codigo': '68_160' },
                { 'name': 'CERRITO', 'codigo': '68_162' },
                { 'name': 'CHARALA', 'codigo': '68_167' },
                { 'name': 'CHARTA', 'codigo': '68_169' },
                { 'name': 'CHIMA', 'codigo': '68_176' },
                { 'name': 'CHIPATA', 'codigo': '68_179' },
                { 'name': 'CIMITARRA', 'codigo': '68_190' },
                { 'name': 'CONCEPCION', 'codigo': '68_207' },
                { 'name': 'CONFINES', 'codigo': '68_209' },
                { 'name': 'CONTRATACION', 'codigo': '68_211' },
                { 'name': 'COROMORO', 'codigo': '68_217' },
                { 'name': 'CURITI', 'codigo': '68_229' },
                { 'name': 'EL CARMEN DE CHUCURI', 'codigo': '68_235' },
                { 'name': 'EL GUACAMAYO', 'codigo': '68_245' },
                { 'name': 'EL PEÑON', 'codigo': '68_250' },
                { 'name': 'EL PLAYON', 'codigo': '68_255' },
                { 'name': 'ENCINO', 'codigo': '68_264' },
                { 'name': 'ENCISO', 'codigo': '68_266' },
                { 'name': 'FLORIAN', 'codigo': '68_271' },
                { 'name': 'FLORIDABLANCA', 'codigo': '68_276' },
                { 'name': 'GALAN', 'codigo': '68_296' },
                { 'name': 'GAMBITA', 'codigo': '68_298' },
                { 'name': 'GIRON', 'codigo': '68_307' },
                { 'name': 'GUACA', 'codigo': '68_318' },
                { 'name': 'GUADALUPE', 'codigo': '68_320' },
                { 'name': 'GUAPOTA', 'codigo': '68_322' },
                { 'name': 'GUAVATA', 'codigo': '68_324' },
                { 'name': 'GsEPSA', 'codigo': '68_327' },
                { 'name': 'HATO', 'codigo': '68_344' },
                { 'name': 'JESUS MARIA', 'codigo': '68_368' },
                { 'name': 'JORDAN', 'codigo': '68_370' },
                { 'name': 'LA BELLEZA', 'codigo': '68_377' },
                { 'name': 'LANDAZURI', 'codigo': '68_385' },
                { 'name': 'LA PAZ', 'codigo': '68_397' },
                { 'name': 'LEBRIJA', 'codigo': '68_406' },
                { 'name': 'LOS SANTOS', 'codigo': '68_418' },
                { 'name': 'MACARAVITA', 'codigo': '68_425' },
                { 'name': 'MALAGA', 'codigo': '68_432' },
                { 'name': 'MATANZA', 'codigo': '68_444' },
                { 'name': 'MOGOTES', 'codigo': '68_464' },
                { 'name': 'MOLAGAVITA', 'codigo': '68_468' },
                { 'name': 'OCAMONTE', 'codigo': '68_498' },
                { 'name': 'OIBA', 'codigo': '68_500' },
                { 'name': 'ONZAGA', 'codigo': '68_502' },
                { 'name': 'PALMAR', 'codigo': '68_522' },
                { 'name': 'PALMAS DEL SOCORRO', 'codigo': '68_524' },
                { 'name': 'PARAMO', 'codigo': '68_533' },
                { 'name': 'PIEDECUESTA', 'codigo': '68_547' },
                { 'name': 'PINCHOTE', 'codigo': '68_549' },
                { 'name': 'PUENTE NACIONAL', 'codigo': '68_572' },
                { 'name': 'PUERTO PARRA', 'codigo': '68_573' },
                { 'name': 'PUERTO WILCHES', 'codigo': '68_575' },
                { 'name': 'RIONEGRO', 'codigo': '68_615' },
                { 'name': 'SABANA DE TORRES', 'codigo': '68_655' },
                { 'name': 'SAN ANDRES', 'codigo': '68_669' },
                { 'name': 'SAN BENITO', 'codigo': '68_673' },
                { 'name': 'SAN GIL', 'codigo': '68_679' },
                { 'name': 'SAN JOAQUIN', 'codigo': '68_682' },
                { 'name': 'SAN JOSE DE MIRANDA', 'codigo': '68_684' },
                { 'name': 'SAN MIGUEL', 'codigo': '68_686' },
                { 'name': 'SAN VICENTE DE CHUCURI', 'codigo': '68_689' },
                { 'name': 'SANTA BARBARA', 'codigo': '68_705' },
                { 'name': 'SANTA HELENA DEL OPON', 'codigo': '68_720' },
                { 'name': 'SIMACOTA', 'codigo': '68_745' },
                { 'name': 'SOCORRO', 'codigo': '68_755' },
                { 'name': 'SUAITA', 'codigo': '68_770' },
                { 'name': 'SUCRE', 'codigo': '68_773' },
                { 'name': 'SURATA', 'codigo': '68_780' },
                { 'name': 'TONA', 'codigo': '68_820' },
                { 'name': 'VALLE DE SAN JOSE', 'codigo': '68_855' },
                { 'name': 'VELEZ', 'codigo': '68_861' },
                { 'name': 'VETAS', 'codigo': '68_867' },
                { 'name': 'VILLANUEVA', 'codigo': '68_872' },
                { 'name': 'ZAPATOCA', 'codigo': '68_895' },
            ],
            "SUCRE": [
                { 'name': 'SINCELEJO', 'codigo': '70_001' },
                { 'name': 'BUENAVISTA', 'codigo': '70_110' },
                { 'name': 'CAIMITO', 'codigo': '70_124' },
                { 'name': 'COLOSO', 'codigo': '70_204' },
                { 'name': 'COROZAL', 'codigo': '70_215' },
                { 'name': 'COVEÑAS', 'codigo': '70_221' },
                { 'name': 'CHALAN', 'codigo': '70_230' },
                { 'name': 'EL ROBLE', 'codigo': '70_233' },
                { 'name': 'GALERAS', 'codigo': '70_235' },
                { 'name': 'GUARANDA', 'codigo': '70_265' },
                { 'name': 'LA UNION', 'codigo': '70_400' },
                { 'name': 'LOS PALMITOS', 'codigo': '70_418' },
                { 'name': 'MAJAGUAL', 'codigo': '70_429' },
                { 'name': 'MORROA', 'codigo': '70_473' },
                { 'name': 'OVEJAS', 'codigo': '70_508' },
                { 'name': 'PALMITO', 'codigo': '70_523' },
                { 'name': 'SAMPUES', 'codigo': '70_670' },
                { 'name': 'SAN BENITO ABAD', 'codigo': '70_678' },
                { 'name': 'SAN JUAN DE BETULIA', 'codigo': '70_702' },
                { 'name': 'SAN MARCOS', 'codigo': '70_708' },
                { 'name': 'SAN ONOFRE', 'codigo': '70_713' },
                { 'name': 'SAN PEDRO', 'codigo': '70_717' },
                { 'name': 'SAN LUIS DE SINCE', 'codigo': '70_742' },
                { 'name': 'SUCRE', 'codigo': '70_771' },
                { 'name': 'SANTIAGO DE TOLU', 'codigo': '70_820' },
                { 'name': 'TOLU VIEJO', 'codigo': '70_823' },
            ],
            "TOLIMA": [
                { 'name': 'IBAGUE', 'codigo': '73_001' },
                { 'name': 'ALPUJARRA', 'codigo': '73_024' },
                { 'name': 'ALVARADO', 'codigo': '73_026' },
                { 'name': 'AMBALEMA', 'codigo': '73_030' },
                { 'name': 'ANZOATEGUI', 'codigo': '73_043' },
                { 'name': 'ARMERO', 'codigo': '73_055' },
                { 'name': 'ATACO', 'codigo': '73_067' },
                { 'name': 'CAJAMARCA', 'codigo': '73_124' },
                { 'name': 'CARMEN DE APICALA', 'codigo': '73_148' },
                { 'name': 'CASABIANCA', 'codigo': '73_152' },
                { 'name': 'CHAPARRAL', 'codigo': '73_168' },
                { 'name': 'COELLO', 'codigo': '73_200' },
                { 'name': 'COYAIMA', 'codigo': '73_217' },
                { 'name': 'CUNDAY', 'codigo': '73_226' },
                { 'name': 'DOLORES', 'codigo': '73_236' },
                { 'name': 'ESPINAL', 'codigo': '73_268' },
                { 'name': 'FALAN', 'codigo': '73_270' },
                { 'name': 'FLANDES', 'codigo': '73_275' },
                { 'name': 'FRESNO', 'codigo': '73_283' },
                { 'name': 'GUAMO', 'codigo': '73_319' },
                { 'name': 'HERVEO', 'codigo': '73_347' },
                { 'name': 'HONDA', 'codigo': '73_349' },
                { 'name': 'ICONONZO', 'codigo': '73_352' },
                { 'name': 'LERIDA', 'codigo': '73_408' },
                { 'name': 'LIBANO', 'codigo': '73_411' },
                { 'name': 'MARIQUITA', 'codigo': '73_443' },
                { 'name': 'MELGAR', 'codigo': '73_449' },
                { 'name': 'MURILLO', 'codigo': '73_461' },
                { 'name': 'NATAGAIMA', 'codigo': '73_483' },
                { 'name': 'ORTEGA', 'codigo': '73_504' },
                { 'name': 'PALOCABILDO', 'codigo': '73_520' },
                { 'name': 'PIEDRAS', 'codigo': '73_547' },
                { 'name': 'PLANADAS', 'codigo': '73_555' },
                { 'name': 'PRADO', 'codigo': '73_563' },
                { 'name': 'PURIFICACION', 'codigo': '73_585' },
                { 'name': 'RIOBLANCO', 'codigo': '73_616' },
                { 'name': 'RONCESVALLES', 'codigo': '73_622' },
                { 'name': 'ROVIRA', 'codigo': '73_624' },
                { 'name': 'SALDAÑA', 'codigo': '73_671' },
                { 'name': 'SAN ANTONIO', 'codigo': '73_675' },
                { 'name': 'SAN LUIS', 'codigo': '73_678' },
                { 'name': 'SANTA ISABEL', 'codigo': '73_686' },
                { 'name': 'SUAREZ', 'codigo': '73_770' },
                { 'name': 'VALLE DE SAN JUAN', 'codigo': '73_854' },
                { 'name': 'VENADILLO', 'codigo': '73_861' },
                { 'name': 'VILLAHERMOSA', 'codigo': '73_870' },
                { 'name': 'VILLARRICA', 'codigo': '73_873' },
            ],
            "VALLE DEL CAUCA": [
                { 'name': 'CALI', 'codigo': '76_001' },
                { 'name': 'ALCALA', 'codigo': '76_020' },
                { 'name': 'ANDALUCIA', 'codigo': '76_036' },
                { 'name': 'ANSERMANUEVO', 'codigo': '76_041' },
                { 'name': 'ARGELIA', 'codigo': '76_054' },
                { 'name': 'BOLIVAR', 'codigo': '76_100' },
                { 'name': 'BUENAVENTURA', 'codigo': '76_109' },
                { 'name': 'GUADALAJARA DE BUGA', 'codigo': '76_111' },
                { 'name': 'BUGALAGRANDE', 'codigo': '76_113' },
                { 'name': 'CAICEDONIA', 'codigo': '76_122' },
                { 'name': 'CALIMA', 'codigo': '76_126' },
                { 'name': 'CANDELARIA', 'codigo': '76_130' },
                { 'name': 'CARTAGO', 'codigo': '76_147' },
                { 'name': 'DAGUA', 'codigo': '76_233' },
                { 'name': 'EL AGUILA', 'codigo': '76_243' },
                { 'name': 'EL CAIRO', 'codigo': '76_246' },
                { 'name': 'EL CERRITO', 'codigo': '76_248' },
                { 'name': 'EL DOVIO', 'codigo': '76_250' },
                { 'name': 'FLORIDA', 'codigo': '76_275' },
                { 'name': 'GINEBRA', 'codigo': '76_306' },
                { 'name': 'GUACARI', 'codigo': '76_318' },
                { 'name': 'JAMUNDI', 'codigo': '76_364' },
                { 'name': 'LA CUMBRE', 'codigo': '76_377' },
                { 'name': 'LA UNION', 'codigo': '76_400' },
                { 'name': 'LA VICTORIA', 'codigo': '76_403' },
                { 'name': 'OBANDO', 'codigo': '76_497' },
                { 'name': 'PALMIRA', 'codigo': '76_520' },
                { 'name': 'PRADERA', 'codigo': '76_563' },
                { 'name': 'RESTREPO', 'codigo': '76_606' },
                { 'name': 'RIOFRIO', 'codigo': '76_616' },
                { 'name': 'ROLDANILLO', 'codigo': '76_622' },
                { 'name': 'SAN PEDRO', 'codigo': '76_670' },
                { 'name': 'SEVILLA', 'codigo': '76_736' },
                { 'name': 'TORO', 'codigo': '76_823' },
                { 'name': 'TRUJILLO', 'codigo': '76_828' },
                { 'name': 'TULUA', 'codigo': '76_834' },
                { 'name': 'ULLOA', 'codigo': '76_845' },
                { 'name': 'VERSALLES', 'codigo': '76_863' },
                { 'name': 'VIJES', 'codigo': '76_869' },
                { 'name': 'YOTOCO', 'codigo': '76_890' },
                { 'name': 'YUMBO', 'codigo': '76_892' },
                { 'name': 'ZARZAL', 'codigo': '76_895' },
            ],
            "ARAUCA": [
                { 'name': 'ARAUCA', 'codigo': '81_001' },
                { 'name': 'ARAUQUITA', 'codigo': '81_065' },
                { 'name': 'CRAVO NORTE', 'codigo': '81_220' },
                { 'name': 'FORTUL', 'codigo': '81_300' },
                { 'name': 'PUERTO RONDON', 'codigo': '81_591' },
                { 'name': 'SARAVENA', 'codigo': '81_736' },
                { 'name': 'TAME', 'codigo': '81_794' },
            ],
            "CASANARE": [
                { 'name': 'YOPAL', 'codigo': '85_001' },
                { 'name': 'AGUAZUL', 'codigo': '85_010' },
                { 'name': 'CHAMEZA', 'codigo': '85_015' },
                { 'name': 'HATO COROZAL', 'codigo': '85_125' },
                { 'name': 'LA SALINA', 'codigo': '85_136' },
                { 'name': 'MANI', 'codigo': '85_139' },
                { 'name': 'MONTERREY', 'codigo': '85_162' },
                { 'name': 'NUNCHIA', 'codigo': '85_225' },
                { 'name': 'OROCUE', 'codigo': '85_230' },
                { 'name': 'PAZ DE ARIPORO', 'codigo': '85_250' },
                { 'name': 'PORE', 'codigo': '85_263' },
                { 'name': 'RECETOR', 'codigo': '85_279' },
                { 'name': 'SABANALARGA', 'codigo': '85_300' },
                { 'name': 'SACAMA', 'codigo': '85_315' },
                { 'name': 'SAN LUIS DE PALENQUE', 'codigo': '85_325' },
                { 'name': 'TAMARA', 'codigo': '85_400' },
                { 'name': 'TAURAMENA', 'codigo': '85_410' },
                { 'name': 'TRINIDAD', 'codigo': '85_430' },
                { 'name': 'VILLANUEVA', 'codigo': '85_440' },
            ],
            "PUTUMAYO": [
                { 'name': 'MOCOA', 'codigo': '86_001' },
                { 'name': 'COLON', 'codigo': '86_219' },
                { 'name': 'ORITO', 'codigo': '86_320' },
                { 'name': 'PUERTO ASIS', 'codigo': '86_568' },
                { 'name': 'PUERTO CAICEDO', 'codigo': '86_569' },
                { 'name': 'PUERTO GUZMAN', 'codigo': '86_571' },
                { 'name': 'LEGUIZAMO', 'codigo': '86_573' },
                { 'name': 'SIBUNDOY', 'codigo': '86_749' },
                { 'name': 'SAN FRANCISCO', 'codigo': '86_755' },
                { 'name': 'SAN MIGUEL', 'codigo': '86_757' },
                { 'name': 'SANTIAGO', 'codigo': '86_760' },
                { 'name': 'VALLE DEL GUAMUEZ', 'codigo': '86_865' },
                { 'name': 'VILLAGARZON', 'codigo': '86_885' },
            ],
            "SAN ANDRES": [
                { 'name': "SAN ANDRES", 'codigo': '88_001' },
                { 'name': "PROVIDENCIA", 'codigo': '88_564' }
            ],
            "AMAZONAS": [
                { 'name': 'LETICIA', 'codigo': '91_001' },
                { 'name': 'EL ENCANTO', 'codigo': '91_263' },
                { 'name': 'LA CHORRERA', 'codigo': '91_405' },
                { 'name': 'LA PEDRERA', 'codigo': '91_407' },
                { 'name': 'LA VICTORIA', 'codigo': '91_430' },
                { 'name': 'MIRITI - PARANA', 'codigo': '91_460' },
                { 'name': 'PUERTO ALEGRIA', 'codigo': '91_530' },
                { 'name': 'PUERTO ARICA', 'codigo': '91_536' },
                { 'name': 'PUERTO NARIÑO', 'codigo': '91_540' },
                { 'name': 'PUERTO SANTANDER', 'codigo': '91_669' },
                { 'name': 'TARAPACA', 'codigo': '91_798' },
            ],
            "GUAINIA": [
                { 'name': 'INIRIDA', 'codigo': '94_001' },
                { 'name': 'BARRANCO MINAS', 'codigo': '94_343' },
                { 'name': 'MAPIRIPANA', 'codigo': '94_663' },
                { 'name': 'SAN FELIPE', 'codigo': '94_883' },
                { 'name': 'PUERTO COLOMBIA', 'codigo': '94_884' },
                { 'name': 'LA GUADALUPE', 'codigo': '94_885' },
                { 'name': 'CACAHUAL', 'codigo': '94_886' },
                { 'name': 'PANA PANA', 'codigo': '94_887' },
                { 'name': 'MORICHAL', 'codigo': '94_888' },
            ],
            "GUAVIARE": [
                { 'name': 'SAN JOSE DEL GUAVIARE', 'codigo': '95_001' },
                { 'name': 'CALAMAR', 'codigo': '95_015' },
                { 'name': 'EL RETORNO', 'codigo': '95_025' },
                { 'name': 'MIRAFLORES', 'codigo': '95_200' },
            ],
            "VAUPES": [
                { 'name': 'MITU', 'codigo': '97_001' },
                { 'name': 'CARURU', 'codigo': '97_161' },
                { 'name': 'PACOA', 'codigo': '97_511' },
                { 'name': 'TARAIRA', 'codigo': '97_666' },
                { 'name': 'PAPUNAUA', 'codigo': '97_777' },
                { 'name': 'YAVARATE', 'codigo': '97_889' },
            ],
            "VICHADA": [
                {'name':'PUERTO CARREÑO','codigo':'99_001'},
                {'name':'LA PRIMAVERA','codigo':'99_524'},
                {'name':'SANTA ROSALIA','codigo':'99_624'},
            ]

        }

    }
}